<template>
  <div>
        <!-- Modal -->
        <div class="modal animated bounce text-left" id="delete" tabindex="-1" role="dialog"
            aria-labelledby="myModalLabel36" aria-hidden="true">
            <div class="modal-dialog" role="document">
                <div class="modal-content">
                    <div class="modal-header">
                        <h4 class="modal-title text-danger" id="myModalLabel36">
                            <span class="alert-icon text-danger"><i class="la la-warning"></i></span>
                            Confirmation de la suppression
                        </h4>
                        <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                            <span aria-hidden="true">&times;</span>
                        </button>
                    </div>
                    <div class="modal-body">
                        <h5 class="text-danger text-center">
                            Attention! Cette action est irréversible, êtes vous sur de vouloir
                            continuer ?
                        </h5>

                        <p>.</p>
                    </div>
                    <div class="modal-footer">
                        <button type="button" class="btn grey btn-outline-primary" data-dismiss="modal">
                            Annuler
                        </button>
                        <button type="button" @click.prevent="supprimerRdv" data-dismiss="modal"
                            class="btn btn-outline-danger">
                            Supprimer
                        </button>
                    </div>
                </div>
            </div>
        </div>

    <div class="app-content content">
      <div class="content-overlay"></div>
      <div class="content-wrapper">
        <div class="content-header row">
          <div class="content-header-left col-md-6 col-12 mb-2">
            <!-- <h3 class="content-header-title">Gérer les rendez-vous </h3> -->
            <div class="row breadcrumbs-top">
              <div class="breadcrumb-wrapper col-12">
                <ol class="breadcrumb">
                 
                  <li class="breadcrumb-item active">Gestion des RDV
                  </li>
                </ol>
              </div>
            </div>
          </div>
          <!-- Definition du modal -->
          <!-- modal ajout  -->
          <div class="modal fade text-left" id="large" tabindex="-1" role="dialog" aria-labelledby="" aria-hidden="true">
            <div class="modal-dialog modal-sm" role="document">
              <div class="modal-content">
                <div class="modal-header bg-info white">
                  <h4 class="modal-title white" id="">
                    + Enregistrer un nouveau rendez-vous

                  </h4>
                  <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                    <span aria-hidden="true">&times;</span>
                  </button>
                </div>
                <div class="modal-body">
                  <form>
                    <div>
                      <div>
                        <div class="container">
                          <div class="row">
                            <div class="col-lg-12">
                              <fieldset class="form-group floating-label-form-group">
                                <label for="patient">Sélectionner le patient</label>
                                <VueMultiselect v-model="formData.patient" label="name" track-by="id"
                                  :selectLabel="' Enter pour sélectionner'" required
                                  :deselectLabel="' Enter pour supprimer'" selectedLabel="Selectionné"
                                  tag-placeholder="Selectionnez un utilisateur" placeholder="Selectionnez un patient"
                                  :options="patients">
                                </VueMultiselect>
                              </fieldset>
                            </div>
                          </div>

                          <div class="row">
                            <div class="col-lg-6">
                              <fieldset class="form-group floating-label-form-group">
                                <label for="patient">Date du RDV</label>
                                <input type="date" class="form-control" v-model="formData.date_meet" name="">
                              </fieldset>
                            </div>

                            <div class="col-lg-6">
                              <fieldset class="form-group floating-label-form-group">
                                <label for="patient">Heure du RDV</label>
                                <input type="time" class="form-control" v-model="formData.heure" name="">
                              </fieldset>
                            </div>
                          </div>
                          <div class="row">
                            <div class="col-lg-12">
                              <fieldset class="form-group floating-label-form-group">
                                <label for="patient">Type de consultation</label>
                                <select required name="role_list" id="" class="form-control" v-model="formData.type_consultation">
                                  <option value="" disabled>Selectionnez le type de consultation</option>
                                  <option value="Médécine Génerale">Médecine Génerale</option>
                                  <option value="Nutrition">Nutrition</option>
                                  <option value="Gynécologie">Gynécologie</option>
                                </select>
                              </fieldset>
                            </div>
                          </div>
                          <div class="row">
                            <div class="col-lg-12">
                              <fieldset class="form-group floating-label-form-group">
                                <label for="motif">Motif de consultation</label>
                                <input type="text" name="" class="form-control" v-model="formData.motif" placeholder="Entrer le motif">
                              </fieldset>
                            </div>
                          </div>
                          <div class="row">
                            <div class="col-sm-6">
                              <fieldset class="form-group floating-label-form-group">
                                <label for="mode">Mode de consultation</label>
                                <select required name="role_list" id="mode" class="form-control" v-model="formData.mode">
                                  <option value="presentiel">Presentiel</option>
                                  <option value="en ligne">En ligne</option>
                                </select>
                              </fieldset>
                            </div>
                            <div class="col-sm-6">
                              <fieldset class="form-group floating-label-form-group">
                                <label for="mode">Etat</label>
                                <select required name="role_list" id="mode" class="form-control" v-model="formData.state">
                                  <option value="en cours">en cours</option>
                                  <option value="annulé">annulé</option>
                                  <option value="terminé">terminé</option>
                                </select>
                              </fieldset>
                            </div>
                          </div>
                          <div class="row">
                            <div class="col-lg-12">
                              <fieldset class="form-group floating-label-form-group">
                                <label for="patient">Affecter un praticen</label>
                                <VueMultiselect v-model="formData.doctor" label="name" track-by="id"
                                  :selectLabel="' Enter pour sélectionner'"
                                  :deselectLabel="' Enter pour supprimer'" selectedLabel="Selectionné"
                                  tag-placeholder="Selectionnez le praticien" placeholder="Selectionnez un praticien"
                                  :options="praticiens">
                                </VueMultiselect>
                              </fieldset>
                            </div>
                          </div>
                          <div class="row">
                            <div class="col-lg-12">
                              <fieldset class="form-group floating-label-form-group">
                                <label for="patient">Commentaire </label>
                                <textarea name="" class="form-control" id="" cols="10" v-model="formData.commentaire"></textarea>
                              </fieldset>
                            </div>
                          </div>

                        </div>
                      </div>
                    </div>
                    <div class=" d-flex justify-content-center py-1  ">
                      <button type="button" @click="close" class="btn grey btn-danger btn-sm mx-3 fs-5 fs-semibold"
                        data-dismiss="modal">
                        Fermer
                      </button>
                      <button  type="submit" class="btn btn-primary flex" @click.prevent="storeMeeting" data-dismiss="modal"
                aria-label="Close">
                        <span class="fs-5 fs-semibold">
                          Ajouter ce rdv
                        </span>
                      </button>
                    </div>
                  </form>

                </div>

              </div>
            </div>
          </div>


          <!-- modal update  -->
          <div class="modal fade text-left" id="updateM" tabindex="-1" role="dialog" aria-labelledby="" aria-hidden="true">
            <div class="modal-dialog modal-sm" role="document">
              <div class="modal-content">
                <div class="modal-header bg-success white">
                  <h4 class="modal-title white" id="">
                    <i class="la la-edit"></i> Modification du rendez-vous

                  </h4>
                  <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                    <span aria-hidden="true">&times;</span>
                  </button>
                </div>
                <div class="modal-body">
                  <form>
                    <div>
                      <div>
                        <div class="container">
                          <div class="row">
                            <div class="col-lg-12">
                              <fieldset class="form-group floating-label-form-group">
                                <label for="patient">Sélectionner le patient</label>
                                <VueMultiselect v-model="infoMeetings.patient" label="name" track-by="id"
                                  :selectLabel="' Enter pour sélectionner'"
                                  :deselectLabel="' Enter pour supprimer'" selectedLabel="Selectionné"
                                  tag-placeholder="Selectionnez un utilisateur" placeholder="Selectionnez un patient"
                                  :options="patients">
                                </VueMultiselect>
                              </fieldset>
                            </div>
                          </div>

                          <div class="row">
                            <div class="col-lg-6">
                              <fieldset class="form-group floating-label-form-group">
                                <label for="patient">Date du RDV</label>
                                <input type="date" class="form-control" v-model="infoMeetings.date_meet" name="">
                              </fieldset>
                            </div>

                            <div class="col-lg-6">
                              <fieldset class="form-group floating-label-form-group">
                                <label for="patient">Heure du RDV</label>
                                <input type="time" class="form-control" v-model="infoMeetings.heure" name="">
                              </fieldset>
                            </div>
                          </div>
                          <div class="row">
                            <div class="col-lg-12">
                              <fieldset class="form-group floating-label-form-group">
                                <label for="patient">Type de consultation</label>
                                <select required name="role_list" id="" class="form-control" v-model="infoMeetings.type_consultation">
                                  <option value="" disabled>Selectionnez le type de consultation</option>
                                  <option value="Médécine Génerale">Médecine Génerale</option>
                                  <option value="Nutrition">Nutrition</option>
                                  <option value="Gynécologie">Gynécologie</option>
                                </select>
                              </fieldset>
                            </div>
                          </div>
                          <div class="row">
                            <div class="col-lg-12">
                              <fieldset class="form-group floating-label-form-group">
                                <label for="motif">Motif de consultation</label>
                                <input type="text" name="" class="form-control" v-model="infoMeetings.motif" placeholder="Entrer le motif">
                              </fieldset>
                            </div>
                          </div>
                          <div class="row">
                            <div class="col-sm-6">
                              <fieldset class="form-group floating-label-form-group">
                                <label for="mode">Mode de consultation</label>
                                <select required name="role_list" id="mode" class="form-control" v-model="infoMeetings.mode">
                                  <option value="presentiel">Presentiel</option>
                                  <option value="en ligne">En ligne</option>
                                </select>
                              </fieldset>
                            </div>
                            <div class="col-sm-6">
                              <fieldset class="form-group floating-label-form-group">
                                <label for="mode">Etat</label>
                                <select required name="role_list" id="mode" class="form-control" v-model="infoMeetings.state">
                                  <option value="en cours">en cours</option>
                                  <option value="annulé">annulé</option>
                                  <option value="terminé">terminé</option>
                                </select>
                              </fieldset>
                            </div>
                          </div>
                          <div class="row">
                            <div class="col-lg-12">
                              <fieldset class="form-group floating-label-form-group">
                                <label for="patient">Affecter un praticen</label>
                                <VueMultiselect v-model="infoMeetings.doctor" label="name" track-by="id"
                                  :selectLabel="' Enter pour sélectionner'"
                                  :deselectLabel="' Enter pour supprimer'" selectedLabel="Selectionné"
                                  tag-placeholder="Selectionnez le praticien" placeholder="Selectionnez un praticien"
                                  :options="praticiens">
                                </VueMultiselect>
                              </fieldset>
                            </div>
                          </div>
                          <div class="row">
                            <div class="col-lg-12">
                              <fieldset class="form-group floating-label-form-group">
                                <label for="patient">Commentaire </label>
                                <textarea name="" class="form-control" id="" cols="10" v-model="infoMeetings.commentaire"></textarea>
                              </fieldset>
                            </div>
                          </div>

                        </div>
                      </div>
                    </div>
                    <div class=" d-flex justify-content-center py-1  ">
                      <button type="button" @click="close" class="btn grey btn-danger btn-sm mx-3 fs-5 fs-semibold"
                        data-dismiss="modal">
                        Fermer
                      </button>
                      <button  type="submit" class="btn btn-primary flex" @click.prevent="updateMeeting" data-dismiss="modal"
                aria-label="Close">
                        <span class="fs-5 fs-semibold">
                          Modifier ce rdv
                        </span>
                      </button>
                    </div>
                  </form>

                </div>

              </div>
            </div>
          </div>
          <!-- modal voir  -->
          <div class="modal fade text-left" id="voirM" tabindex="-1" role="dialog" aria-labelledby="" aria-hidden="true">
            <div class="modal-dialog " role="document">
              <div class="modal-content">
                <div class="modal-header bg-success white">
                  <h4 class="modal-title white" id=""> Détails du rendez-vous</h4>
                  <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                    <span aria-hidden="true">&times;</span>
                  </button>
                </div>
                <div class="modal-body">

                  <div class="card" >
									<div class="card-body">
										<div class="row">		
											<div class="col-lg-12 d-flex justify-content-around" >        								
												<div class="media-body"  >
															<h5 class="media-heading">
																<strong>Nom : </strong>
																<span class="float-right content-header-title">
																	<strong class="text-uppercase">{{ infoMeetings.patient? infoMeetings.patient.name : '' }}</strong> 
																</span>
															</h5>
                              <hr>
															<h5 class="media-heading">
																<strong>Type de consultation : </strong>
																<span class="float-right content-header-title">
																	<strong class="text-uppercase">{{ infoMeetings.type_consultation }}</strong> 
																</span>
															</h5>
                              <hr>
															<h5 class="media-heading">
																<strong>Date et Heure du rendez-vous : </strong>
																<span class="float-right content-header-title">
																	<strong class="text-uppercase">{{ infoMeetings.date_meet }} à {{ infoMeetings.heure }}</strong> 
																</span>
															</h5>
                              <hr>
															<h5 class="media-heading">
																<strong>Mode : </strong>
																<span class="float-right content-header-title">
																	<strong class="text-uppercase">{{ infoMeetings.mode }}</strong> 
																</span>
															</h5>
                              <hr>
															<h5 class="media-heading">
																<strong>Etat : </strong>
																<span class="float-right content-header-title">
																	<strong class="text-uppercase">{{ infoMeetings.state }}</strong> 
																</span>
															</h5>
                              <hr>
															<h5 class="media-heading">
																<strong>Motif : </strong><br>
																<span>{{ infoMeetings.motif }}</span> 
															</h5>
                              <hr>
															<h5 class="media-heading">
																<strong>Commentaire : </strong><br>
																<span>{{ infoMeetings.commentaire }}</span> 
															</h5>
                              <hr>


												</div>
													
											</div>
										</div>
									</div>
								</div>
                </div>

              </div>
            </div>
          </div>


          <div class="content-header-right col-md-6 col-12">

            <div class="btn-group float-md-right" role="group" aria-label="Button group with nested">

              <div class="form-group">
                <button type="button" class="btn btn-info round box-shadow-2 px-2 mb-1"
                  data-toggle="modal" data-target="#large">
                  + Ajouter un rdv
                </button>
              </div>
            </div>
          </div>
        </div>
        <div class="content-body">
          <section class="row">
            <div class="col-12">
              <div class="card">
                <div class="card-head">
                  <div class="my-2 d-flex justify-content-end">
                    <div>
                      <input v-model="search" type="search" placeholder="Recherche..." id="form1" class="form-control" />
                    </div>
                  </div>
                </div>
                <div class="card-content">
                  <div class="card-body">
                    <!-- Invoices List table -->
                    <div class="table-responsive">
                      <table id="invoices-list" class="table table-bordered table-striped table-hover ">
                        <thead class="bg-info white">
                          <tr>
                            <th># </th>
                            <th>Patient </th>
                            <!-- <th>Date RDV</th> -->
                            <th>Mode </th>
                            <th>Etat </th>
                            <th>Consultation </th>
                            <th>Praticien</th>
                            <th>Actions</th>
                          </tr>
                        </thead>
                        <tbody>
                          <tr v-for="(meeting, index) in meetings">
                            <td>{{ meeting.id }}</td>
                            <td>{{ meeting.patient.name }}</td>
                            <!-- <td>{{ meeting.date_meet }} à {{ meeting.heure }}</td> -->
                            <td>{{ meeting.mode }}</td>
                            <td><span :class="'badge badge-' + badges[meeting.state]">{{ meeting.state }}</span></td>
                            <td>{{ meeting.type_consultation ? meeting.type_consultation : 'Non programmée' }} </td>
                            <td> <span v-if="meeting.doctor"> Dr {{ meeting.doctor.name }}</span></td>
                            <td>
                              <a href="#" class="btn btn-primary round btn-sm waves-effect waves-light " @click="voirRdv(meeting.id)" data-toggle="modal" data-target="#voirM"><i class="la la-eye"></i></a>
                              <a href="#" class="btn btn-primary round btn-sm waves-effect waves-light mx-1" @click="recupRdv(meeting.id)" data-toggle="modal" data-target="#updateM"><i class="la la-pencil"></i></a>
                              <a href="#" class="btn btn-danger round btn-sm waves-effect waves-light" data-toggle="modal" data-target="#delete" @click="recupId(meeting.id)"><i class="la la-trash"></i></a>
                            </td>
                          </tr>


                        </tbody>
                      </table>
                      <nav aria-label="pagination ">
                        <ul class="pagination justify-content-center">
                          <li class="page-item "><span @click="prev()" class="page-link pointer" tabindex="-1"> &#171;
                            </span></li>
                          <li v-for="item in pagination.total" :key="item"
                            :class="{ 'active': item === pagination.current }" class="page-item pointer"><span
                              class="page-link" @click="getData(item)"> {{ item }} </span>
                          </li>

                          <li class="page-item"> <span class="page-link pointer" @click="next()">&#187; </span></li>
                        </ul>
                      </nav>
                    </div>
                    <!--/ Invoices table -->
                  </div>
                </div>
              </div>
            </div>
          </section>
        </div>
      </div>
    </div>
  </div>
</template>


<script setup>
let badges = {'en cours': "warning", 'annulé': "danger", 'terminé': "success"}

function isObject(value) {
  return typeof value === 'object' && value !== null && value.constructor === Object;
}


import MeetingService from "@/services/modules/meeting.service.js";
import PatientService from "@/services/modules/patient.service.js";
import PraticienService from "@/services/modules/praticien.service.js";
import { createToast } from "mosha-vue-toastify";
import { onMounted, reactive, ref, watch } from 'vue';
import VueMultiselect from "vue-multiselect";
// import the styling for the toast
import "mosha-vue-toastify/dist/style.css";

const infoMeetings = ref([])
const meetings = ref([])
const patients = ref([])
const praticiens = ref([])
const isActive = ref(false)
const deleteId = ref(null)
const pagination = reactive({
  first: null,
  last: null,
  next: null,
  prev: null,
  current: null,
  total: null
})

const formData = reactive(
  {
    date_meet: '',
    heure: '',
    type_consultation: '',
    motif: '',
    mode: '',
    state: '',
    commentaire: '',
    patient_id: '',
    doctor_id: '',
  }
)

const search = ref('')
let timeout = null;
watch(search, (newValue, oldValue) => {
  if (timeout) {
    clearTimeout(timeout);
  }

  timeout = setTimeout(() => {
    getData(1)
  }, 500); 
});

function closeMeeting() {
  formData.date_meet = ""
  formData.heure = ""
  formData.type_consultation = ""
  formData.motif = ""
  formData.mode = "presentiel"
  formData.state = "en cours"
  formData.commentaire = ""
  formData.patient = ""
  formData.doctor = ""
}

const toast = (message, type) => {
  createToast(message, { type: type })
}

const prev = function () {
  if (pagination.current >= 0) {
    pagination.current = 1
    getData(pagination.current)
  }
  else {
    pagination.current--
    getData(pagination.current)
  }
}
const next = function () {
  pagination.current++
  if (pagination.current <= pagination.total) {
    getData(pagination.current)
  }
}

const active = () => isActive.value = true
const disabled = () => isActive.value = false
const getData = (element = 1) => {
  active()
  MeetingService.get(null, `?search=${search.value}&page=${element}`).then((data) => {
    const datas = data.data.data
    pagination.total = Math.ceil(datas.total / datas.per_page)
    pagination.current = datas.current_page
    pagination.last = datas.last_page
    pagination.first = 1

    meetings.value = datas.data
    disabled()
  }).catch((e) => {
    disabled()
    console.log(e)
  })
}

const storeMeeting = function () {
  formData.patient_id = formData.patient.id
  if (isObject(formData.doctor)) {
    formData.doctor_id = formData.doctor.id
  }
  
  MeetingService.create(formData).then((response) => {
    if (response.data.status == 'error') {
      toast(response.data.message, 'danger')
    }
    else {
      closeMeeting()
      getData()
      toast("Vous avez ajouté un nouveau rendez-vous !", 'success')
    }

  })
}

const voirRdv = (id) => {
  MeetingService.get(id).then((data) => {
    infoMeetings.value = data.data.data
    infoMeetings.value.patient_name = data.data.data.patient.name
  }).catch((e) => {
    console.log(e)
  })
}

const recupRdv = (id) => {
  MeetingService.get(id).then((data) => {
    infoMeetings.value = data.data.data
  }).catch((e) => {
    console.log(e)
  })
}

const updateMeeting = function () {
    const datas = {}
  datas.date_meet = infoMeetings.value.date_meet
  datas.heure = infoMeetings.value.heure
  datas.motif = infoMeetings.value.motif
  datas.mode = infoMeetings.value.mode
  datas.state = infoMeetings.value.state
  datas.type_consultation = infoMeetings.value.type_consultation
  datas.commentaire = infoMeetings.value.commentaire
  datas.patient_id = infoMeetings.value.patient.id

  if (isObject(infoMeetings.value.doctor)) {
    datas.doctor_id = infoMeetings.value.doctor.id
  }
  
  MeetingService.update(infoMeetings.value.id, datas).then((response) => {
    console.log(response);
      if (response.data.status == 'error') {
        toast(response.data.message, 'danger')
      }
      else {
        getData()
        toast("Modifications éffectuées avec succès !!!", 'success')
      }

    })
}


const recupId = (id) => {
  deleteId.value = id
}

const supprimerRdv = function () {
  MeetingService.destroy(deleteId.value).then((data) => {
      if (data.data.status == "success") {
        deleteId.value = ""
        getData()
        toast("Suppression du rendez vous", "success");
      } else {
        toast(data.data.message, "danger");
      }
    })
}

const getPatients = () => {
    PatientService.get().then((data) => {
        const datas = data.data.data
        patients.value = datas.data
    }).catch((e) => {
        console.log(e)
    })
}

const getPraticiens = () => {
    PraticienService.get().then((data) => {
        const datas = data.data.data
        praticiens.value = datas.data
    }).catch((e) => {
        console.log(e)
    })
}

onMounted(() => {
  getData()
  getPatients()
  getPraticiens()
})
</script>
