
import ApiService from '@/services/configs/api.service'

const FeedbackService = {
  get(slug,element) {
    return ApiService.get(`feedbacks`, slug,element);
  },

  show(slug) {
    return ApiService.delete(`feedbacks/${slug}`);
  },
 
};

  export default FeedbackService;
  