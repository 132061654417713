
import ApiService from '@/services/configs/api.service'

const PatientQfcService = {
  get(slug) {
    return ApiService.get(`patients/${slug}/qfc`);
  },
 
};

  export default PatientQfcService;
  