
import ApiService from '@/services/configs/api.service'

const UserConversationService = {
  get() {
    return ApiService.get(`users/conversations`);
  },
};

  export default UserConversationService;
  