
import ApiService from '@/services/configs/api.service'

const PatientComposition = {
  get(slug) {
    return ApiService.get(`patients/${slug}/body_composition`);
  },
 
};

  export default PatientComposition;
  