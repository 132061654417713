
import ApiService from '@/services/configs/api.service'

const FinconsultationService = {

  get(slug) {
    return ApiService.get(`consultations/${slug}/finish`);
  },
 

 
};

  export default FinconsultationService;
  