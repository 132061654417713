<template>

  <div>
    <!-- Ce menu comprends la navbar et la sidebar -->
    <!-- navbar -->
       <nav class="header-navbar navbar-expand-md navbar navbar-with-menu navbar-without-dd-arrow fixed-top navbar-semi-light bg-info navbar-shadow">
            <div class="navbar-wrapper">
                <div class="navbar-header">
                    <ul class="nav navbar-nav flex-row">
                        <li class="nav-item mobile-menu d-md-none mr-auto"><a class="nav-link nav-menu-main menu-toggle hidden-xs" href="#"><i class="ft-menu font-large-1"></i></a></li>
                        <li class="nav-item">
                            <img class="brand-logo" alt="modern dashboard logo" style="width: 150px;" src="/hospital/app-assets/images/logo/logo.png">
                        </li>
                        <li class="nav-item d-md-none"><a class="nav-link open-navbar-container" data-toggle="collapse" data-target="#navbar-mobile">
                            <i class="material-icons mt-50">more_vert</i></a>
                        </li>
                    </ul>
                </div>
                <div class="navbar-container content">
                    <div class="collapse navbar-collapse" id="navbar-mobile">
                        <ul class="nav navbar-nav mr-auto float-left">
                            <li class="nav-item d-none d-md-block"><a class="nav-link nav-menu-main menu-toggle" href="#"><i class="ft-menu"></i></a></li>
                            
                            <li class="nav-item"><a class="nav-link nav-link-expand mx-md-1 mx-0" href="#"><i class="ficon ft-maximize"></i></a></li>
                            
                        </ul>
                        <ul class="nav navbar-nav float-right">
                        
                          
                            <li class="dropdown dropdown-user nav-item"><a class="dropdown-toggle nav-link dropdown-user-link" href="#" data-toggle="dropdown"><span class="mr-1 user-name text-bold-700"> {{ users.name }} {{ users.username }} ({{ users.role }})  </span><span class="avatar avatar-online">
                                <img src="/hospital/app-assets/images/avatar.png" alt="avatar"><i></i></span></a>
                                <div class="dropdown-menu dropdown-menu-right">
                                    
                                    <router-link to="/dashboard/moncompte">
                                        <a class="dropdown-item" href="#"><i class="material-icons"></i> Mon profil</a>
                                    </router-link>
                                    <a class="dropdown-item" href="#"><i class="material-icons">playlist_add_check</i>Consultation</a>
                                    <a class="dropdown-item" href="#"><i class="material-icons">content_paste</i> Mes Rendez vous</a>
                                    <div class="dropdown-divider"></div>
                                    <a class="dropdown-item"  @click.prevent="logout">
                                        <i class="material-icons">power_settings_new</i>
                                        Déconnexion
                                    </a>
                                    <form id="logout-form" action="" method="POST" class="d-none">
                                    
                                    </form>                                
                                </div>
                            </li>
                        </ul>
                    </div>
                </div>
            </div>
        </nav> 

    
      <!-- sidebar -->
      <div class="main-menu material-menu menu-fixed menu-light menu-accordion   menu-shadow " style="padding-top:10px;"  data-scroll-to-active="true">     
          <div class="main-menu-content">
              <ul class="navigation navigation-main" id="main-menu-navigation" data-menu="menu-navigation">

                  <li class="active">
                     <router-link to="/dashboard">
                        <i class="la la-home"></i><span class="menu-title" data-i18n="Dashboard Hospital"> Tableau de bord</span>
                    </router-link>
                    
                  </li>


                  <li class=" nav-item">
                    <router-link to="/dashboard/patient">
                        <i class="la la-user"></i><span class="menu-title" data-i18n="Patients"> Patients</span>
                    </router-link>
                      
                  </li>
                  
                  <li class=" nav-item">
                        <router-link to="/dashboard/praticien">
                          <i class="la la-stethoscope"></i><span class="menu-title" data-i18n="Doctors"> Praticiens</span>
                        </router-link>
                  </li>
                               
                  <li class=" nav-item">
                        <router-link to="/dashboard/ordonnances-bons">
                            <i class="la la-clipboard"></i><span class="menu-title" data-i18n="Report"> Ordonnance & Bon </span>
                        </router-link> 
                  </li>
                  

                  <li class=" nav-item">
                    <router-link to="/dashboard/certificat/index">
                        <i class="la la-edit"></i> <span class="menu-title" data-i18n="Report"> Certificats médicales</span>
                    </router-link>     
                  </li>
                  
                  
                  <!-- <div class="dropdown-divider"></div> -->

                <li class=" nav-item">
                    <router-link to="/dashboard/rdv">
                        <i class="la la-calendar"></i><span class="menu-title" data-i18n="user"> Gestion des RDV</span>
                    </router-link>
                </li>

                <li class=" nav-item">

                <router-link to="/dashboard/messagerie">
                        <i class="la la-envelope"></i><span class="menu-title" data-i18n="Chat"> Messagerie</span>
                    </router-link>
                </li>

                <li class=" nav-item">
                    <router-link to="/dashboard/utilisateur">
                        <i class="la la-cogs"></i><span class="menu-title" data-i18n="user"> Réglages</span>
                    </router-link>
                </li>


                  <li class=" navigation-header">
                      <span data-i18n="Professional"><em>Outils et Services</em></span>
                  </li>

                  <div class="dropdown-divider"></div>

               
               

                  <li class=" nav-item">

                    <router-link to="/dashboard/inscrits">
                            <i class="la la-users"></i><span class="menu-title" data-i18n="Chat"> Inscrits au PAEV</span>
                        </router-link>
                  </li>

                    <li class=" nav-item">

                    <router-link to="/dashboard/feedbacks">
                            <i class="la la-check-square"></i><span class="menu-title" data-i18n="Chat"> Feedback</span>
                        </router-link>
                    </li>
                
              </ul>
          </div>
      </div> 


      <!-- BEGIN: Footer-->
      <footer class="footer footer-static footer-light navbar-border navbar-shadow">
          <p class="clearfix blue-grey lighten-2 text-sm-center mb-0 px-2"><span class="float-md-left d-block d-md-inline-block">Copyright &copy; 
              <a class="text-bold-800 grey darken-2" href="https://www.nicuxgroup.com" target="_blank">Réalisé par Nicuxgroup</a></span>
              <span class="float-md-right d-none d-lg-block">Programme de l'Augmentation de l'Espérance de Vie<i class="ft-heart pink"></i><span id="scroll-top"></span></span>
          </p>
      </footer>
  </div>
</template>

<script setup>
    import { onMounted,reactive } from 'vue';
    import { useRouter, useRoute } from "vue-router";
    import { createToast } from "mosha-vue-toastify";
    // import the styling for the toast
    import "mosha-vue-toastify/dist/style.css";
    const router = useRouter();
    const route = useRoute();

  const users = reactive({})
  onMounted(() => {
    const usersInfo = JSON.parse(localStorage.getItem('paevcliniqueInfo'))
    if(usersInfo != null || usersInfo != undefined ) {
      users.name = usersInfo.users.name

      users.role = usersInfo.users.profession
    }
    
  })
  const toast = (message,type) => {
    createToast(message,{type:type})
  }
  const logout = function() {
    localStorage.removeItem('paevcliniqueInfo')
    toast('Vous êtes bien déconnecté  ', 'success')
    router.push('/')
  }
</script>

<style>
    
</style>