
import ApiService from '@/services/configs/api.service'

const RemindsService = {
  get(slug,element) {
    return ApiService.get("remind_24", slug,element);
  },
 
  create(params) {
    return ApiService.post("remind_24", params);
  },

  
  update(slug, params) {
    return ApiService.update("remind_24", slug, params);
  },

  destroy(slug) {
    return ApiService.delete(`remind_24/${slug}`);
  },
 

 
};

  export default RemindsService;
  