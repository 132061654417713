
import ApiService from '@/services/configs/api.service'

const ConversationService = {
  get(slug,element) {
    return ApiService.get(`conversations`, slug,element);
  },
 
  create(params) {
    return ApiService.post("conversations", params);
  },

  update(slug, params) {
    return ApiService.update("conversations", slug, params);
  },

  destroy(slug) {
    return ApiService.delete(`conversations/${slug}`);
  },
 

 
};

  export default ConversationService;
  