<template>
	<div>
		<div class="app-content content">
			<div class="content-overlay"></div>
			<div class="content-wrapper">
				<div class="content-body">
					<!-- List Of All Patients -->
					<section id="patients-list">
						<div class="row">
							<div class="col-12">
								<div class="card">
									<div class="card-header">
										<router-link to="/admin/patient/add" class="btn btn-info btn-xs" >
											<i class="la la-plus" style="font-weight: bold;"></i> Nouveau patient
										</router-link>
										<div class="d-flex justify-content-end ">
											<div class="row">
											<div class="col-lg-12">
												<Search @search="getSearch" />
											</div>
											</div>
										</div>
									</div>
									<div class="card-body collapse show">
										<div class="table-responsive">
                        					<table class="table table-bordered table-striped table-hover patients-list">									
												<vue-element-loading :active="isActive" :is-full-screen="false"/>
													<thead class="bg-info white">
														<tr>
															<th>#</th>
															<th>NOM & PRENOMS</th>
															<th>SEXE</th>
															<th>AGE</th>
															<th>DATE D'ARRIVÉE</th>
															<th>TELEPHONE</th>
															<th>ACTIONS</th>
														</tr>
													</thead>
												<tbody>
													<tr v-for="(patient, index) in resultQuery">
														<td>{{ index + 1 }}</td>
														<td>{{ patient.name }}</td>
														<td>{{ patient.sex }}  </td>
														<td>{{ patient.age }} ans </td>
														<td>{{ patient.date_arrive }}  </td>
														<td>{{ patient.phone }}</td>
														<td>

															<router-link :to="{ name: 'ListeDossierNutrition', params: { patient_id: patient.id } }"
																class="btn btn-info round btn-sm waves-effect waves-light">
																DOSSIER NUTRITIONNEL
															</router-link>
														</td>
													</tr>
												</tbody>
												
											</table>
											<nav aria-label="pagination ">
                          <ul class="pagination justify-content-center">
                             <li class="page-item "><span @click="prev()" class="page-link pointer"  tabindex="-1"> &#171; </span></li>
                            <li v-for="item in pagination.total" :key="item" :class="{'active':item===pagination.current}" class="page-item pointer"><span class="page-link" @click="getData(item)" > {{ item }} </span></li>
                            
                            <li class="page-item"> <span class="page-link pointer" @click="next()" >&#187; </span></li>
                            </ul>
                      </nav>
										</div>
									</div>
								</div>
							</div>
						</div>
					</section>
				</div>
			</div>
		</div>
	</div>
</template>

<script setup>
import { ref, reactive, onMounted, computed } from 'vue'
import { useStore } from 'vuex'
import { createToast } from "mosha-vue-toastify";
import Search from "@/components/Search"
import PatientService from "@/services/modules/patient.service.js";
import NutritionService from "@/services/modules/nutrition.service.js";
// import the styling for the toast
import "mosha-vue-toastify/dist/style.css";

import VueElementLoading from 'vue-element-loading'
const isActive = ref(false)
const search = ref('')
const patients = ref([])
const pagination = reactive({
  first:null,
  last:null,
  next:null,
  prev:null,
  current:null,
  total:null
})

const resultQuery = computed(() => {
	if (search.value) {
		return patients.value.filter((item) => {
			return search.value.toLowerCase().split(' ').every(v => item.name.toLowerCase().includes(v)) ||
				search.value.toLowerCase().split(' ').every(v => item.consultation_type.toLowerCase().includes(v)) ||
				search.value.toLowerCase().split(' ').every(v => item.created_at.toLowerCase().includes(v))
		})

	} else {
		return patients.value;
	}
})
//getData
const getSearch = function (data) {
	search.value = data
}
	const prev = function() {
    if(pagination.current >= 0) {
      pagination.current = 1 
      getData(pagination.current)
    }
    else {
      pagination.current--
      getData(pagination.current)
    }
  }
  const next = function() {
    pagination.current++
    if(pagination.current <= pagination.total) {
      getData(pagination.current)
    }
  }

	 const active = () => isActive.value = true
  const disabled = () => isActive.value = false
	const getData = (element=1) => {
		active()
		NutritionService.get("",element).then((data) => {
			const datas = data.data.data
			pagination.total = Math.ceil(datas.total / datas.per_page)
			pagination.current = datas.current_page
			pagination.last = datas.last_page
			pagination.first = 1
			patients.value = datas.data
			disabled()
			
		}).catch((e) => {
			console.log(e)
			disabled()
		})
	}

onMounted(() => {
	getData()
})





const toast = (message, type) => {
	createToast(message, { type: type })
}

</script>
<style></style>