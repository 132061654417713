
import ApiService from '@/services/configs/api.service'

const PatientService = {
  get(slug,element) {
    return ApiService.get(`patients`, slug,element);
  },
 
  create(params) {
    return ApiService.post("patients", params);
  },

  

  update(slug, params) {
    return ApiService.update("patients", slug, params);
  },

  destroy(slug) {
    return ApiService.delete(`patients/${slug}`);
  },

  analyses(id) {
    return ApiService.get(`patients/analysis?patient_id=${id}`);
  },
 

 
};

  export default PatientService;
  