import { createStore } from 'vuex'

export default createStore({
  state: {
    isActive:false,
  },
  getters: {
  },
  mutations: {
    active(state) {
      state.isActive = true
    },
    disabled(state) {
      state.isActive = false
    }
  },
  actions: {
    active(context) {
      context.commit('active')
    },
   
    disabled(context) {
      context.commit('disabled')
    },
  },
  modules: {
  }
})
