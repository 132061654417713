
import ApiService from '@/services/configs/api.service'

const PatientRemindService = {
  get(slug) {
    return ApiService.get(`patients/${slug}/remind_24`);
  },
 
};

  export default PatientRemindService;
  