import { createRouter, createWebHistory } from 'vue-router'

// import AdminLayout from '../views/admin/AdminLayout.vue'
// import Dashboard from '../views/admin/Dashboard.vue'

// import PatientIndex from '../views/admin/patients/PatientIndex.vue'
// import PatientEdit from '../views/admin/patients/PatientEdit.vue'
// import PatientAdd from '../views/admin/patients/PatientAdd.vue'

// import PraticienIndex from '../views/admin/praticiens/PraticienIndex.vue'
// import PraticienEdit from '../views/admin/praticiens/PraticienEdit.vue'

import * as Admin from '@/views/admin'
//import * as Public from '@/views/public'

import authPage from "../views/errors/401.vue";
import notFound from "../views/errors/404.vue";

import Login from '@/views/auth/Login.vue'
import Remember from '@/views/auth/Remember.vue'

//middleware
import middlewarePipeline from "./middlewarePipeline";
import auth from "./middleware/auth";
import guest from "./middleware/guest";


const routes = [
  {
    path: '/dashboard',
    name: 'dashboard',
    component: Admin.AdminLayout,
    children: [
        { path: 'moncompte', component: Admin.MonCompte }, 

        { path: '', name: 'dashboard', component: Admin.Dashboard },
        { path: 'patient', component: Admin.PatientIndex },
        { path: 'patient/edit/:id', component: Admin.PatientEdit, name:'patientEdit' },
        { path: 'patient/add/:suscriber_id?', component: Admin.PatientAdd },
        { path: 'patient/dossier/:id', name:"dossierMedical", component: Admin.DossierMedical },
        { path: 'patient/dossier/voirDossier/:id', name:"voirDossier", component: Admin.VoirDossier },
        { path: 'patient/dossier/termine/:id', name:"dossierMedicalTermine", component: Admin.DossierMedicalTermine },
        { path: 'patient/stats', component: Admin.Stats },
        { path: 'praticien', component: Admin.PraticienIndex },
        { path: 'praticien/edit/:id', component: Admin.PraticienEdit },

        { path: 'conseil-suivi', component: Admin.ConseilSuivi },
        { path: 'evolution-traitement', component: Admin.DetailSuivi },
        { path: 'fiche-conseil/:id', name:"ficheConseil", component: Admin.FicheConseil },

        { path: 'ordonnances-bons', component: Admin.OrdonnanceBon },

        { path: 'ordonnance', component: Admin.OrdonnanceIndex },
        { path: 'ordonnance/:id/read', name:"voirOrdonance" ,component: Admin.OrdonnanceCreate },
        { path: 'ordonnance/edit', component: Admin.OrdonnanceEdit },

        { path: 'bon', component: Admin.BonIndex },
        { path: 'bon/:id/read', name:'voirBon', component: Admin.BonCreate },
        { path: 'bon/edit', component: Admin.BonEdit },

        { path: 'certificat/index', component: Admin.CertificatIndex },
        { path: 'certificat/edit', component: Admin.CertificatEdit },
        { path: 'certificat/:id/read', name:"voirCertificat", component: Admin.CertificatRead },

        { path: 'nutrition', component: Admin.Nutrition },
        { path: 'nutrition/list_dossier/:patient_id', name:"ListeDossierNutrition", component: Admin.ListeDossier },
        { path: 'nutrition/dossier/:id', name:"dossierMedicalNutrition", component: Admin.DossierNutrition },
        { path: 'utilisateur', component: Admin.Utilisateur },

        { path: 'rdv', component: Admin.IndexRdv },
        { path: 'messagerie', component: Admin.Messagerie },

        { path: 'inscrits', component: Admin.Inscrit },
        { path: 'feedbacks', component: Admin.Feedback },


    ],
    meta: {
      middleware: [auth],
    },
  },
  /* {
    path: '/',
    name: 'public',
    component: Public.PublicLayout,
    children: [
        { path: '/', name: 'home', component: Public.Home },
        { path: '/contact', component: Public.Contact },
        { path: '/about', component: Public.About },

    ]
  },
 */
  {
    path: "/401-non-autorise",
    name: "authPage",
    component: authPage,
  },
  {
    path :'/:pathMatch(.*)*',
    name: "notFound",
    component: notFound,
  },

 /*  {
    path: '/:pathMatch(.*)*', redirect: '/'
  }, */

  
  { 
    path: '/', 
    name: 'Login', 
    component: Login ,
    meta: {
      middleware: [guest],
    },
  },
  { 
    path: '/remember-password', name: 'Remember', component: Remember 
  },
  
 
]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes
})

router.beforeEach((to, from, next) => {
  if (!to.meta.middleware) {
    return next();
  }
  const middleware = to.meta.middleware;

 
  const context = {
    to,
    from,
    next,
  };

  return middleware[0]({
    ...context,
    next: middlewarePipeline(context, middleware, 1),
  });
});

export default router
