<template>
    <div>
        <!-- Modal -->
        <div class="modal animated bounce text-left" id="delete" tabindex="-1" role="dialog"
            aria-labelledby="myModalLabel36" aria-hidden="true">
            <div class="modal-dialog" role="document">
                <div class="modal-content">
                    <div class="modal-header">
                        <h4 class="modal-title text-danger" id="myModalLabel36">
                            <span class="alert-icon text-danger"><i class="la la-warning"></i></span>
                            Confirmation de la suppression
                        </h4>
                        <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                            <span aria-hidden="true">&times;</span>
                        </button>
                    </div>
                    <div class="modal-body">
                        <h5 class="text-danger text-center">
                            Attention! Cette action est irréversible, êtes vous sur de vouloir
                            continuer ?
                        </h5>

                        <p>.</p>
                    </div>
                    <div class="modal-footer">
                        <button type="button" class="btn grey btn-outline-primary" data-dismiss="modal">
                            Annuler
                        </button>
                        <button type="button" @click.prevent="deleteConversation" data-dismiss="modal"
                            class="btn btn-outline-danger">
                            Supprimer
                        </button>
                    </div>
                </div>
            </div>
        </div>
        <!-- Modal add -->
        <div class="modal animated bounce text-left" id="bounce" tabindex="-1" role="dialog"
            aria-labelledby="myModalLabel36" aria-hidden="true">
            <div class="modal-dialog modal-lg" role="document">
                <div class="modal-content">
                    <div class="modal-header">
                        <h4 class="modal-title " id="myModalLabel36">
                            Sélectionnez un contact
                        </h4>
                        <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                            <span aria-hidden="true">&times;</span>
                        </button>
                    </div>
                    <form class="modal-body">
                        <div class="form-body">
                            <div class="row my-2">
                                <div class="col-md-12">
                                    <div class="form-group">
                                        <label for="contact"> Nom et Prenoms </label>
                                        <VueMultiselect v-model="conversationParams.user2_id" label="name" track-by="id"
                                            :selectLabel="'Appuyez sur Entrée pour sélectionner'"
                                            :deselectLabel="'Appuyez sur Entrée pour supprimer'" selectedLabel="Selectionné"
                                            tag-placeholder="Selectionnez un utilisateur"
                                            placeholder="Selectionnez un utilisateur" :options="users">
                                        </VueMultiselect>

                                    </div>
                                </div>

                            </div>

                            <button type="submit" class="btn btn-primary w-100" @click.prevent="storeConversation"
                                data-dismiss="modal" aria-label="Close"> Commencer une nouvelle discussion
                            </button>

                        </div>
                    </form>
                </div>
            </div>
        </div>

        <div class="app-content content" style="padding-top: 5rem;">
            <div class="sidebar-left">
                <div class="sidebar">
                    <!-- app chat user profile left sidebar start -->
                    <div class="chat-user-profile">
                        <header class="chat-user-profile-header text-center border-bottom">
                            <span class="chat-profile-close">
                                <i class="ft-x"></i>
                            </span>
                            <div class="my-2">

                                <img src="/hospital/app-assets/images/portrait/small/avatar-docteur.png" class="round mb-1"
                                    alt="user_avatar" height="100" width="100">

                                <h5 class="mb-0">John Doe</h5>
                                <span>Designer</span>
                            </div>
                        </header>
                        <div class="chat-user-profile-content">
                            <div class="chat-user-profile-scroll ps ps--active-y">
                                <h6 class="text-uppercase mb-1">ABOUT</h6>
                                <p class="mb-2">It is a long established fact that a reader will be distracted by the
                                    readable content .</p>
                                <h6>PERSONAL INFORAMTION</h6>
                                <ul class="list-unstyled mb-2">
                                    <li class="mb-25">email@gmail.com</li>
                                    <li>+1(789) 950 -7654</li>
                                </ul>

                                <h6 class="text-uppercase mb-1">SETTINGS</h6>
                                <ul class="list-unstyled">
                                    <li class="mb-50 "><a href="javascript:void(0);" class="d-flex align-items-center"><i
                                                class="ft-tag mr-50"></i>
                                            Add
                                            Tag</a></li>
                                    <li class="mb-50 "><a href="javascript:void(0);" class="d-flex align-items-center"><i
                                                class="ft-star mr-50"></i>
                                            Important Contact</a>
                                    </li>
                                    <li class="mb-50 "><a href="javascript:void(0);" class="d-flex align-items-center"><i
                                                class="ft-image mr-50"></i>
                                            Shared
                                            Documents</a></li>
                                    <li class="mb-50 "><a href="javascript:void(0);" class="d-flex align-items-center"><i
                                                class="ft-trash-2 mr-50"></i>
                                            Deleted
                                            Documents</a></li>
                                    <li><a href="javascript:void(0);" class="d-flex align-items-center"><i
                                                class="ft-x-circle mr-50"></i> Blocked
                                            Contact</a></li>
                                </ul>
                                <div class="ps__rail-x" style="left: 0px; bottom: 0px;">
                                    <div class="ps__thumb-x" tabindex="0" style="left: 0px; width: 0px;"></div>
                                </div>
                                <div class="ps__rail-y" style="top: 0px; height: 343px; right: 0px;">
                                    <div class="ps__thumb-y" tabindex="0" style="top: 0px; height: 207px;"></div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <!-- app chat user profile left sidebar ends -->
                    <!-- app chat sidebar start -->
                    <div class="chat-sidebar card">
                        <span class="chat-sidebar-close">
                            <i class="ft-x"></i>
                        </span>
                        <div class="chat-sidebar-search">
                            <div class="d-flex align-items-center">
                                <div class="chat-sidebar-profile-toggle">
                                    <div class="avatar">
                                        <img src="/hospital/app-assets/images/portrait/small/avatar-docteur.png"
                                            class="cursor-pointer" alt="user_avatar" height="36" width="36">
                                    </div>
                                </div>
                                <Search @search="getSearch" class="form-group position-relative has-icon-left mx-75 mb-0" />
                            </div>
                        </div>
                        <div class="chat-sidebar-list-wrapper pt-2 ps ps--active-y">
                            <h6 class="px-2 pt-2 pb-25 mb-0">CHATS</h6>
                            <ul class="chat-sidebar-list">
                                <li v-for="conversation in resultQuery" :key="conversation.id"
                                    :class="{ active: conversation.id === activeConversationId }"
                                    @click="setActiveConversation(conversation)">
                                    <div class="d-flex align-items-center">
                                        <div class="avatar m-0 mr-50">
                                            <img src="/hospital/app-assets/images/portrait/small/avatar-docteur.png"
                                                height="36" width="36" alt="sidebar user image">
                                            <!-- <span :class="['avatarstatus', conversation.user2.status]"></span> -->
                                        </div>
                                        <div class="chat-sidebar-name">
                                            <h6 class="mb-0" v-if="user_log != conversation.user2.id">{{
                                                conversation.user2.name }}</h6>
                                            <h6 class="mb-0" v-if="user_log != conversation.user1.id">{{
                                                conversation.user1.name }}</h6>
                                            <span class="text-muted">...</span>
                                        </div>
                                    </div>
                                </li>
                            </ul>

                            <button class="btn-outline-primary sendbtnfloat" data-toggle="modal" data-target="#bounce"
                                @click="addConversation" id="btnGroupDrop1" type="button" aria-haspopup="true"
                                aria-expanded="false"><span
                                    class="ft-message-square chat-sidebar-toggle chat-start-icon"></span></button>
                            <div class="ps__rail-x" style="left: 0px; bottom: -372px;">
                                <div class="ps__thumb-x" tabindex="0" style="left: 0px; width: 0px;"></div>
                            </div>
                            <div class="ps__rail-y" style="top: 372px; height: 484px; right: 0px;">
                                <div class="ps__thumb-y" tabindex="0" style="top: 211px; height: 273px;"></div>
                            </div>
                        </div>
                    </div>
                    <!-- app chat sidebar ends -->

                </div>
            </div>
            <div class="content-right">
                <div class="content-overlay"></div>
                <div class="content-wrapper">
                    <div class="content-header row">
                    </div>
                    <div class="content-body contenu-message">
                        <!-- app chat overlay -->
                        <div class="chat-overlay"></div>
                        <!-- app chat window start -->
                        <section class="chat-window-wrapper">
                            <div class="chat-area" v-if="receiverName">
                                <div class="chat-header">
                                    <header class="d-flex justify-content-between align-items-center px-1 py-75">
                                        <div class="d-flex align-items-center">
                                            <div class="chat-sidebar-toggle d-block d-lg-none mr-1"><i
                                                    class="ft-align-justify font-large-1 cursor-pointer"></i>
                                            </div>
                                            <div class="avatar chat-profile-toggle m-0 mr-1">
                                                <img src="/hospital/app-assets/images/portrait/small/avatar-docteur.png"
                                                    class="cursor-pointer" alt="avatar" height="36" width="36">
                                                <span class="avatar-status-busy"></span>
                                            </div>
                                            <h6 class="mb-0">{{ receiverName }}</h6>
                                        </div>
                                        <div class="chat-header-icons">
                                            <span class="chat-icon-favorite">
                                                <i class="ft-star font-medium-5 cursor-pointer"></i>
                                            </span>
                                            <span class="dropdown">
                                                <i class="ft-more-vertical font-medium-4 ml-25 cursor-pointer dropdown-toggle nav-hide-arrow cursor-pointer"
                                                    id="dropdownMenuButton" data-toggle="dropdown" aria-haspopup="true"
                                                    aria-expanded="false" role="menu">
                                                </i>
                                                <span class="dropdown-menu dropdown-menu-right"
                                                    aria-labelledby="dropdownMenuButton">
                                                   
                                                    <a class="dropdown-item" data-toggle="modal" data-target="#delete"
                                                        @click="supprimer(activeConversationId)"><i
                                                            class="ft-trash-2 mr-25"></i>
                                                       Supprimer cette conversation</a>
                                                  
                                                </span>
                                            </span>
                                        </div>
                                    </header>
                                </div>
                                <!-- chat card start -->
                                <div class="card chat-wrapper shadow-none mb-0">
                                    <div class="card-content">
                                        <div class="card-body chat-container ps ps--active-y">
                                            <div class="chat-content" v-for="message in messages" :key="message.id">
                                                <div class="chat" v-if="user_log == message.sender_id">
                                                    <div class="chat-avatar">
                                                        <a class="avatar m-0">
                                                            <img src="/hospital/app-assets/images/portrait/small/avatar-docteur.png"
                                                                alt="avatar" height="36" width="36">
                                                        </a>
                                                    </div>
                                                    <div class="chat-body">
                                                        <div class="chat-message">
                                                            <p v-if="message.message">{{ message.message }}</p>
                                                            <a :href="imageUrl[message.id]" target="_blank">
                                                                <img v-if="message.fichier" :src="imageUrl[message.id]" alt="image" class="profil" width="200"/>
                                                            </a>
                                                            <span class="chat-time">{{ message.created_at_fr }}</span>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div class="chat chat-left" v-else>
                                                    <div class="chat-avatar">
                                                        <a class="avatar m-0">
                                                            <img src="/hospital/app-assets/images/portrait/small/avatar-user.png"
                                                                alt="avatar" height="36" width="36">
                                                        </a>
                                                    </div>
                                                    <div class="chat-body">
                                                        <div class="chat-message">
                                                            <p v-if="message.message">{{ message.message }}</p>
                                                            <img v-if="message.fichier" :src="imageUrl[message.id]" alt="image" class="profil" width="200"/>
                                                            <span class="chat-time">{{ message.updated_at }}</span>
                                                        </div>
                                                    </div>
                                                </div>

                                            </div>
                                            <div class="ps__rail-x" style="left: 0px; bottom: -644px;">
                                                <div class="ps__thumb-x" tabindex="0" style="left: 0px; width: 0px;"></div>
                                            </div>
                                            <div class="ps__rail-y" style="top: 644px; right: 0px; height: 413px;">
                                                <div class="ps__thumb-y" tabindex="0" style="top: 252px; height: 161px;">
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="card-footer chat-footer px-2 py-1 pb-0">
                                        <form class="d-flex align-items-center" action="javascript:void(0);">
                                            <i class="ft-user cursor-pointer"></i>
                                            <label for="file-input">
                                                <i class="ft-paperclip ml-1 cursor-pointer"></i>
                                            </label>
                                            <input id="file-input" type="file" class="d-none" @change="handleFileUpload"
                                                name="file" ref="file" />
                                            <input type="text" class="form-control chat-message-send mx-1"
                                                placeholder="Taper votre message ici..." v-model="formData.message">
                                            <button type="submit" class="btn btn-primary glow send d-lg-flex"
                                                @click.prevent="storeMessage"><i class="ft-play"></i>
                                                <span class="d-none d-lg-block mx-50">Envoyer</span></button>
                                        </form>
                                    </div>
                                </div>
                                <!-- chat card ends -->
                            </div>

                            <div class="chat-start" v-else>
                                <span
                                    class="ft-message-square chat-sidebar-toggle chat-start-icon font-large-3 p-3 mb-1"></span>
                                <h4 class="d-none d-lg-block py-50 text-bold-500">Sélectionner une conversation pour
                                    afficher la discussion</h4>

                                    
                                <button
                                    class="btn btn-outline-primary chat-start-text chat-sidebar-toggle d-block  py-50 px-1"
                                    data-toggle="modal" data-target="#bounce" @click="addConversation" id="btnGroupDrop1"
                                    type="button" aria-haspopup="true" aria-expanded="false">Commencer une nouvelle
                                    conversation !</button>
                            </div>
                        </section>
                        <!-- app chat window ends -->
                        <!-- app chat profile right sidebar start -->
                        <section class="chat-profile">
                            <header class="chat-profile-header text-center border-bottom">
                                <span class="chat-profile-close">
                                    <i class="ft-x"></i>
                                </span>
                                <div class="my-2">

                                    <img src="/hospital/app-assets/images/portrait/small/avatar-docteur.png" class="round mb-1"
                                        alt="chat avatar" height="100" width="100">

                                    <h5 class="app-chat-user-name mb-0">Elizabeth Elliott</h5>
                                    <span>Devloper</span>
                                </div>
                            </header>
                            <div class="chat-profile-content p-2 ps">
                                <h6 class="mt-1">ABOUT</h6>
                                <p>It is a long established fact that a reader will be distracted by the readable content.
                                </p>
                                <h6 class="mt-2">PERSONAL INFORMATION</h6>
                                <ul class="list-unstyled">
                                    <li class="mb-25">email@gmail.com</li>
                                    <li>+1(789) 950-7654</li>
                                </ul>
                                <div class="ps__rail-x" style="left: 0px; bottom: 0px;">
                                    <div class="ps__thumb-x" tabindex="0" style="left: 0px; width: 0px;"></div>
                                </div>
                                <div class="ps__rail-y" style="top: 0px; right: 0px;">
                                    <div class="ps__thumb-y" tabindex="0" style="top: 0px; height: 0px;"></div>
                                </div>
                            </div>
                        </section>
                        <!-- app chat profile right sidebar ends -->

                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script setup>
import API_BASE_URL from '@/services/configs/environment.js';
import { ref, reactive, onMounted, computed } from 'vue'
import { useStore } from 'vuex'
import Search from "@/components/Search"
import UsersService from "@/services/modules/utilisateur.service.js";
import VueMultiselect from "vue-multiselect";
import { createToast } from "mosha-vue-toastify";
// import the styling for the toast
import "mosha-vue-toastify/dist/style.css";
import ConversationService from "@/services/modules/conversation.service.js";
import UserConversationService from "@/services/modules/userConversation.service.js";
import MessageService from "@/services/modules/message.service.js";
import VueElementLoading from 'vue-element-loading'

const conversations = ref([]);
const activeConversationId = ref(null);
const messages = ref([]);
const search = ref("");
const receiverName = ref(null);
const user_log = ref("");
const users = ref([])
const deleteData = ref(null)
const imageUrl = ref({});



const formData = reactive({
    conversation_id: "",
    receiver_id: "",
    sender_id: "",
    message: "",
    fichier: null,
});


const conversationParams = reactive({
    "user1_id": "",
    "user2_id": "",
})

const supprimer = function (recupId) {
    deleteData.value = recupId;
};

const toast = (message, type) => {
    createToast(message, { type: type });
};

const addConversation = function () {
    close()
}

const close = function () {
    conversationParams.user1_id = ''
    conversationParams.user2_id = ''
}


//upload de photo de compagnie
function handleFileUpload(event) {
    formData.fichier = event.target.files[0];
    toast("Fichier uploadé avec succès", "success");
    const data = new FormData();
    data.append('message', formData.message);
    data.append('conversation_id', formData.conversation_id);
    data.append('receiver_id', formData.receiver_id);
    data.append('sender_id', formData.sender_id);
    if (formData.fichier) {
        data.append('fichier', formData.fichier);
    }
    MessageService.create(data).then((response) => {
        if (response.data.status == 'success') {
            formData.message = "";
            fetchMessages(formData.conversation_id);
        } else {
            toast(response.data.message, 'danger')
        }
    })
};

//getData
const getSearch = function (data) {
    search.value = data;
};

const getUsers = () => {
    UsersService.get().then((data) => {
        const datas = data.data.data
        users.value = datas.data
    }).catch((e) => {
        console.log(e)
    })
}

const deleteConversation = function () {
    ConversationService.destroy(deleteData.value)
        .then((data) => {
            if (data.data.status == "success") {
                toast("Suppression effectuée avec succès", "success");
                location.reload()
            } else {
                toast(data.data.message, "danger");
            }
        })
        .catch((error) => {
            if (error.response) {
                // Requête effectuée mais le serveur a répondu par une erreur.
                const erreurs = error.response.data.message;
                toast(erreurs, "danger");
            } else if (error.request) {
                // Demande effectuée mais aucune réponse n'est reçue du serveur.
                //console.log(error.request);
            } else {
                // Une erreur s'est produite lors de la configuration de la demande
            }
        });
};


const storeConversation = function () {
    const userInfo = JSON.parse(localStorage.getItem("paevcliniqueInfo"));
    if (userInfo) {
        user_log.value = userInfo.users.id;
    }
    conversationParams.user2_id = conversationParams.user2_id.id
    conversationParams.user1_id = user_log
    if (conversationParams.user1_id == conversationParams.user2_id) {
        toast('Impossible créer une conversation avec vous meme', 'danger')
    } else {
        ConversationService.create(conversationParams).then((response) => {
            if (response.data.status == 'error') {
                toast(response.data.message, 'danger')
            }
            else {
                const recupConversationId = response.data.data.id
                fetchConversations()
                setActiveConversation(response.data.data)
            }

        })
    }


}

const resultQuery = computed(() => {
    if (search.value) {
        return conversations.value.filter((conversation) => {
            return (
                search.value
                    .toLowerCase()
                    .split(" ")
                    .every((v) => conversation.user2.name.toLowerCase().includes(v))
            );
        });
    } else {
        return conversations.value;
    }
});

onMounted(() => {
    getUsers()
    const userInfo = JSON.parse(localStorage.getItem("paevcliniqueInfo"));
    if (userInfo) {
        user_log.value = userInfo.users.id;
    }
});


async function fetchConversations() {
    try {
        const response = await UserConversationService.get();
        conversations.value = response.data.data;
    } catch (error) {
        console.error(error);
    }
}

async function fetchMessages(id) {
    try {
        const response = await ConversationService.get(id);
        messages.value = response.data.data.messages;
        if (user_log.value != response.data.data.user2.id) {
            receiverName.value = response.data.data.user2.name
        }
        else if (user_log.value != response.data.data.user1.id) {
            receiverName.value = response.data.data.user1.name
        }
        response.data.data.messages.forEach(msg => {
            if (msg.fichier) {
                fetchImg(msg)
            }
        });

    } catch (error) {
        console.error(error);
    }
}


function storeMessage() {
    const data = new FormData();
    data.append('message', formData.message);
    data.append('conversation_id', formData.conversation_id);
    data.append('receiver_id', formData.receiver_id);
    data.append('sender_id', formData.sender_id);
    if (formData.fichier) {
        data.append('fichier', formData.fichier);
    }
    MessageService.create(data).then((response) => {
        if (response.data.status == 'success') {
            formData.message = "";
            fetchMessages(formData.conversation_id);
        } else {
            toast(response.data.message, 'danger')
        }
    }).catch((e) => {
        //   chargement.value = false  
        if (error.response) {
            // Requête effectuée mais le serveur a répondu par une erreur.
            const message = error.response.data.message
            toast(message, "success");
        } else if (error.request) {
            // Demande effectuée mais aucune réponse n'est reçue du serveur.
            //console.log(error.request);
        } else {
            // Une erreur s'est produite lors de la configuration de la demande
            //console.log('dernier message', error.message);
        }
    })

}

function setActiveConversation(conversation) {
    activeConversationId.value = conversation.id;
    formData.conversation_id = conversation.id
    if (user_log.value != conversation.user1_id) {
        formData.receiver_id = conversation.user1_id
        formData.sender_id = conversation.user2_id
    }
    else if (user_log.value != conversation.user2_id) {
        formData.receiver_id = conversation.user2_id
        formData.sender_id = conversation.user1_id
    }
    fetchMessages(conversation.id);
}

const activeConversation = computed(() => {
    return conversations.value.find(conversation => conversation.id === activeConversationId.value);
});
fetchConversations();

function fetchImg(message) {
    console.log(API_BASE_URL)
    const userInfo = JSON.parse(localStorage.getItem("paevcliniqueInfo"));
    const url = 'https://api.paevclinique.com/api/private-files/' + message.fichier;
    const token = 'Bearer ' + userInfo.token;

    fetch(url, {
    headers: {
        'Authorization': token
    }
    })
    .then(response => response.blob())
    .then(blob => {
        imageUrl.value[message.id] = URL.createObjectURL(blob);
    // Utilisez l'URL de l'image pour l'afficher dans votre application Vue.js
    })
    .catch(error => console.error(error));
}

</script>

<style>
.sendbtnfloat {
    position: fixed;
    width: 60px;
    height: 60px;
    bottom: 70px;
    left: 480px;
    border-radius: 50px;
    text-align: center;
    box-shadow: 2px 2px 3px #999;
    z-index: 99;
    font-size: 18px;
}

.contenu-message{
    overflow: auto;
}

@media screen and (max-width: 900px) {
    .sendbtnfloat {
        width: 50px;
        height: 50px;
        bottom: 60px;
        left: 600px;
    }
}
</style>