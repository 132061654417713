
import ApiService from '@/services/configs/api.service'

const ConseilService = {
  get(id) {
    return ApiService.get(`consultation/${id}/advices`);
  },
 
  create(params) {
    return ApiService.post("advices", params);
  },

  
  update(slug, params) {
    return ApiService.update("advices", slug, params);
  },

  destroy(slug) {
    return ApiService.delete(`advices/${slug}`);
  },
 

 
};

  export default ConseilService;
  