
import ApiService from '@/services/configs/api.service'

const ConsultationService = {
  get(slug,element) {
    return ApiService.get(`consultations`, slug,element);
  },
 
  create(params) {
    return ApiService.post("consultations", params);
  },

  update(slug, params) {
    return ApiService.update("consultations", slug, params);
  },

  destroy(slug) {
    return ApiService.delete(`consultations/${slug}`);
  },
 

 
};

  export default ConsultationService;
  