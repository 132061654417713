<template>
    <div>
      <div class="app-content content">
        <div class="content-overlay"></div>
        <div class="content-wrapper">
          <div class="content-header row">
            <div class="content-header-left col-md-6 col-12 mb-2">
              <!-- <h3 class="content-header-title">Gérer les rendez-vous </h3> -->
              <div class="row breadcrumbs-top">
                <div class="breadcrumb-wrapper col-12">
                  <ol class="breadcrumb">
                   
                    <li class="breadcrumb-item"><a href="#">Ceci concerne les feedbacks des patients</a>
                    </li>
                  </ol>
                </div>
              </div>
            </div>
            <!-- Definition du modal -->
            <!-- modal voir  -->
            <div class="modal fade text-left" id="voirM" tabindex="-1" role="dialog" aria-labelledby="" aria-hidden="true">
              <div class="modal-dialog " role="document">
                <div class="modal-content">
                  <div class="modal-header bg-success white">
                    <h4 class="modal-title white" id=""> Détails</h4>
                    <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                      <span aria-hidden="true">&times;</span>
                    </button>
                  </div>
                  <div class="modal-body">
                    <div class="card" >
                      <div class="card-body">
                        <div class="row">		
                          <div class="col-lg-12 d-flex justify-content-around" >        								
                            <div class="media-body"  >
                              <h5 class="media-heading">
                                <strong>Patient: </strong>
                                <span class="float-right content-header-title">
                                    <strong class="text-uppercase">{{ info.patient ? info.patient.name : '' }}</strong> 
                                </span>
                              </h5> 
                              <hr>
                              <h5 class="media-heading">
                                <strong>Commentaire : </strong>
                              </h5> 
                              <p>
                                {{ info.comment }}
                              </p>
                              <hr>

                            </div>
                                  
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
  
                </div>
              </div>
            </div>
  
          </div>
          <div class="content-body">
            <section class="row">
              <div class="col-12">
                <div class="card">
                  <div class="card-head">
                    <div class="my-2 d-flex justify-content-end">
                      <div>
                        <input v-model="search" type="search" placeholder="Recherche..." id="form1" class="form-control" />
                      </div>
                    </div>
                  </div>
                  <div class="card-content">
                    <div class="card-body">
                      <!-- Invoices List table -->
                      <div class="table-responsive">
                        <table class="table table-bordered table-striped table-hover ">
                          <thead class="bg-info white">
                            <tr>
                              <th>Date </th>
                              <th>Patient </th>
                              <th>Commentaire du patient</th>
                              <th>Actions</th>
                            </tr>
                          </thead>
                          <tbody>
                            <tr v-for="(feedback, index) in feedbacks">
                              <td>{{ feedback.created_at_fr }}</td>
                              <td>{{ feedback.patient.name }}</td>
                              <td>{{ feedback.comment }}</td>
                              <td>
                                <a href="#" class="btn btn-primary round btn-sm waves-effect waves-light" @click="voirFeedback(feedback.id)" data-toggle="modal" data-target="#voirM"><i class="la la-eye"></i></a>
                                <!-- <router-link :to="'/admin/patient/add/' + feedback.id" class="btn btn-info btn-xs" >
                                  <i class="la la-eye" style="font-weight: bold;"></i> voir le patient
                                </router-link> -->
                              </td>
                            </tr>
  
  
                          </tbody>
                        </table>
                        <nav aria-label="pagination ">
                          <ul class="pagination justify-content-center">
                            <li class="page-item "><span @click="prev()" class="page-link pointer" tabindex="-1"> &#171;
                              </span></li>
                            <li v-for="item in pagination.total" :key="item"
                              :class="{ 'active': item === pagination.current }" class="page-item pointer"><span
                                class="page-link" @click="getData(item)"> {{ item }} </span>
                            </li>
  
                            <li class="page-item"> <span class="page-link pointer" @click="next()">&#187; </span></li>
                          </ul>
                        </nav>
                      </div>
                      <!--/ Invoices table -->
                    </div>
                  </div>
                </div>
              </div>
            </section>
          </div>
        </div>
      </div>
    </div>
  </template>
  
  
  <script setup>
  let badges = {'en cours': "warning", 'annulé': "danger", 'terminé': "success"}
  
  function isObject(value) {
    return typeof value === 'object' && value !== null && value.constructor === Object;
  }
  
  
  import FeedbackService from "@/services/modules/feedbacks.service.js";
import { onMounted, reactive, ref, watch } from 'vue';
  
  const info = ref([])
  const feedbacks = ref([])
  const isActive = ref(false)
  const pagination = reactive({
    first: null,
    last: null,
    next: null,
    prev: null,
    current: null,
    total: null
  })
  const search = ref('')
  let timeout = null;
  watch(search, (newValue, oldValue) => {
    if (timeout) {
      clearTimeout(timeout);
    }

    timeout = setTimeout(() => {
      getData(1)
    }, 500); 
  });
  
  
  const prev = function () {
    if (pagination.current >= 0) {
      pagination.current = 1
      getData(pagination.current)
    }
    else {
      pagination.current--
      getData(pagination.current)
    }
  }
  const next = function () {
    pagination.current++
    if (pagination.current <= pagination.total) {
      getData(pagination.current)
    }
  }
  
  // const active = () => isActive.value = true
  // const disabled = () => isActive.value = false
  const getData = (element = 1) => {
    // active()
    FeedbackService.get(null, `?search=${search.value}&page=${element}`).then((data) => {
      const datas = data.data.data
      console.log(datas)
      pagination.total = Math.ceil(datas.total / datas.per_page)
      pagination.current = datas.current_page
      pagination.last = datas.last_page
      pagination.first = 1
  
      feedbacks.value = datas.data
      // disabled()
    }).catch((e) => {
      // disabled()
      console.log(e)
    })
  }
  
  
  const voirFeedback = (id) => {
    FeedbackService.get(id).then((data) => {
      info.value = data.data.data
      // info.value.patient_name = data.data.data.patient.name 
    }).catch((e) => {
      console.log(e)
    })
  }
  
  
  
  onMounted(() => {
    getData()
  })
  </script>
  