
import ApiService from '@/services/configs/api.service'

const SuscriberService = {
  get(slug,element) {
    return ApiService.get(`suscribers`, slug,element);
  },

  show(slug) {
    return ApiService.delete(`suscribers/${slug}`);
  },
 

 
};

  export default SuscriberService;
  