<template>
  <div>
    <div class="app-content content">
      <div class="content-overlay"></div>
      <div class="content-wrapper">
        <div class="content-header row">
          <div class="content-header-left col-md-6 col-12 mb-2">
            <!-- <h3 class="content-header-title">Gérer les rendez-vous </h3> -->
            <div class="row breadcrumbs-top">
              <div class="breadcrumb-wrapper col-12">
                <ol class="breadcrumb">
                  <li class="breadcrumb-item"><a href="index.html">Liste de tous les patients</a>
                  </li>
                  <li class="breadcrumb-item"><a href="#">Patient</a>
                  </li>
                  <li class="breadcrumb-item active">Conseils & suivi
                  </li>
                </ol>
              </div>
            </div>
          </div>
          <!-- Definition du modal -->
          <!-- modal ajout  -->
          <div class="modal fade text-left" id="large" tabindex="-1" role="dialog" aria-labelledby="" aria-hidden="true">
            <div class="modal-dialog modal-sm" role="document">
              <div class="modal-content">
                <div class="modal-header bg-success white">
                  <h4 class="modal-title white" id="">
                    <i class="la la-edit"></i>

                  </h4>
                  <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                    <span aria-hidden="true">&times;</span>
                  </button>
                </div>
                <div class="modal-body">
                  <form>
                    <div>
                      <div>
                        <div class="container">
                          <div class="row">
                            <div class="col-lg-12">
                              <label for="patient">Identité du patient</label>
                              <fieldset class="form-group floating-label-form-group">
                                <label for="patient">Sélectionner le patient</label>
                                <VueMultiselect v-model="formData.patient" label="name" track-by="id"
                                  :selectLabel="'Appuyez sur Entrée pour sélectionner'"
                                  :deselectLabel="'Appuyez sur Entrée pour supprimer'" selectedLabel="Selectionné"
                                  tag-placeholder="Selectionnez un utilisateur" placeholder="Selectionnez un patient"
                                  :options="patients">
                                </VueMultiselect>
                              </fieldset>
                            </div>
                          </div>

                          <div class="row">
                            <!-- <div class="col-lg-12">
                              <fieldset class="form-group floating-label-form-group">
                                <label for="patient">DIAGNOSTICS RETENUS</label>
                                <textarea name="" class="form-control" id="" cols="10"
                                  disabled>Patient diabétique</textarea>
                              </fieldset>
                            </div> -->
                            <div class="col-lg-12">
                              <label for="patient">Identité du praticien</label>
                              <fieldset class="form-group floating-label-form-group">
                                <VueMultiselect v-model="formData.doctor" label="name" track-by="id"
                                  :selectLabel="'Appuyez sur Entrée pour sélectionner'"
                                  :deselectLabel="'Appuyez sur Entrée pour supprimer'" selectedLabel="Selectionné"
                                  tag-placeholder="Selectionnez le praticien" placeholder="Selectionnez un praticien"
                                  :options="praticiens">
                                </VueMultiselect>
                              </fieldset>
                            </div>


                          </div>


                          <div class="row">
                            <div class="col-lg-12">
                              <fieldset class="form-group floating-label-form-group">
                                <label for="patient">Votre conseil </label>
                                <textarea name="" class="form-control" id="" cols="10" rows="10" v-model="formData.comment"></textarea>
                              </fieldset>
                            </div>
                          </div>

                        </div>
                      </div>
                    </div>
                    <div class=" d-flex justify-content-center py-1  ">
                      <button type="button" @click="close" class="btn grey btn-danger btn-sm mx-3 fs-5 fs-semibold"
                        data-dismiss="modal">
                        Fermer
                      </button>
                      <button @click.prevent="storeConseil" data-dismiss="modal" aria-label="Close" type="submit" class="btn btn-primary flex">
                        <span class="fs-5 fs-semibold">
                          Envoyer
                        </span>
                      </button>
                    </div>
                  </form>

                </div>

              </div>
            </div>
          </div>

          <!-- <div class="content-header-right col-md-6 col-12">

            <div class="btn-group float-md-right" role="group" aria-label="Button group with nested">
              <div class="form-group">
                <button type="button" @click="addRdv" class="btn btn-info round box-shadow-2 px-2 mb-1"
                  data-toggle="modal" data-target="#large">
                  + Conseils
                </button>
              </div>
            </div>
          </div> -->
        </div>
      </div>
      <div class="content-body">
        <section class="row">
          <div class="col-12">
            <div class="card">
              <div class="card-head">
                <div class="my-2 d-flex justify-content-end">
                  <div>
                    <Search @search="getSearch" />
                  </div>
                </div>
              </div>
              <div class="card-content">
                <div class="card-body">
                  <!-- Invoices List table -->
                  <div class="table-responsive">
                    <table class="table table-bordered table-striped table-hover ">
                      <thead class="bg-info white">
                        <tr>
                          <th class="text-center">PATIENTS </th>
                          <th class="text-center">CONSEILS</th>
                          <th class="text-center">SUIVI</th>

                        </tr>
                      </thead>
                      <tbody>
                        <!-- PAID -->
                        <tr v-for="(patient, index) in resultQuery">
                          <td>{{ patient.name }}</td>
                          <td class="">
                            <router-link :to="{ name: 'ficheConseil', params: { id: patient.id } }">
                              <span class="btn mr-1 mb-1 btn-info btn-sm">Conseil pour {{
                                patient.name }} </span>
                            </router-link>
                            
                          </td>
                          <td class="">
                            <router-link to="/admin/evolution-traitement">
                              <span class="btn mr-1 mb-1 btn-primary btn-sm">Suivi</span>
                            </router-link>
                          </td>
                          
                        
                        </tr>


                      </tbody>

                    </table>
                  </div>
                  <nav aria-label="pagination ">
                    <ul class="pagination justify-content-center">
                      <li class="page-item "><span @click="prev()" class="page-link pointer" tabindex="-1"> &#171;
                        </span></li>
                      <li v-for="item in pagination.total" :key="item" :class="{ 'active': item === pagination.current }"
                        class="page-item pointer"><span class="page-link" @click="getData(item)"> {{ item }} </span>
                      </li>

                      <li class="page-item"> <span class="page-link pointer" @click="next()">&#187; </span></li>
                    </ul>
                  </nav>
                  <!--/ Invoices table -->
                </div>
              </div>
            </div>
          </div>
        </section>
      </div>
    </div>
  </div>
</template>

<script setup>
import { ref, reactive, onMounted, computed } from 'vue'
import { useStore } from 'vuex'
import { createToast } from "mosha-vue-toastify";
import Search from "@/components/Search"
import PatientService from "@/services/modules/patient.service.js";
import ConseilService from "@/services/modules/conseil.service.js";
import PraticienService from "@/services/modules/praticien.service.js";
import VueMultiselect from "vue-multiselect";
// import the styling for the toast
import "mosha-vue-toastify/dist/style.css";
import VueElementLoading from 'vue-element-loading'

const search = ref('')
const infoMeetings = ref([])
const conseils = ref([])
const patients = ref([])
const praticiens = ref([])
const isActive = ref(false)
const deleteId = ref(null)
const pagination = reactive({
  first: null,
  last: null,
  next: null,
  prev: null,
  current: null,
  total: null
})


const formData = reactive(
  {
    comment: '',
    patient_id: '',
    doctor_id: '',
  }
)

function closeConseil() {
  formData.comment = ""
  formData.patient = ""
  formData.doctor = ""
}

const toast = (message, type) => {
  createToast(message, { type: type })
}

const prev = function () {
  if (pagination.current >= 0) {
    pagination.current = 1
    getData(pagination.current)
  }
  else {
    pagination.current--
    getData(pagination.current)
  }
}
const next = function () {
  pagination.current++
  if (pagination.current <= pagination.total) {
    getData(pagination.current)
  }
}

const resultQuery = computed(()=> {
    if(search.value){
      return patients.value.filter((item)=>{
        return search.value.toLowerCase().split(' ').every(v => item.name.toLowerCase().includes(v)) ||
        search.value.toLowerCase().split(' ').every(v => item.consultation_type.toLowerCase().includes(v)) ||
        search.value.toLowerCase().split(' ').every(v => item.phone.toLowerCase().includes(v))
      })

      }else{
        return patients.value;
      }
  }) 
  //getData
  const getSearch = function(data) {
    search.value = data
  }

  
const active = () => isActive.value = true
const disabled = () => isActive.value = false
const getData = (element = 1) => {
  active()
  PatientService.get("",element).then((data) => {
    const datas = data.data.data
    pagination.total = Math.ceil(datas.total / datas.per_page)
    pagination.current = datas.current_page
    pagination.last = datas.last_page
    pagination.first = 1

    patients.value = datas.data 
    disabled()
  }).catch((e) => {
    disabled()
      console.log(e)
    })
  }

const storeConseil = function () {
  formData.patient_id = formData.patient.id
  formData.doctor_id = formData.doctor.id
  ConseilService.create(formData).then((response) => {
    console.log(response);
    if (response.data.status == 'error') {
      toast(response.data.message, 'danger')
    }
    else {
      closeConseil()
      getData()
      toast("Vous avez ajouté un nouveau conseil!!!", 'success')
    }

  })
}

const recupRdv = (id) => {
  MeetingService.get(id).then((data) => {
    infoMeetings.value = data.data.data
    console.log(infoMeetings.value);
  }).catch((e) => {
    console.log(e)
  })
}

const updateMeeting = function () {
  const datas = {}
  datas.date_meet = infoMeetings.value.date_meet
  datas.heure = infoMeetings.value.heure
  datas.motif = infoMeetings.value.motif
  datas.type_consultation = infoMeetings.value.type_consultation
  datas.commentaire = infoMeetings.value.commentaire
  datas.patient_id = infoMeetings.value.patient.id
  datas.doctor_id = infoMeetings.value.doctor.id
  MeetingService.update(infoMeetings.value.id, datas).then((response) => {
    console.log(response);
    if (response.data.status == 'error') {
      toast(response.data.message, 'danger')
    }
    else {
      getData()
      toast("Modifications éffectuées avec succès !!!", 'success')
    }

  })
}


const recupId = (id) => {
  deleteId.value = id
}

const supprimerRdv = function () {
  MeetingService.destroy(deleteId.value).then((data) => {
    if (data.data.status == "success") {
      deleteId.value = ""
      getData()
      toast("Suppression du rendez vous", "success");
    } else {
      toast(data.data.message, "danger");
    }
  })
}

const getPatients = () => {
  PatientService.get().then((data) => {
    const datas = data.data.data
    patients.value = datas.data
  }).catch((e) => {
    console.log(e)
  })
}

const getPraticiens = () => {
  PraticienService.get().then((data) => {
    const datas = data.data.data
    praticiens.value = datas.data
  }).catch((e) => {
    console.log(e)
  })
}

onMounted(() => {
  getData()
  getPatients()
  getPraticiens()
})
</script>
