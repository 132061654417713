
import ApiService from '@/services/configs/api.service'

const MeetingService = {
  get(slug,element) {
    return ApiService.get(`meetings`, slug,element);
  },
 
  create(params) {
    return ApiService.post("meetings", params);
  },

  update(slug, params) {
    return ApiService.update("meetings", slug, params);
  },

  destroy(slug) {
    return ApiService.delete(`meetings/${slug}`);
  },
 

 
};

  export default MeetingService;
  