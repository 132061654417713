<template>
    <div>
        Praticien Edit
        
    </div>
</template>

<script>
export default {
    name: 'PraticienEdit',
}
</script>

<style>

</style>