
import ApiService from '@/services/configs/api.service'

const PatientMenuService = {
  get(slug) {
    return ApiService.get(`patients/${slug}/menus`);
  },
 
};

  export default PatientMenuService;
  