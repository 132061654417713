
import ApiService from '@/services/configs/api.service'

const FicheStresseurService = {
  get(slug,element) {
    return ApiService.get(`stress-sheets`, slug,element);
  },
 
  create(params) {
    return ApiService.post("stress-sheets", params);
  },

  update(slug, params) {
    return ApiService.update("stress-sheets", slug, params);
  },

  destroy(slug) {
    return ApiService.delete(`stress-sheets/${slug}`);
  },
 

 
};

  export default FicheStresseurService;
  