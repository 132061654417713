<template>
  <div>
    <div class="app-content content">
      <div class="content-overlay"></div>
      <div class="content-wrapper">
        <div class="content-header row">
          <div class="content-header-left col-md-6 col-12 mb-2">
            <!-- <h3 class="content-header-title">Enregistrer un patient</h3> -->

            <div class="row breadcrumbs-top">
              <div class="breadcrumb-wrapper col-12">
                <ol class="breadcrumb">
                  <li class="breadcrumb-item"><a href="/">Retour Tableau de bord</a></li>
                  
                </ol>
              </div>
            </div>
          </div>
          <div class="content-header-right col-md-6 col-12">
            <div
              class="btn-group float-md-right"
              role="group"
              aria-label="Button group with nested "
            >
              <router-link
                to="/dashboard/patient"
                class="btn btn-info round box-shadow-2 px-2 mb-1"
                id="btnGroupDrop1"
                type="button"
                aria-haspopup="true"
                aria-expanded="false"
              >
                <i class="ft-arrow-left"></i> Liste des patients
              </router-link>
            </div>
          </div>
        </div>
       
        <div class="content-body">
          <!-- Add Patient Form Wizard -->
          <section id="add-patient">
            <div class="icon-tabs">
              <div class="row">
                <div class="col-12">
                  <div class="card">
                    
                    <div class="card-content collapse show">
                      <div class="card-body">
                        <form
                          @submit.prevent="addPatient"
                          class="add-patient-tabs steps-validation wizard-notification"
                        >
                          <!-- step 1 => Personal Details -->
                          <h6>
                            <i class="step-icon la la-user font-medium-3"> </i>
                            Details Personnel sur lié au patient
                          </h6>
                          <fieldset>
                            <div class="row">
                              
                              <div class="col-md-6">
                                <div class="form-group">
                                  <label for="name">
                                    Nom et Prénom (s):
                                   
                                  </label>
                                  <input
                                    class="form-control"
                                    id="name"
                                    name="name"
                                    v-model="formData.infoGeneral.name"
                                    type="text"
                                  />
                                </div>
                              </div>
                              <div class="col-md-6">
                                <div class="form-group">
                                  <label for="dateA">
                                    Date d'arrivée
                                   
                                  </label>
                                  <input
                                    class="form-control"
                                    id="dateArrivee"
                                    name="dateArrivee"
                                    v-model="formData.infoGeneral.dateArriver"
                                    type="date"
                                  />
                                </div>
                              </div>
                              <div class="col-md-6">
                                <div class="form-group">
                                  <label for="city"> Sexe  
                                    <!-- <span class="danger"> * </span>  -->
                                  </label>
                                  <select
                                    v-model="formData.infoGeneral.sexe"
                                    class="custom-select"
                                    id="city"
                                  >
                                    <option value="Masculin">Masculin</option>
                                    <option value="Feminin">Feminin</option>
                                  </select>
                                </div>
                              </div>

                              <div class="col-md-6">
                                <div class="form-group">
                                  <label for="city">
                                    Situation Matrimoniale
                                  </label>
                                  <select
                                    v-model="
                                      formData.infoGeneral.situationMatrimoniale
                                    "
                                    class="custom-select"
                                    id="city"
                                  >
                                    <option value="Mariee">Marié(e)</option>
                                    <option value="célibataire">
                                      célibataire
                                    </option>
                                    <option value="Concubinage">
                                      Concubinage
                                    </option>
                                    <option value="Veuf">Veuf (ve)</option>
                                  </select>
                                </div>
                              </div>
                            </div>

                            <div class="row">

                              <div class="col-md-6">
                                <div class="form-group">
                                  <label for="address"> Adresse: </label>
                                  <input
                                    class="form-control"
                                    id="address"
                                    v-model="formData.infoGeneral.adresse"
                                    type="text"
                                  />
                                </div>
                              </div>

                              <div class="col-md-6">
                                <div class="form-group">
                                  <label for="contact"> Profession </label>
                                  <input
                                    class="form-control"
                                    id="profession"
                                    name="profession"
                                    v-model="formData.infoGeneral.profession"
                                    type="text"
                                  />
                                </div>
                              </div>

                              <div class="col-md-6">
                                <div class="form-group">
                                  <label for="contact"> Nombre d'enfant </label>
                                  <input
                                    class="form-control"
                                    id="contact"
                                    name="contact"
                                    v-model="formData.infoGeneral.nbreEnfant"
                                    type="number"
                                  />
                                </div>
                              </div>

                              <div class="col-md-6">
                                <div class="form-group">
                                  <label for="contact">
                                    Numéro de télephone:
                                    <span class="danger"> * </span>
                                  </label>
                                  <input
                                    class="form-control"
                                    id="contact"
                                    name="contact"
                                    type="tel"
																		required
                                    v-model="formData.infoGeneral.telephone"
                                  />
                                </div>
                              </div>

                              <div class="col-md-6">
                                <div class="form-group">
                                  <label for="dob">
                                    Âge :
                                   
                                  </label>
                                  <input
                                    class="form-control"
                                    id="dob"
                                    name="dateofbirth"
                                    type="number"
                                    v-model="formData.infoGeneral.age"
                                  />
                                </div>
                              </div>

                              <div class="col-md-6">
                                <div class="form-group">
                                  <label for="contact">
                                    Adresse Email:
                                   
                                  </label>
                                  <input
                                    class="form-control"
                                    id="email"
                                    name="email"
                                    type="email"
                                    v-model="formData.infoGeneral.email"
                                  />
                                </div>
                              </div>

                              <div class="col-md-6">
                                <div class="form-group">
                                  <label for="dob">
                                   Saississez un mot de passe pour ce patient:
                                   
                                  </label>
                                  <input
                                    class="form-control"
                                    id="pass"
                                    name="password"
                                    type="password"
                                    v-model="formData.infoGeneral.password"
                                  />
                                </div>
                              </div>

                            </div>
                          </fieldset>
                          <!-- Step 2 => Emergency Details-->
                          <h6>
                            <i class="step-icon la la-ambulance font-medium-3">
                            </i>
                            Personne à contacter en cas d'urgence
                          </h6>
                          <fieldset>
                            <div class="row">
                              <div class="col-md-6">
                                <div class="form-group">
                                  <label for="fnemergency">
                                    Nom :
                                   
                                  </label>
                                  <input
                                    class="form-control"
                                    id="fnemergency"
                                    name="fnemergency"
                                    type="text"
                                    v-model="formData.personneContacter.name"
                                  />
                                </div>
                              </div>
                           
                              <div class="col-md-6">
                                <div class="form-group">
                                  <label for="relation">
                                    Relation avec le patient:
                                   
                                  </label>
                                  <input
                                    class="form-control"
                                    id="relation"
                                    name="relation"
                                    v-model="
                                      formData.personneContacter
                                        .relationAvecPatient
                                    "
                                    type="text"
                                  />
                                </div>
                              </div>

                              <div class="col-md-6">
                                <div class="form-group">
                                  <label for="relativeadd"> Adresse: </label>
                                  <input
                                    class="form-control"
                                    id="relativeadd"
                                    name="relativeadd"
                                    v-model="formData.personneContacter.adresse"
                                    type="text"
                                  />
                                </div>
                              </div>
                           
                              <div class="col-md-6">
                                <div class="form-group">
                                  <label for="cn">
                                    Télephone:
                                   
                                  </label>
                                  <input
                                    class="form-control"
                                    id="cn"
                                    name="cn"
                                    type="tel"
                                    v-model="
                                      formData.personneContacter.telephone
                                    "
                                  />
                                </div>
                              </div>
                            </div>
                          </fieldset>

													<button class="btn btn-primary w-100 flex" type="submit">
														<span class="fs-5 fs-semibold" v-if="!chargement">
															ENREGISTRER 
														</span>
														<span v-else class="d-flex justify-content-center align-items-center">
															<span class="mx-2 fs-semibold text-light">
																Chargement ...
															</span>
															<div
																style="width: 1.5rem; height: 1.5rem"
																class="spinner-border text-light"
																role="status"
															>
																<span class="sr-only">En cours d'execution...</span>
															</div>
														</span>
              						</button>
                        </form>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </section>
        </div>
      </div>
    </div>
  </div>
</template>

<script setup>
import { ref, reactive, onMounted, computed } from 'vue'
import { useRouter, useRoute } from "vue-router";
// import the library
import { createToast } from "mosha-vue-toastify";
// import the styling for the toast
import "mosha-vue-toastify/dist/style.css";
import PatientService from "@/services/modules/patient.service.js";
import SuscriberService from "@/services/modules/suscriber.service.js";


const router = useRouter();
const route = useRoute();


//datas
const formData = reactive({
  infoGeneral: {
    name: "",
    dateArriver: "",
    situationMatrimoniale: "",
    adresse: "",
    sexe: "Masculin",
    // typeConsultation: "Medecine generale",
    profession: "",
    nbreEnfant: 0,
    telephone: "",
    age: 0,
    email: "",
    password: ""
  },
  personneContacter: {
    name: "",
    relationAvecPatient: "",
    adresse: "",
    telephone: "",
    // autreTelephone: "",
  },
  symptomes: {
    passage: true,
    symptomes: "",
    dateSymptomes: "",
    autreRemarque: "",
  },
  assurance: {
    estAssurer: true,
    nomCompagnie: "",
    numeroAssurance: "",
    montantAssurance: "",
    dateExpiration: "",
  },
})
const chargement = ref(false)
let datas

//methods
const toast = (message,type) => {
  createToast(message,{type:type})
}

const checkAssurance = reactive(
	{
		yes:true,
		non:false	
	}
)
const checkSymptome = reactive(
	{
		yes:true,
		non:false	
	}
)



const addPatient = () => {
  if(chargement.value == false) {
    chargement.value = true
    const data = {
    "name": formData.infoGeneral.name,
    "address": formData.infoGeneral.adresse,
    // "consultation_type": formData.infoGeneral.typeConsultation,
    "sex": formData.infoGeneral.sexe,
    "phone": formData.infoGeneral.telephone,
    "marital_status": formData.infoGeneral.situationMatrimoniale,
    "children_count": formData.infoGeneral.nbreEnfant,
    "age": formData.infoGeneral.age,
    "profession": formData.infoGeneral.profession,
    "date_arrive": formData.infoGeneral.dateArriver,
    "email": formData.infoGeneral.email,
    "password": formData.infoGeneral.password,
    "contacts": [
        {
            "name": formData.personneContacter.name,
            "relation": formData.personneContacter.relationAvecPatient,
            "address": formData.personneContacter.adresse,
            "phone": formData.personneContacter.telephone,
        }
    ],
    

    }
		PatientService.create(data).then((data) => {
      const response = data.data
        chargement.value = false
   
        toast('vous avez créer un patient', 'success')
        router.go(-1)
      }).catch((e) => {
        chargement.value = false  
				toast(e, 'danger')
    })
  }
}


function getSuscriber(id){
  SuscriberService.get(id).then((data) => {
      datas = data.data.data
      formData.infoGeneral.name = datas.name
      formData.infoGeneral.adresse = datas.address
      formData.infoGeneral.sexe = datas.sex
      formData.infoGeneral.profession = datas.job
      formData.infoGeneral.telephone = datas.phone
      formData.infoGeneral.age = datas.age
      formData.infoGeneral.name = datas.name
      formData.infoGeneral.name = datas.name
      formData.infoGeneral.name = datas.name
      
      // infoSuscribers.value.patient_name = data.data.data.patient.name
    }).catch((e) => {
      console.log(e)
    })
}


onMounted(() => {
  if(route.params.suscriber_id){
    getSuscriber(route.params.suscriber_id)
  }
})

</script>

<style scoped>
.flex {
	display: flex;
	justify-content: center;
	align-items:center
}
</style>
