<template>
  <div>
      <div class="app-content content">
      <div class="content-overlay"></div>
      <div class="content-wrapper">
          <div class="content-header row">
              <div class="content-header-left col-md-6 col-12 mb-2">
                  <!-- <h3 class="content-header-title">Gérer les rendez-vous </h3> -->
                  <div class="row breadcrumbs-top">
                      <div class="breadcrumb-wrapper col-12">
                          <ol class="breadcrumb">
                              <li class="breadcrumb-item">
                                  <router-link to="/admin/fiche-conseil">
                                      <span>Conseil & recommandation</span>
                                  </router-link>
                                 
                              </li>
                             
                              <li class="breadcrumb-item active">
                                  <router-link to="/admin/evolution-traitement">
                                      <span>Aller au suivi de ce patient</span>
                                  </router-link>
                              </li>
                              <li class="breadcrumb-item active">
                                  <router-link to="/">
                                      <span>Dossier médical</span>
                                  </router-link>
                              </li>
                          </ol>
                      </div>
                  </div>
              </div>
              <!-- Definition du modal -->
                  <!-- modal ajout  -->
          <div class="modal fade text-left" id="large" tabindex="-1" role="dialog" aria-labelledby="" aria-hidden="true">
              <div class="modal-dialog modal-sm" role="document">
                  <div class="modal-content">
                  <div class="modal-header bg-success white">
                      <h4 class="modal-title white" id="">
                      <i class="la la-edit"></i>
                      
                      </h4>
                      <button
                      type="button"
                      class="close"
                      data-dismiss="modal"
                      aria-label="Close"
                      >
                      <span aria-hidden="true">&times;</span>
                      </button>
                  </div>
                  <div class="modal-body">
                      <form >
                      <div>
                          <div>
                          <div class="container">
                              <div class="row">
                                  <div class="col-lg-12">
                              <fieldset class="form-group floating-label-form-group">
                                  <label for="patient"
                                  >Identité du patient</label>
                                  <input type="text" class="form-control" value="Sarah lance" name="" disabled >
                              </fieldset>
                              </div>
                              </div>
                              
                              <div class="row">
                                  <div class="col-lg-12">
                                      <fieldset class="form-group floating-label-form-group">
                                          <label for="patient"
                                          >DIAGNOSTICS RETENUS</label>
                                          <textarea name="" class="form-control" id="" cols="10" disabled>Patient diabétique</textarea>
                                      </fieldset>
                                  </div>
              
                                  
                              </div>
                              
                              
                              <div class="row">
                                  <div class="col-lg-12">
                                  <fieldset class="form-group floating-label-form-group">
                                      <label for="patient">Votre conseil </label>
                                      
                                      <textarea name="" class="form-control" id="" cols="10" rows="10" ></textarea>
                                  </fieldset>
                                  </div>
                              </div>

                          </div>
                          </div>
                      </div>
                      <div class=" d-flex justify-content-center py-1  ">
                          <button
                          type="button"
                          @click="close"
                          class="btn grey btn-danger btn-sm mx-3 fs-5 fs-semibold"
                          data-dismiss="modal"
                          >
                          Fermer
                      </button>
                      <button :data-dismiss="{'modal':chargement==false}" type="submit" class="btn btn-primary flex" >
                          <span class="fs-5 fs-semibold" v-if="!chargement">
                             Envoyer
                          </span>
                          <span v-else class="d-flex align-items-center">
                              <span class="mx-2 fs-semibold text-light">
                                  chargement ...
                              </span>
                              <div
                                  style="width: 1.5rem; height: 1.5rem"
                                  class="spinner-border text-light"
                                  role="status"
                              >
                                  <span class="sr-only">Loading...</span>
                              </div>
                          </span>
                      </button>
                      </div>
                      </form>
                      
                  </div>
                  
                  </div>
              </div>
              </div>

              <div class="content-header-right col-md-6 col-12">

                  <div
                  class="btn-group float-md-right"
                  role="group"
                  aria-label="Button group with nested">

                  <div class="form-group">
                      <button
                      type="button"
                      @click="addRdv"
                      class="btn btn-info round box-shadow-2 px-2 mb-1"
                      data-toggle="modal"
                      data-target="#large"
                      >
                      <i class="ft-edit icon-left"></i> Conseils
                      </button>
                  </div>
                  </div>
              </div>
          </div>
          

          <section id="plan-medical-nutritionnel">
                  <div class="row">
                    <div class="col-lg-6 col-md-12">
                            <div class="card" style="height: 207.125px;">
                                <div class="card-body">
                                    <div class="row">
                                        <div class="col-lg-4 col-md-4 d-flex justify-content-around">
                                            <div class="patient-img-name text-center">
                                                <img src="/hospital/app-assets/images/portrait/small/avatar-s-18.png" alt="" class="card-img-top mb-1 patient-img img-fluid rounded-circle" style="width: 51%;">
                                                <h4>Sarah lance</h4>
                                                <span class="badge badge-info mr-2">Masculin</span>
                                            </div>
                                        </div>
                                        <div class="col-lg-8 col-md-8 d-flex justify-content-around">
                                            <div class="media-body">
                                                <h5 class="media-heading">
                                                    No Dossier :
                                                    <span class="badge badge-info float-right">Am/3254</span>
                                                </h5>
                                                <hr />
                                                <h5 class="media-heading">
                                                    DIAGNOSTICS:
                                                    <span class="float-right content-header-title">Patient diabétique</span>
                                                </h5>
                                                <hr />
                                                <h5 class="media-heading">
                                                    ALLERGIES:
                                                    <span class="float-right content-header-title">Quinine</span>
                                                </h5>
                                                
                                                
                                             </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="col-lg-3 col-md-6">
                            <div class="card bg-gradient-y-info" style="height: 207.125px;">
                                <div class="card-body">
                                    <ul class="list-unstyled text-white patient-info-card">
                                        <li><span class="patient-info-heading">Groupe Sanguin :</span> B+</li>
                                        <li><span class="patient-info-heading">Electrophorèse:</span> AC</li>
                                        <li><span class="patient-info-heading">G6PD:</span> --</li>
                                        <li><span class="patient-info-heading">IMC : 35 kg/m²</span> </li>
                                        <li><span class="patient-info-heading">Dernier Contrôle :</span> 10 Oct 2022</li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                        <div class="col-lg-3 col-md-6">
                            <div class="card bg-gradient-y-warning" style="height: 207.125px;">
                                <div class="card-header">
                                    <h5 class="card-title text-white">Contrôles réguliers</h5>
                                </div>
                                <div class="card-content mx-2">
                                    <ul class="list-unstyled text-white">
                                        <li>Dr. Denis Dolivera </li>
                                        
                                        
                                    </ul>
                                </div>
                            </div>
                        </div>

                      <div class="col-6 ">
                          <div class="card">
                              <div class="card-header">
                                  <h4 class="card-title">Suivi sur le plan médical</h4>
                                  <a class="heading-elements-toggle"><i class="la la-ellipsis font-medium-3"></i></a>
                                  <div class="heading-elements">
                                      <ul class="list-inline mb-0">
                                         
                                          <li><a data-action="reload"><i class="ft-rotate-cw"></i></a></li>
                                          <li><a data-action="expand"><i class="ft-maximize"></i></a></li>
                                      </ul>
                                  </div>
                              </div>
                              <div class="card-content collapse show">
                                  <div class="card-body">
                                      <div class="row">
                                        <div class="col-lg-12 col-md-12">
                                            <div class="card pull-up">
                                                <div class="card-content">
                                                    <div class="card-body">
                                                        <div class="row">
                                                            <div class="col-6 text-left">
                                                                <h5 class="mb-0">Tension Arterielle</h5>
                                                                <small class="text-light">120/89 mm/hg</small>
                                                            </div>
                                                           
                                                            <div class="col-6 text-left">
                                                                <h5 class="mb-0">Ferritininémie</h5>
                                                                <small class="text-light">280 mg/L</small>
                                                            </div>
                                                            
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div> 
                                        <div class="col-lg-12 col-md-12">
                                            <div class="card pull-up">
                                                <div class="card-content">
                                                    <div class="card-body">
                                                        <div class="row">
                                                          <div class="col-3 text-left">
                                                              <h5 class="mb-0">CPK</h5>
                                                              <small class="text-light">54 mg/L</small>
                                                          </div>
                                                          <div class="col-3 text-left">
                                                              <h5 class="mb-0">TCK</h5>
                                                              <small class="text-light">280 mg/L</small>
                                                          </div>
                                                          <div class="col-3 text-left">
                                                              <h5 class="mb-0">TPINR</h5>
                                                              <small class="text-light">280 mg/L</small>
                                                          </div>
                                                          <div class="col-3 text-left">
                                                              <h5 class="mb-0">BIA</h5>
                                                              <small class="text-light">280 mg/L</small>
                                                          </div>  
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div> 
                                                                    
                                      </div>
                                  </div>
                              </div>
                          </div>
                      </div>
                      <div class="col-6 ">
                          <div class="card">
                              <div class="card-header">
                                  <h4 class="card-title">Suivi sur le plan nutritionnel</h4>
                                  <a class="heading-elements-toggle"><i class="la la-ellipsis font-medium-3"></i></a>
                                  <div class="heading-elements">
                                      <ul class="list-inline mb-0">
                                         
                                          <li><a data-action="reload"><i class="ft-rotate-cw"></i></a></li>
                                          <li><a data-action="expand"><i class="ft-maximize"></i></a></li>
                                      </ul>
                                  </div>
                              </div>
                              <div class="card-content collapse show">
                                  <div class="card-body">
                                      <div class="row">
                                        <div class="col-lg-12 col-md-12">
                                          <h3>Les ions</h3>
                                            <div class="card pull-up">
                                                <div class="card-content">
                                                    <div class="card-body">
                                                        <div class="row">
                                                          <div class="col-3 text-left">
                                                              <h5 class="mb-0">Calcium</h5>
                                                              <small class="text-light">54 mg/L</small>
                                                          </div>
                                                          <div class="col-3 text-left">
                                                              <h5 class="mb-0">Magnésium</h5>
                                                              <small class="text-light">280 mg/L</small>
                                                          </div>
                                                          <div class="col-3 text-left">
                                                              <h5 class="mb-0">Potassium</h5>
                                                              <small class="text-light">280 mg/L</small>
                                                          </div>
                                                          <div class="col-3 text-left">
                                                              <h5 class="mb-0">Sodium</h5>
                                                              <small class="text-light">280 mg/L</small>
                                                          </div>  
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div> 
                                         
                                      </div>
                                  </div>
                              </div>
                          </div>
                      </div>
                    
                  </div>
          </section>
         
      </div>
  </div>
  </div>
</template>

<script>

</script>
