<template>
  <div>
    <vue-element-loading :active="isActive" :is-full-screen="false"/>
    <router-view />
    <AdminSidebar />
    <!-- <AdminHeader/> -->
  </div>
</template>

<script>
// import AdminHeader from '@/components/AdminHeader.vue'
import AdminSidebar from "@/components/AdminSidebar.vue";
import VueElementLoading from 'vue-element-loading'
import { mapState, mapGetters, mapActions } from 'vuex'

export default {
  name: "AdminLayout",
  components: {
    AdminSidebar,
    VueElementLoading
  },
  computed: {
    ...mapState(['isActive'])
  },
};
</script>

<style></style>
