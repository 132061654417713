<template>
	<div class="login-page bg-img">
	  <main id="page-content">
		<div class="container">
		  <div class="row">
			<div class="col-lg-6 offset-lg-3 col-xl-6 offset-xl-3">
				
				<form id="formAuthentication" class="mt-4 login-form" @submit.prevent="login">
					<div class="text-center mt-2">
					  <h2>BIENVENUE <br> AU</h2>
					  <img src="/hospital/app-assets/images/logo/logo_paev.png" width="250" class="img-rounded mt-2" alt="Logo"/>
					  <div class="mt-2">
						<strong>
							Merci de vous identifier!
						</strong>
						<strong class="text-danger fw-bold fs-4">
						  {{ errors }}
						</strong>
					  </div>
					</div>
					<div class="rounded p-3" style="border-radius: 10px;">
						<div class="col-md-12">
							<div class="mb-3">
							<label for="email" class="form-label"> <strong>EMAIL</strong> </label>
							<input type="text" class="form-control" id="email" v-model="state.email"
								name="email-username"
								placeholder="Entrer votre email"
								autofocus
								style="border: none; "
							/>
							<div class="text-danger fw-semibold fs-6 py-1" v-if="v$.email.$error">
								<div v-for="error in v$.email.$errors" :key="error.$uid">
								<span v-if="error.$validator =='required'"> Ce champ est obligatoire </span>
								<span v-if="error.$validator =='email'"> Email invalide </span>
								</div>
							</div>
							</div>
		
							<div class="mb-2">
							<div class="d-flex justify-content-between">
								<label class="form-label" for="password"> <strong>MOT DE PASSE</strong> </label>
								<router-link to="/remember-password" style="text-decoration: underline;">
									<strong style="font-weight: 700; color: #1a73b7;"> Mot de passe oublié ?  </strong>
								</router-link>
							</div>
							<div class="">
								<div class="input-group input-group-merge position-relative">
								<input
									:type="statePassword"
									id="password"
									class="form-control"
									name="password"
									v-model.trim="state.password"
									placeholder="Entrer le mot de passe"
									aria-describedby="password"
									style="border: none; "
								/>
								</div>
								<div class="text-danger fw-semibold fs-6 py-1" v-if="v$.password.$error">
								<div v-for="error in v$.password.$errors" :key="error.$uid">
									<span v-if="error.$validator =='required'"> Ce champ est obligatoire </span>
									<span v-if="error.$validator =='minLength'"> Mot de passe très court (6 caractères) </span>
								</div>
								</div>
							</div>
							</div>
							<button class="btn btn-primary w-100 mt-2" type="submit">
							<span class="" style="font-weight: bold;  font-size: medium;" v-if="!chargement">
								Se connecter
							</span>
							<span v-else class="d-flex justify-content-center align-items-center">
								<span class="mx-2 fs-semibold text-light">
								Connexion en cours ...
								</span>
								<div
								style="width: 1.5rem; height: 1.5rem"
								class="spinner-border text-light"
								role="status"
								>
								<span class="sr-only">Connexion...</span>
								</div>
							</span>
							</button>
						</div>
					</div>
			  </form>
			</div>
		  </div>
		</div>
	  </main>
	</div>
  </template>
  
  
  

<script setup>
import { computed, reactive, ref } from "vue";
import { useRouter, useRoute } from "vue-router";
import { useVuelidate } from "@vuelidate/core";
import {
  required,
  minLength,
  numeric,
  email,
  sameAs,
} from "@vuelidate/validators";
import { createToast } from "mosha-vue-toastify";
import UsersService from "@/services/modules/utilisateur.service.js";
// import the styling for the toast
import "mosha-vue-toastify/dist/style.css";


const router = useRouter();
const route = useRoute();

//datas
const chargement = ref(false);
const errors = ref('');
const statePassword = ref("password");
const show = ref(false);
const state = reactive({
  email: "",
  password: "",
});

//methods

const toast = (message,type) => {
  createToast(message,{type:type})
}

const login = () => {
  v$.value.$validate(); // checks all inputs
  if (!v$.value.$error) {
    if(chargement.value == false) {
      chargement.value = true
		UsersService.login(state).then((data) => {
        const response = data.data
		console.log(response);
            chargement.value = false
            const usersInfo = {
				token: response.data.token,
				users: response.data.user,
				doctor_id: response.data.doctor_id,
            }
            localStorage.setItem('paevcliniqueInfo',JSON.stringify(usersInfo))
            //toast('vous est connecté', 'success')
            router.push("/dashboard");
          }).catch((e) => {
            chargement.value = false  
			toast(e.response.data.message, 'danger')
        })
    }
   
  }
}

const invisible = ()=> {
    statePassword.value = !show.value ? 'text' : 'password'
    show.value = !show.value
  }

  //validations
  const rules = {
    password: {required,minLength: minLength(6)},
    email: {required,email},
  }

  const v$ = useVuelidate(rules, state)
</script>



<style scoped>

input.form-control {
    width: 100%;
    padding: 25px;
    box-sizing: border-box;
}
	.login-form {
	border: 1px solid #ccc;
	border-radius: 10px;
	background-color: rgba(255, 255, 255, 0.5);
	box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
	
  }

  .login-page {
  height: 100vh;
  /* display: flex; */
  justify-content: center;
  align-items: center;
}

	.bg-img {
		position: relative;
		background-image: url('http://app.paevclinique.org/assets/images/overlay.jpg');
		background-size: cover;
		background-position: center;
		min-height: 100vh;
		justify-content: center;
		align-items: center;
		background-color: rgba(0, 0, 0, 0.5); 
	}

	
	html .fixed-navbar {
		padding-top: 0; 
		}
	
</style>