import router from "../../router";

export default function guest ({ next, store,from }){
  const usersInfo = JSON.parse(localStorage.getItem("paevcliniqueInfo"));
  if (usersInfo) {
    return router.push('/dashboard')
  }

  return next()
}


