
import ApiService from '@/services/configs/api.service'

const AnalyseService = {
  get(slug,element) {
    return ApiService.get(`analyses`, slug,element);
  },
 
  create(params) {
    return ApiService.post("analyses", params);
  },

  update(slug, params) {
    return ApiService.update("analyses", slug, params);
  },

  destroy(slug) {
    return ApiService.delete(`analyses/${slug}`);
  },
 

 
};

  export default AnalyseService;
  