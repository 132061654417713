<template>
    <div>
  
  
      <div class="modal animated slideInDown text-left" id="historie" tabindex="-1" role="dialog" aria-labelledby="historie"
        style="display: none" aria-hidden="true">
        <div class="modal-dialog modal-lg" role="document">
          <div class="modal-content">
            <div class="modal-header">
              <h4 class="modal-title" id="historie">
                Informations relatives à cette consultation
              </h4>
              <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                <span aria-hidden="true">×</span>
              </button>
            </div>
            <div class="modal-body">
              <div class="">
                <div class="col-md-8 mx-auto" v-if="infoPatient">
                  <div class="card card-content collapse show">
                    <div class="card-body">
                      <h4 class="card-title">
                        Informations personnelles du patient
                      </h4>
                      <div class="media-body mt-3">
                        <h5 class="media-heading">
                          Identité
                          <span class="float-right">{{ infoPatient.name}}</span>
                        </h5>
  
                        <h5 class="media-heading">
                          Adresse
                          <span class="float-right">{{ infoPatient.address }}</span>
                        </h5>
  
                        <h5 class="media-heading">
                          Téléphone
                          <span class="float-right">{{ infoPatient.phone }}</span>
                        </h5>
  
                        <h5 class="media-heading">
                          Profession
                          <span class="float-right">{{ infoPatient.profession }}</span>
                        </h5>
  
                      </div>
                      <div class="media-body">
                        <h5 class="media-heading">
                          Situation matrimoniale:
                          <span class="float-right content-header-title">{{ infoPatient.marital_status
                          }}</span>
                        </h5>
  
                        <h5 class="media-heading">
                          Nombre d'enfants
                          <span class="badge badge-info float-right">{{ infoPatient.children_count }}</span>
                        </h5>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="row" v-if="detailsHistorie">
                  <h4 class="card-title">
                    Antécédents du patient
                  </h4>
                  <div class="col-md-6">
                    <ul class="list-group list-group-flush">
                      <li class="list-group-item">Médical : {{ detailsHistorie.medical }}</li>
                      <li class="list-group-item">Chirurgical: {{ detailsHistorie.surgical }}</li>
                      <li class="list-group-item">Gynecologique et obstétrical: {{ detailsHistorie.gynecological }}</li>
                      <li class="list-group-item">Nutritionnel: {{ detailsHistorie.nutritional }}</li>
                      <li class="list-group-item">Ascendant : {{ detailsHistorie.ascendant }}</li>
                      <li class="list-group-item">Colateraux: {{ detailsHistorie.colateraux }}</li>
                    </ul>
                  </div>
                  <div class="col-md-6">
                    <ul class="list-group list-group-flush">
                      <li class="list-group-item">Descendant: {{ detailsHistorie.descendant }}</li>
                      <li class="list-group-item">Alergie Medicale: {{ detailsHistorie.drug_allergies }}</li>
                      <li class="list-group-item">Alergie Alimentaire: {{ detailsHistorie.food_allergies }}</li>
                      <li class="list-group-item">Style de vie : {{ detailsHistorie.lifestyle }}</li>
                      <li class="list-group-item">Totems {{ detailsHistorie.totems }}</li>
                    </ul>
                  </div>
                </div>
                <div class="mt-4">
                  <h2 class="card-title">
                    Données de la consultation
                  </h2>
                  <div class="row" v-if="detailsConsultation">
                    <div class="col-md-4">
                      <ul class="list-group list-group-flush">
                        <li class="list-group-item">Motif : {{ detailsConsultation.consultation_reason }}</li>
                      </ul>
                    </div>
                    <div class="col-md-4">
                      <ul class="list-group list-group-flush">
                        <li class="list-group-item">Diagnostic hypothese: {{ detailsConsultation.hypothesis_diagnostic }}
                        </li>
                      </ul>
                    </div>
                    <div class="col-md-4">
                      <ul class="list-group list-group-flush">
                        <li class="list-group-item">Diagnostic retenu: {{ detailsConsultation.diagnostic }}</li>
                      </ul>
                    </div>
                  </div>
  
  
                  <div class="row" v-if="anamnese">
                    <h4 class="card-title mx-3">
                      ANAMNESE
                    </h4>
                    <div class="col-md-6">
                      <ul class="list-group list-group-flush">
                        <li class="list-group-item">Debut : {{ anamnese.anamnesis_beginning }}</li>
                        <li class="list-group-item">Signes associés : {{ anamnese.anamnesis_associated_signs }}</li>
                        <li class="list-group-item">Signes négatifs : {{ anamnese.anamnesis_negative_signs }}</li>
                      </ul>
                    </div>
                    <div class="col-md-6">
                      <ul class="list-group list-group-flush">
                        <li class="list-group-item">Bilan avant admission : {{ anamnese.anamnesis_previous_treatment }}</li>
                        <li class="list-group-item">Traitement avant admission : {{ anamnese.anamnesis_previous_results }}
                        </li>
                        <li class="list-group-item">Evolution : {{ anamnese.anamnesis_evolution }}</li>
                      </ul>
                    </div>
                  </div>
  
                  <div class="row" v-if="detailsAnthro">
                    <h4 class="card-title mx-3">
                      DONNÉES ANTHROPOMETRIQUES
                    </h4>
                    <div class="col-md-6">
                      <ul class="list-group list-group-flush">
                        <li class="list-group-item">Taille(cm) : {{ detailsAnthro.taille }}</li>
                        <li class="list-group-item">Poids(kg) : {{ detailsAnthro.poids }}</li>
                        <li class="list-group-item">IMC(Kg/m²) : {{ detailsAnthro.imc }}</li>
                        <li class="list-group-item">Temperature(°C) : {{ detailsAnthro.temperature }}</li>
                      </ul>
                    </div>
                    <div class="col-md-6">
                      <ul class="list-group list-group-flush">
                        <li class="list-group-item">Pouls(BPM) : {{ detailsAnthro.pouls }}</li>
                        <li class="list-group-item">Etat général : {{ detailsAnthro.etat_general }}</li>
                        <li class="list-group-item">Bras gauche(cmHG) : {{ detailsAnthro.bras_gauche }}</li>
                        <li class="list-group-item">Bras droit(cmHG) : {{ detailsAnthro.bras_droit }}</li>
                      </ul>
                    </div>
                  </div>
  
                  <div class="row" v-if="examens">
                    <h4 class="card-title mx-3">
                      Examens radiologiques
                    </h4>
                    <div class="table-responsive">
                      <table class="table">
                        <thead>
                          <tr>
                            <th>#</th>
                            <th>Nom de l'examen</th>
                            <th>Interpretation</th>
                            <th>Fichier</th>
                          </tr>
                        </thead>
                        <tbody>
                          <tr v-for="(exam, index) in examens" :key=index>
                            <th scope="row"> {{ index + 1 }}</th>
                            <td>{{ exam.name }}</td>
                            <td>{{ exam.interpretation }}</td>
                            <td v-if="exam.fichier">Oui</td>
                            <td v-else>Non</td>
                          </tr>
                        </tbody>
                      </table>
                    </div>
                  </div>
  
                  <div class="row" v-if="analyses">
                    <h4 class="card-title mx-3">
                      Analyses biologiques
                    </h4>
                    <div class="table-responsive">
                      <table class="table">
                        <thead>
                          <tr>
                            <th>#</th>
                            <th>Nom de l'anayse</th>
                            <th>Interpretation</th>
                            <th>Fichier</th>
                          </tr>
                        </thead>
                        <tbody>
                          <tr v-for="(analyse, index) in analyses" :key=index>
                            <th scope="row"> {{ index + 1 }}</th>
                            <td>{{ analyse.name }}</td>
                            <td>{{ analyse.interpretation }}</td>
                            <td v-if="analyse.fichier">Oui</td>
                            <td v-else>Non</td>
                          </tr>
                        </tbody>
                      </table>
                    </div>
                  </div>
  
                </div>
  
              </div>
            </div>
            <div class="modal-footer">
              <button type="button" class="btn grey btn-outline-secondary" data-dismiss="modal">
                Fermer
              </button>
            </div>
          </div>
        </div>
      </div>
  
      <div class="app-content content">
        <div class="content-overlay"></div>
        <div class="content-wrapper">
          <div class="content-header row">
                      <div class="content-header-left col-md-6 col-12 mb-2">
                           
                          <h4 class="card-title text-uppercase" id="from-actions-top-bottom-center">
                              <strong style="text-decoration: underline;">
                                  Dossier Nutritionel
                              </strong> 
                          </h4>
  
                      </div>
                      <div class="content-header-right col-md-6 col-12">
                          <div class="btn-group float-md-right" role="group" aria-label="Button group with nested dropdown">
                              <router-link to="/admin/patient/index"  class="btn btn-info round  box-shadow-2 px-2 mb-1">
                                  <i class="ft-arrow-left  icon-left"></i>
                                  Retour au patient
                              </router-link>
                          </div>
                      </div>
                  </div>
          <div class="content-body">
            <!-- Tabs with Icons start -->
            <section id="tabs-with-icons">
              <div class="row">
                
                              <div class="col-lg-6 col-md-12">
                                  <div class="card" style="height: 160.125px;">
                                      <div class="card-body">
                                          <div class="row">		
                                              <div class="col-lg-12 d-flex justify-content-around" style="overflow: auto; height:9em;">        								
                                                  <div class="media-body"  >
                                                              <h5 class="media-heading">
                                                                  <strong>Nom & prénom : </strong>
                                                                  <span class="float-right content-header-title">
                                                                      <strong class="text-uppercase">{{ patients.name }}</strong> 
                                                                  </span>
                                                              </h5>
                                                              <hr/>
                                                              <h5 class="media-heading">
                                                                  <strong>Sexe : </strong>
                                                                  <span class="float-right content-header-title">{{patients.sex}}</span>
                                                              </h5>
                                                              <hr/>
                                                              <h5 class="media-heading">
                                                                  <strong>AGE:</strong>
                                                                  <span class="float-right content-header-title">{{patients.age}} ans </span>
                                                              </h5>
                                                              <hr />
                                                              <h5 class="media-heading">
                                                                  <strong>Profession:</strong>
                                                                  <span class="float-right content-header-title">{{patients.profession}}</span>
                                                              </h5>
  
                                                              <hr />
                                                              <h5 class="media-heading">
                                                                  <strong>Situation Matrimoniale:</strong>
                                                                  <span class="float-right content-header-title">{{patients.marital_status}}</span>
                                                              </h5>
                                                              
                                                              <hr />
                                                              <h5 class="media-heading">
                                                                  <strong>Télephone:</strong>
                                                                  <span class="float-right content-header-title">{{patients.phone}}</span>
                                                              </h5>
                                                              <hr />
                                                              <h5 class="media-heading">
                                                                  <strong>Email:</strong>
                                                                  <span class="float-right content-header-title">{{patients.email}}</span>
                                                              </h5>
  
                                                              <hr />
                                                              <h5 class="media-heading">
                                                                  <strong>Personne à contacter en cas d'urgence:</strong>
                                                                  <span class="float-right content-header-title"></span>
                                                              </h5>
                                                          </div>
                                                      
                                              </div>
                                          </div>
                                      </div>
                                  </div>
                              </div>
                              <div class="col-lg-3 col-md-6">
                                  <div class="card bg-gradient-y-info" style="height: 160.125px;">
                                      <div class="card-body" style="overflow: auto; height:3em; color:#fff;">
                                          <h5 class="media-heading" style="color:#fff;">
                                              GSRH:
                                              <span class="float-right content-header-title text-white">B+</span>
                                          </h5>
                                          <h5 class="media-heading" style="color:#fff;">
                                              Electrophorèse de l'hb:
                                              <span class="float-right content-header-title text-white">AC</span>
                                          </h5>
                                          <h5 class="media-heading" style="color:#fff;">
                                              G6PD
                                              <span class="float-right content-header-title text-white">Null</span>
                                          </h5>
  
                                          <h5 class="media-heading" style="color:#fff;">
                                              TCK
                                              <span class="float-right content-header-title text-white">Null</span>
                                          </h5>
                                          <h5 class="media-heading" style="color:#fff;">
                                              TPINR
                                              <span class="float-right content-header-title text-white">Null</span>
                                          </h5>
                                          <h5 class="media-heading" style="color:#fff;">
                                              BIA
                                              <span class="float-right content-header-title text-white">Null</span>
                                          </h5>
                                          <h5 class="media-heading" style="color:#fff;">
                                              CPK
                                              <span class="float-right content-header-title text-white">Null</span>
                                          </h5>
                                      </div>
                                  </div>
                              </div>
                              <div class="col-lg-3 col-md-6">
                                  <div class="card bg-gradient-y-warning" style="height: 160.125px;">
                                      <div class="card-header">
                                          <h5 class="card-title text-white">Contrôles récents</h5>
                                      </div>
                                      <div class="card-content mx-2">
                                          <ul class="list-unstyled text-white">
                                              <li></li>
                                          </ul>
                                      </div>
                                  </div>
                              </div>
                          
              </div>
  
              <div class="text-center mt-5" v-if="existConsultation == 0">
                <button class="btn btn-outline-primary" data-toggle="modal" data-target="#bounce" @click="addConsultation"
                  id="btnGroupDrop1" type="button" aria-haspopup="true" aria-expanded="false"><span
                    class="la la-plus font-large-3 p-1"></span></button>
                <h4 class="d-none d-lg-block py-50 text-bold-500">Cliquez pour ajouter une consultation</h4>
              </div>
  
  
              <div class="text-right" v-if="state != 1 && existConsultation != 0">
                <button class="btn btn-outline-primary" data-toggle="modal" data-target="#bounce" @click="storeConsultation()"
                  id="btnGroupDrop1" type="button" aria-haspopup="true" aria-expanded="false">Ajouter une
                  consultation</button>
              </div>
              <div class="row match-height mt-2" v-if="existConsultation != 0">
                <div class="table-responsive">
                  <table class="table">
                    <thead>
                      <tr>
                        <th>#</th>
                        <th>Type de consultation</th>
                        <th>Date</th>
                        <th>Etat</th>
                        <th>Voir</th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr v-for="(listConsultation, index) in listConsultations">
                        <th scope="row"> {{ index + 1 }}</th>
                        <td>{{ listConsultation.consultation_type }}</td>
                        <td>{{ listConsultation.created_at }}</td>
                        <td v-if="listConsultation.state == 'En cours'"><span
                            class="btn btn-warning  round btn-sm waves-effect waves-light">{{ listConsultation.state
                            }}</span></td>
                        <td v-if="listConsultation.state == 'Cloturé'"><span
                            class="btn btn-success  round btn-sm waves-effect waves-light">{{ listConsultation.state
                            }}</span></td>
                        <!-- <td><i data-toggle="modal" @click="getHistorie(listConsultation.id)" data-target="#historie"
                              class="ft-eye"></i></td> -->
                        <td v-if="listConsultation.state == 'En cours'">
                          <router-link title="voir dossier medical"
                            :to="{ name: 'voirDossier', params: { id: listConsultation.id } }"
                            class="btn btn-outline-warning  round btn-sm waves-effect waves-light">
                            <i class="ft-eye"></i>
                          </router-link>
                          <!-- <button @click.prevent="supprimerConsultation(listConsultation.id)" class="btn btn-outline-danger round btn-sm mx-2"> x </button> -->
                        </td>
                        <td v-if="listConsultation.state == 'Cloturé'">
                          <!-- <i data-toggle="modal" @click="getConsultation(listConsultation.id)" data-target="#historie"
                            class="ft-eye btn btn-outline-success cursor-pointer round btn-sm"></i> -->
                          <router-link title="voir dossier medical"
                            :to="{ name: 'dossierMedicalTermine', params: { id: listConsultation.id } }"
                            class="btn btn-outline-warning  round btn-sm waves-effect waves-light">
                            <i class="ft-eye"></i>
                          </router-link>
                          <!-- <button @click.prevent="supprimerConsultation(listConsultation.id)" class="btn btn-outline-danger round btn-sm mx-2"> x </button> -->
                        </td>
                        <!-- <td v-if="listConsultation.state == 'En cours'"><span
                              class="badge badge-warning px-1 cursor-pointer"
                              @click="getConsultation(listConsultation.id)">Dossier médical</span></td>
                          <td v-if="listConsultation.state == 'Cloturé'"><span class="badge badge-success px-1">Dossier
                              médical</span></td> -->
                      </tr>
                    </tbody>
                  </table>
                </div>
              </div>
            </section>
            <!-- Tabs with Icons end -->
          </div>
        </div>
      </div>
    </div>
  </template>
  
  <script setup>
  import { ref, reactive, onMounted } from 'vue'
  import { Patients } from "../../../api/patient"
  import { useRouter, useRoute } from "vue-router"
  import { createToast } from "mosha-vue-toastify";
  import VueMultiselect from 'vue-multiselect'
  import "mosha-vue-toastify/dist/style.css";
  import AnthropoService from "@/services/modules/anthropo.service.js";
  import AnamneseService from "@/services/modules/anamnese.service.js";
  import HistoriquePatientService from "@/services/modules/historique.patient.service.js";
  import PatientService from "@/services/modules/patient.service.js";
  import ConsultationService from "@/services/modules/consultation.service.js";
  
  const router = useRouter()
  const route = useRoute()
  
  const patients = ref([])
  let showDossier = null
  const existConsultation = ref(null)
  const consultationId = ref(null)
  const listConsultations = ref([])
  const infoPatient = ref([])
  const detailsHistorie = ref([])
  const detailsConsultation = ref([])
  const analyses = ref([])
  const examens = ref([])
  const anamnese = ref([])
  const soins = ref([])
  const detailsAnthro = ref([])
  const state = ref(null)
  
  const consultationParams = reactive({
    consultation_type: "Nutrition",
    patient_id: "",
  });
  
  
  
  const historiquesPatiens = ref([])
  
  const getConsultation = (id) => {
    ConsultationService.get(id).then((data) => {
      detailsConsultation.value = data.data.data
      infoPatient.value = data.data.data.patient
      detailsHistorie.value = data.data.data.history
      anamnese.value = data.data.data.anamnesis
      detailsAnthro.value = data.data.data.anthropo_data
      examens.value = data.data.data.exams
      analyses.value = data.data.data.analyses
  
    }).catch((e) => {
      console.log(e)
    })
  }
  
  const getPatient = () => {
    PatientService.get(route.params.id).then((data) => {
      const datas = data.data.data
      existConsultation.value = datas.consultations.length;
      patients.value = datas
      listConsultations.value = datas.consultations
      listConsultations.value.forEach(function (consultation) {
        if (consultation.state === 'En cours') {
          state.value = 1
        }
      });
  
    }).catch((e) => {
      console.log(e)
    })
  }
  
  const toast = (message, type) => {
    createToast(message, { type: type })
  }
  
  const storeConsultation = function () {
    consultationParams.patient_id = route.params.patient_id
    ConsultationService.create(consultationParams).then((response) => {
      if (response.data.status == 'error') {
        toast(response.data.message, 'danger')
      }
      else {
        consultationParams.consultation_type = ""
        getPatient()
        toast("Vous avez ajouté une consultation pour ce patient", 'success')
      }
  
    })
  }
  
  
  const supprimerConsultation = function (id) {
    ConsultationService.destroy(id).then((data) => {
      if (data.data.status == "success") {
        getPatient()
        toast("Suppression de la consultation", "success");
      } else {
        toast(data.data.message, "danger");
      }
    })
  }
  onMounted(() => {
    getPatient()
  })
  
  
  
  //multiselected option
  const taggingSelectedType = ref(null)
  const taggingOptionsType = ref(
    [
      { name: 'Radiographie', code: "ra" },
      { name: 'Scanner', code: 'sc' },
      { name: 'Mammographie', code: 'ma' },
      { name: 'Echographie', code: 'ec' },
      { name: 'IRM', code: 'irm' },
    ]
  )
  const taggingSelectedBio = ref(null)
  const taggingOptionsBio = ref(
    [
      { name: 'Glycémie', code: "gl" },
      { name: ' NFS', code: 'nsf' },
      { name: 'Natrémie', code: 'na' },
      { name: 'Kaliémie', code: 'ka' },
      { name: 'Chlorémie', code: 'ch' },
      { name: 'Calcémie', code: 'ca' },
      { name: 'Magnésémie', code: 'ma' },
      { name: 'Transaminases + TGO +TGT', code: 'tr' },
      { name: 'Amylasémie', code: 'amy' },
      { name: 'Azotémie', code: 'azo' },
      { name: 'Créatininémie', code: 'cre' },
      { name: 'Uricémie', code: 'urc' },
      { name: 'ECG', code: 'ecg' },
      { name: 'TCK', code: 'tck' },
      { name: 'TP / INR', code: 'tp' },
      { name: 'CPK', code: 'cpk' },
      { name: 'Cholestérolémie T', code: 'chot' },
      { name: 'Cholestérolémie HDL', code: 'chohdl' },
      { name: 'Triglycéridémie', code: 'trig' },
      { name: 'PSA totales ', code: 'psat' },
      { name: 'PSA prostatiques/', code: 'psap' },
      { name: 'FPSA', code: 'fpsa' },
      { name: 'Electrophorèse des protéines', code: 'elecP' },
      { name: "Electrophorèse de l'HB", code: 'elech' },
      { name: "Ag HBs", code: 'ags' },
      { name: "Ag HBc", code: 'agc' },
      { name: "G6PD", code: 'g6pd' },
      { name: "Alpha foeto protéines", code: 'afp' },
      { name: "Sérologie Rubéole", code: 'seror' },
      { name: "Sérologie Toxoplasmose ", code: 'serot' },
      { name: "ASLO ", code: 'aslo' },
      { name: "Biluribine D", code: 'bild' },
      { name: "Biluribine I", code: 'bili' },
      { name: "ECBU + Antibiogramme ", code: 'ecbu' },
      { name: "Spermoogramme + Antibiogramme ", code: 'sperAn' },
      { name: "Sérologie HVI", code: 'serohvi' },
      { name: "GSRH", code: 'gsrh' },
    ]
  )
  
  
  
  //get data
  const getData = JSON.parse(localStorage.getItem('patients'))
  if (getData != null || getData != undefined) {
    const datas = getData
    patients.value = [...Patients, ...datas]
  } else {
    patients.value = Patients
  }
  
  patients.value = patients.value.filter(patient => patient.id == route.params.id)
  
  
  
  </script>
  <style src="vue-multiselect/dist/vue-multiselect.css"></style>
  <style scoped>
  .curseur {
    cursor: pointer
  }
  
  .customInput {
    border: none;
    border-bottom: 1px solid black;
    outline: none;
    margin: 2px 5px;
  }
  
  .supprimer {
    color: red;
    font-size: 16px;
    font-weight: bold
  }
  
  .container {
    display: block;
    position: relative;
    padding-left: 35px;
    margin-bottom: 12px;
    cursor: pointer;
    font-size: 15px;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
  }
  
  /* Hide the browser's default checkbox */
  .container input {
    position: absolute;
    opacity: 0;
    cursor: pointer;
    height: 0;
    width: 0;
  }
  
  /* Create a custom checkbox */
  .checkmark {
    position: absolute;
    top: 0;
    left: 0;
    height: 25px;
    width: 25px;
    background-color: #eee;
  }
  
  /* On mouse-over, add a grey background color */
  .container:hover input~.checkmark {
    background-color: #ccc;
  }
  
  /* When the checkbox is checked, add a blue background */
  .container input:checked~.checkmark {
    background-color: #2196F3;
  }
  
  /* Create the checkmark/indicator (hidden when not checked) */
  .checkmark:after {
    content: "";
    position: absolute;
    display: none;
  }
  
  /* Show the checkmark when checked */
  .container input:checked~.checkmark:after {
    display: block;
  }
  
  /* Style the checkmark/indicator */
  .container .checkmark:after {
    left: 9px;
    top: 5px;
    width: 5px;
    height: 10px;
    border: solid white;
    border-width: 0 3px 3px 0;
    -webkit-transform: rotate(45deg);
    -ms-transform: rotate(45deg);
    transform: rotate(45deg);
  }
  </style>
  