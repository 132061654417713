<template>
  <div>
    <!-- Modal -->
    <div class="modal animated bounce text-left" id="bounce" tabindex="-1" role="dialog" aria-labelledby="myModalLabel36"
      aria-hidden="true">
      <div class="modal-dialog" role="document">
        <div class="modal-content">
          <div class="modal-header">
            <h4 class="modal-title text-danger" id="myModalLabel36">
              <span class="alert-icon text-danger"><i class="la la-warning"></i></span>
              Confirmation de la suppression
            </h4>
            <button type="button" class="close" data-dismiss="modal" aria-label="Close">
              <span aria-hidden="true">&times;</span>
            </button>
          </div>
          <div class="modal-body">
            <h5 class="text-danger text-center">Attention! Cette action est irresvocable, êtes vous sur de vouloir
              continuer ?</h5>

            <p>.</p>
          </div>
          <div class="modal-footer">
            <button type="button" class="btn grey btn-outline-primary" data-dismiss="modal">Annuler</button>
            <button type="button" @click.prevent="deletePatient" data-dismiss="modal"
              class="btn btn-outline-danger">Supprimer</button>
          </div>
        </div>
      </div>
    </div>

    <div class="app-content content" style="padding-top: 5rem;">
      <div class="content-overlay"></div>
      <div class="content-wrapper">
        <div class="content-body">
          <!-- List Of All Patients -->
          <section id="patients-list">
            <div class="row">
              <div class="col-12">
                <div class="card">
                  <div class="card-header">
                      <router-link to="/dashboard/patient/add" class="btn btn-info btn-xs" >
                        <i class="la la-plus" style="font-weight: bold;"></i> Nouveau patient
                      </router-link>
                      <div class="d-flex justify-content-end ">
                        <div class="row">
                          <div class="col-lg-12">
                            <input v-model="search" type="search" placeholder="Recherche..." id="form1" class="form-control" />
                          </div>
                        </div>
                      </div>
                  </div>
                  <div class="card-body collapse show">
                    <div class="table-responsive">
                        <table class="table table-bordered table-striped table-hover ">
                          <thead class="bg-info white">
                          <vue-element-loading :active="isActive" :is-full-screen="false" />
                          <tr>
                            <th>#</th>
                            <th>NOM & PRENOMS</th>
                            <th>SEXE</th>
                            <th>DATE D'ARRIVÉE</th>
                            <th>AGE</th>
                            <th>TELEPHONE</th>
                            <th>ACTIONS</th>
                          </tr>
                        </thead>
                        <tbody>
                          <tr v-for="(patient, index) in patients">
                            <td>{{ patient.id }}</td>
                            <td>{{ patient.name }}</td>
                            <td>{{ patient.sex }}</td>
                            <td>{{ patient.date_arrive_fr }}</td>
                            <td>{{ patient.age }} ans </td>
                            <td>{{ patient.phone }}</td>
                            <td>
                              <router-link title="consulter dossier medical"
                                :to="{ name: 'dossierMedical', params: { id: patient.id } }"
                                class="btn btn-primary  round btn-sm waves-effect waves-light">
                                <i class="ft-eye"></i>
                              </router-link>
                              <router-link title="modifer patient" :to="{ name: 'patientEdit', params: { id: patient.id } }"
                                class="btn btn-primary mx-1 round btn-sm waves-effect waves-light">
                                <i class="ft-edit"></i>
                              </router-link>
                              <button data-toggle="modal" data-target="#bounce" title="supprimer patient"
                                @click="supprimer(index, patient)"
                                class="btn btn-danger  round btn-sm waves-effect waves-light">
                                <i class="ft-trash"></i>
                              </button>
                            </td>
                          </tr>
                        </tbody>

                      </table>
                      <nav aria-label="pagination ">
                        <ul class="pagination justify-content-center">
                          <li class="page-item "><span @click="prev()" class="page-link pointer" tabindex="-1"> &#171;
                            </span></li>
                          <li v-for="item in pagination.total" :key="item" :class="{ 'active': item === pagination.current }"
                            class="page-item pointer"><span class="page-link" @click="getData(item)"> {{ item }} </span>
                          </li>

                          <li class="page-item"> <span class="page-link pointer" @click="next()">&#187; </span></li>
                        </ul>
                      </nav>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </section>
        </div>
      </div>
    </div>
  </div>
</template>

<script setup>
import PatientService from "@/services/modules/patient.service.js";
import { createToast } from "mosha-vue-toastify";
import { onMounted, reactive, ref, watch } from 'vue';
// import the styling for the toast
import "mosha-vue-toastify/dist/style.css";
import VueElementLoading from 'vue-element-loading';

const search = ref('')
const isActive = ref(false)
const saveUpdate = reactive({})
const deleteData = reactive({})
const patients = ref([])
const pagination = reactive({
  first: null,
  last: null,
  next: null,
  prev: null,
  current: null,
  total: null
})

let timeout = null;
watch(search, (newValue, oldValue) => {
  if (timeout) {
    clearTimeout(timeout);
  }

  timeout = setTimeout(() => {
    getData(1)
  }, 500);
});

const prev = function () {
  if (pagination.current >= 0) {
    pagination.current = 1
    getData(pagination.current)
  }
  else {
    pagination.current--
    getData(pagination.current)
  }
}
const next = function () {
  pagination.current++
  if (pagination.current <= pagination.total) {
    getData(pagination.current)
  }
}
const active = () => isActive.value = true
const disabled = () => isActive.value = false
const getData = (element = 1) => {
  active()
  PatientService.get(null, `?search=${search.value}&page=${element}`).then((data) => {
    const datas = data.data.data
    pagination.total = Math.ceil(datas.total / datas.per_page)
    pagination.current = datas.current_page
    pagination.last = datas.last_page
    pagination.first = 1

    patients.value = datas.data
    disabled()
  }).catch((e) => {
    disabled()
    console.log(e)
  })
}

onMounted(() => {
  getData()
})

const supprimer = function (index, data) {
  deleteData.id = data.id
  deleteData.nom = data.name
  deleteData.index = index
}
const deletePatient = () => {
  patients.value.splice(patients.value.indexOf(deleteData.index), 1);
  PatientService.destroy(deleteData.id).then((data) => {
    toast('Suppression effectué avec succèss', 'success')
    getData()
  }).catch((error) => {

    if (error.response) {
      // Requête effectuée mais le serveur a répondu par une erreur.
      const erreurs = error.response.data.message
      toast(erreurs, 'danger')
    } else if (error.request) {
      // Demande effectuée mais aucune réponse n'est reçue du serveur.
      //console.log(error.request);
    } else {
      // Une erreur s'est produite lors de la configuration de la demande
    }
  })
}



const toast = (message, type) => {
  createToast(message, { type: type })
}

</script>

<style >.pointer {
  cursor: pointer;
}</style>
