<template>
  <div>
    <div class="app-content content">
      <div class="content-overlay"></div>
      <div class="content-wrapper">
        <div class="content-header row">
          <div class="content-header-left col-md-6 col-12"></div>
          <div class="content-header-right col-md-6 col-12">
            <div
              class="btn-group float-md-right"
              role="group"
              aria-label="Button group with nested "
            >
              <router-link
                to="/admin/patient/index"
                class="btn btn-info round box-shadow-2 px-2 mb-1"
                id="btnGroupDrop1"
                type="button"
                aria-haspopup="true"
                aria-expanded="false"
              >
                <i class="ft-arrow-left"></i> Liste des patients
              </router-link>
            </div>
          </div>
        </div>
       
        <div class="content-body">
          <!-- Add Patient Form Wizard -->
          <section id="add-patient">
            <div class="icon-tabs">
              <div class="row">
                <div class="col-12">
                  <div class="card">
                    <div class="card-header text-center">
                      <h4 class="badge border-info info badge-border">
                        Modifier les informations de {{ patients.name }}
                      </h4>
                      <a class="heading-elements-toggle" href="#">
                        <i class="la la-ellipsis-h font-medium-3"> </i>
                      </a>
                    </div>
                    <div class="card-content collapse show">
                      <div class="card-body">
                        <form
                          @submit.prevent="updatePatient"
                          class="add-patient-tabs steps-validation wizard-notification"
                        >
                          <!-- step 1 => Personal Details -->
                          <h6>
                            <i class="step-icon la la-user font-medium-3"> </i>
                            Details Personnel
                          </h6>
                          <fieldset>
                            <div class="row">
                              <div class="col-md-6">
                                <div class="form-group">
                                  <label for="name">
                                    Nom et prénom (s):
                                   
                                  </label>
                                  <input
                                    class="form-control"
                                    id="name"
                                    name="name"
                                    v-model="patients.name"
                                    type="text"
                                  />
                                </div>
                              </div>
                              <div class="col-md-6">
                                <div class="form-group">
                                  <label for="dateA">
                                    Date d'arrivée
                                   
                                  </label>
                                  <input
                                    class="form-control"
                                    id="dateArrivee"
                                    name="dateArrivee"
                                    v-model="patients.date_arrive"
                                    type="date"
                                  />
                                </div>
                              </div>
                              <div class="col-md-6">
                                <div class="form-group">
                                  <label for="city">
                                    Situation Matrimoniale
                                  </label>
                                  <select
                                    v-model="patients.marital_status"
                                    class="custom-select"
                                    id="city"
                                  >
																		<option disabled :value="patients.marital_status"> {{ patients.marital_status }} </option>
                                    <option value="Mariee">Marié(e)</option>
                                    <option value="célibataire">
                                      célibataire
                                    </option>
                                    <option value="Concubinage">
                                      Concubinage
                                    </option>
                                    <option value="Veuf">Veuf (ve)</option>
                                  </select>
                                </div>
                              </div>
                              <div class="col-md-6">
                                <div class="form-group">
                                  <label for="address"> Adresse: </label>
                                  <input
                                    class="form-control"
                                    id="address"
                                    v-model="patients.address"
                                    type="text"
                                  />
                                </div>
                              </div>
                              <div class="col-md-6">
                                <div class="form-group">
                                  <label for="city"> Sexe  
                                    <!-- <span class="danger"> * </span>  -->
                                  </label>
                                  <select
                                    v-model="patients.sex"
																		required
                                    class="custom-select"
                                    id="city"
                                  >
																	<option disabled :value="patients.sex"> {{ patients.sex }} </option>
                                    <option value="masculin">Masculin</option>
                                    <option value="feminin">Feminin</option>
                                  </select>
                                </div>
                              </div>
                              <div class="col-md-6">
                                <div class="form-group">
                                  <label for="contact"> Profession </label>
                                  <input
                                    class="form-control"
                                    id="profession"
                                    name="profession"
                                    v-model="patients.profession"
                                    type="text"
                                  />
                                </div>
                              </div>
                              <div class="col-md-6">
                                <div class="form-group">
                                  <label for="contact"> Nombre d'enfant </label>
                                  <input
                                    class="form-control"
                                    id="contact"
                                    name="contact"
                                    v-model="patients.children_count"
                                    type="number"
                                  />
                                </div>
                              </div>
                              <div class="col-md-6">
                                <div class="form-group">
                                  <label for="contact">
                                    Numéro de télephone:
                                    <span class="danger"> * </span>
                                  </label>
                                  <input
                                    class="form-control"
                                    id="contact"
                                    name="contact"
                                    type="tel"
																		required
                                    v-model="patients.phone"
                                  />
                                </div>
                              </div>
                              <div class="col-md-6">
                                <div class="form-group">
                                  <label for="dob">
                                    Votre age:
                                   
                                  </label>
                                  <input
                                    class="form-control"
                                    id="dob"
                                    name="dateofbirth"
                                    type="number"
                                    v-model="patients.age"
                                  />
                                </div>
                              </div>
                              <div class="col-md-6">
                                <div class="form-group">
                                  <label for="contact">
                                    Adresse Email:
                                   
                                  </label>
                                  <input
                                    class="form-control"
                                    id="email"
                                    name="email"
                                    type="email"
                                    v-model="patients.email"
                                  />
                                </div>
                              </div>
                              <div class="col-md-6">
                                <div class="form-group">
                                  <label for="dob">
                                    Mot de Passe:
                                   
                                  </label>
                                  <input
                                    class="form-control"
                                    id="pass"
                                    name="password"
                                    type="password"
                                    v-model="patients.password"
                                  />
                                </div>
                              </div>
                            </div>
                          </fieldset>
                          <!-- Step 2 => Emergency Details-->
                          <h6>
                            <i class="step-icon la la-ambulance font-medium-3">
                            </i>
                            Personne à contacter en cas d'urgence
                          </h6>
                          <fieldset v-for="(contact, index) in patients.patient_contacts">
                            <div class="row">
                              <div class="col-md-6">
                                <div class="form-group">
                                  <label for="fnemergency">
                                    Nom et Prénom (s) :
                                   
                                  </label>
                                  <input
                                    class="form-control"
                                    id="fnemergency"
                                    name="fnemergency"
                                    type="text"
                                    v-model="contact.name"
                                  />
                                </div>
                              </div>
                            
                              <div class="col-md-6">
                                <div class="form-group">
                                  <label for="relation">
                                    Relation avec le patient:
                                   
                                  </label>
                                  <input
                                    class="form-control"
                                    id="relation"
                                    name="relation"
                                    v-model="contact.relation"
                                    type="text"
                                  />
                                </div>
                              </div>
                              <div class="col-md-6">
                                <div class="form-group">
                                  <label for="relativeadd"> Adresse: </label>
                                  <input
                                    class="form-control"
                                    id="relativeadd"
                                    name="relativeadd"
                                    v-model="contact.address"
                                    type="text"
                                  />
                                </div>
                              </div>
                            
                              <div class="col-md-6">
                                <div class="form-group">
                                  <label for="cn">
                                    Télephone:
                                   
                                  </label>
                                  <input
                                    class="form-control"
                                    id="cn"
                                    name="cn"
                                    type="tel"
                                    v-model="contact.phone"
                                  />
                                </div>
                              </div>
                              
                            </div>
                          </fieldset>
													<button class="btn btn-primary w-100 flex" type="submit">
														<span class="fs-5 fs-semibold" v-if="!chargement">
															Modifier un patient
														</span>
														<span v-else class="d-flex justify-content-center align-items-center">
															<span class="mx-2 fs-semibold text-light">
																chargement ...
															</span>
															<div
																style="width: 1.5rem; height: 1.5rem"
																class="spinner-border text-light"
																role="status"
															>
																<span class="sr-only">Loading...</span>
															</div>
														</span>
              						</button>
                        </form>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </section>
        </div>
      </div>
    </div>
  </div>
</template>

<script setup>
	import { reactive, ref,onMounted } from "vue";
	import { useRouter, useRoute } from "vue-router";
  import PatientService from "@/services/modules/patient.service.js";
	// import the library
	import { createToast } from "mosha-vue-toastify";
	// import the styling for the toast
	import "mosha-vue-toastify/dist/style.css";

	const router = useRouter();
	const route = useRoute();
  const patients = ref([])
	const getData = () => {
  PatientService.get(route.params.id).then((data) => {
    const datas = data.data.data
    patients.value = datas
  }).catch((e) => {
      console.log(e)
    })
  }

  onMounted(() => {
    getData()
  })
  

//datas
const chargement = ref(false)
//methods
const toast = (message,type) => {
  createToast(message,{type:type})
}

const checkAssurance = reactive(
	{
		yes:true,
		non:false	
	}
)
const checkSymptome = reactive(
	{
		yes:true,
		non:false	
	}
)

const updatePatient = () => {
  
  if(chargement.value == false) {
    chargement.value = true
    const data = {
    "name": patients.value.name,
    "address": patients.value.address,
    // "consultation_type": patients.value.consultation_type,
    "sex": patients.value.sex,
    "phone": patients.value.phone,
    "email": patients.value.email,
    "marital_status": patients.value.marital_status,
    "children_count": patients.value.children_count,
    "age": patients.value.age,
    "profession": patients.value.profession,
    "date_arrive": patients.value.date_arrive,
    "password": patients.value.password,
    "contacts": patients.value.patient_contacts,
    }
		PatientService.update(patients.value.id,data).then((data) => {
      const response = data.data
        chargement.value = false
   
        toast('patient à ete mise à jours', 'success')
        router.go(-1)
      }).catch((e) => {
        chargement.value = false  
				toast(e, 'danger')
    })
  }
}


</script>

<style scoped>
.flex {
	display: flex;
	justify-content: center;
	align-items:center
}
</style>
