
import ApiService from '@/services/configs/api.service'

const QuestionElemService = {
  get(slug,element) {
    return ApiService.get(`questions`, slug,element);
  },
 
  create(params) {
    return ApiService.post("questions", params);
  },

  update(slug, params) {
    return ApiService.update("questions", slug, params);
  },

  destroy(slug) {
    return ApiService.delete(`questions/${slug}`);
  },
 

 
};

  export default QuestionElemService;
  