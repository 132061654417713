
import ApiService from '@/services/configs/api.service'

const QfcService = {
  get(slug,element) {
    return ApiService.get("qfc", slug,element);
  },
 
  create(params) {
    return ApiService.post("qfc", params);
  },

  
  update(slug, params) {
    return ApiService.update("qfc", slug, params);
  },

  destroy(slug) {
    return ApiService.delete(`qfc/${slug}`);
  },
 

 
};

  export default QfcService;
  