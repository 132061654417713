<template>
    <div>
        <div class="app-content content">
            <div class="content-overlay"></div>
            <div class="content-wrapper">

                <div class="content-header row">
					<div class="content-header-left col-md-6 col-12 mb-2">
						 
						<h4 class="card-title" id="from-actions-top-bottom-center">
							<strong style="text-decoration: underline;">
								 Dossier Médical 
							</strong> 
								&nbsp; &nbsp; &nbsp;
							<strong > Patient(e) : </strong> 
						</h4>

					</div>
					<div class="content-header-right col-md-6 col-12">
						<div class="btn-group float-md-right" role="group" aria-label="Button group with nested dropdown">
							<button class="btn btn-info round  box-shadow-2 px-2 mb-1" aria-expanded="false">
								<i class="ft-arrow-left  icon-left"></i> 
								Retour au patient
							</button>
							
						</div>
					</div>
				</div>

                <!-- <div class="content-header row">
                    <div class="content-header-left col-md-6 col-12 mb-2">
                        <h3 class="content-header-title">
                            Dossier Médical : {{ infoPatient.id }}
                        </h3>

                    </div>
                </div> -->

                <div class="content-body">
                    <!-- Tabs with Icons start -->
                    <section id="tabs-with-icons">
                        <div class="row">
                            <div class="col-lg-12" v-if="infoPatient">
                                <div class="card collapse show">
                                    <div class="row">
                                        <div class="col-6">
                                            <div class="card-body">
                                                <div class="media-list media-bordered">
                                                    <div class="media">
                                                        <span class="media-left">
                                                            <img class="media-object"
                                                                src="/hospital/app-assets/images/portrait/small/avatar-s-19.png"
                                                                alt="Generic placeholder image" height="64" width="64" />
                                                        </span>
                                                        <div class="media-body">
                                                            <h5 class="media-heading">
                                                                No Dossier :
                                                                <span class="badge badge-info float-right">{{ infoPatient.id
                                                                }}</span>
                                                            </h5>
                                                            <hr />
                                                            <h5 class="media-heading">
                                                                Profession:
                                                                <span class="float-right content-header-title">{{
                                                                    infoPatient.profession }}</span>
                                                            </h5>
                                                            <hr />
                                                            <h5 class="media-heading">
                                                                Sexe
                                                                <span class="badge badge-info float-right">{{
                                                                    infoPatient.sex }}</span>
                                                            </h5>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div class="col-6">
                                            <div class="card-body">
                                                <div class="media-list media-bordered">
                                                    <div class="media">
                                                        <div class="media-body">
                                                            <h5 class="media-heading">
                                                                Type de Consultation:
                                                                <span class="badge badge-info float-right">{{
                                                                    infoPatient.consultation_type }}</span>
                                                            </h5>
                                                            <hr />
                                                            <h5 class="media-heading">
                                                                Télephone
                                                                <span class="badge badge-info float-right">{{
                                                                    infoPatient.phone }}</span>
                                                            </h5>
                                                            <hr />
                                                            <h5 class="media-heading">
                                                                Date Arrivée :
                                                                <span class="badge badge-info float-right">{{
                                                                    infoPatient.date_arrive }}</span>
                                                            </h5>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="col-xl-10 col-lg-10 offset-xl-1 offset-lg-1 mt-5">
                            <div class="card">
                                <div class="card-header">
                                    <h4 class="card-title" id="from-actions-top-bottom-center">
                                        Fiche de consultation
                                    </h4>
                                    <a class="heading-elements-toggle"><i class="la la-ellipsis-v font-medium-3"></i></a>
                                    <div class="heading-elements">
                                        <ul class="list-inline mb-0">
                                            <li>
                                                <a data-action="close"><i class="ft-x"></i></a>
                                            </li>
                                        </ul>
                                    </div>
                                </div>
                                <div class="card-content">
                                    <div class="card-body">
                                        <ul class="nav nav-tabs nav-underline nav-justified">
                                            <li class="nav-item">
                                                <a class="nav-link active" id="activeIcon12-tab1" data-toggle="tab"
                                                    href="#activeIcon12" aria-controls="activeIcon12"
                                                    aria-expanded="true"><i class="ft-heart"></i>
                                                    <strong class="text-info">
                                                        Historique de consultation
                                                    </strong></a>
                                            </li>
                                        </ul>
                                        <div class="tab-content px-1 pt-1">
                                            <!-- Histrorique Médical -->
                                            <div role="tabpanel" class="tab-pane active" id="activeIcon12"
                                                aria-labelledby="activeIcon12-tab1" aria-expanded="true">
                                                <h4 class="card-title" id="from-actions-top-bottom-center">
                                                    <strong class="text-info">HISTORIQUE DE CONSULTATION</strong>
                                                </h4>
                                                <div class="card" v-if="detailsConsultation.consultation_reason">
                                                    <div class="card-header">
                                                        <h4 class="card-title">
                                                            <strong class="text-info">
                                                                MOTIF DE CONSULTATION
                                                            </strong>
                                                        </h4>
                                                    </div>
                                                    <div class="card-content">
                                                        <div class="card-body">
                                                            <p>
                                                                {{ detailsConsultation.consultation_reason }}
                                                            </p>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div class="card" v-if="detailsConsultation.hypothesis_diagnostic">
                                                    <div class="card-header">
                                                        <h4 class="card-title">
                                                            <strong class="text-info">
                                                                DIAGNOSTIC HYPOTHESE
                                                            </strong>
                                                        </h4>
                                                    </div>
                                                    <div class="card-content">
                                                        <div class="card-body">
                                                            <p>
                                                                {{ detailsConsultation.hypothesis_diagnostic }}
                                                            </p>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div class="card" v-if="detailsConsultation.diagnostic">
                                                    <div class="card-header">
                                                        <h4 class="card-title">
                                                            <strong class="text-info">
                                                                DIAGNOSTIC RETENU:
                                                            </strong>
                                                        </h4>
                                                    </div>
                                                    <div class="card-content">
                                                        <div class="card-body">
                                                            <p>
                                                                {{ detailsConsultation.diagnostic }}
                                                            </p>
                                                        </div>
                                                    </div>
                                                </div>

                                                <div class="card" v-if="anamnese">
                                                    <div class="card-header">
                                                        <h4 class="card-title">
                                                            <strong class="text-info"> ANAMNESE </strong>
                                                        </h4>
                                                    </div>
                                                    <div class="card-content">
                                                        <div class="card-body">
                                                            <p>
                                                                <strong>Debut</strong> :<span class="float-right">{{
                                                                    anamnese.anamnesis_beginning }}</span>
                                                            </p>
                                                            <hr />
                                                            <p>
                                                                <strong>Signe associé</strong> :<span class="float-right">{{
                                                                    anamnese.anamnesis_associated_signs }}</span>
                                                            </p>
                                                            <hr />
                                                            <p>
                                                                <strong>Signe negatif</strong> :<span class="float-right">{{
                                                                    anamnese.anamnesis_negative_signs }}</span>
                                                            </p>
                                                            <hr />
                                                            <p>
                                                                <strong>Traitement avant admission</strong> :<span
                                                                    class="float-right">{{
                                                                        anamnese.anamnesis_previous_treatment }}</span>
                                                            </p>
                                                            <hr />
                                                            <p>
                                                                <strong>Bilan avant admission</strong> :<span
                                                                    class="float-right">{{
                                                                        anamnese.anamnesis_previous_results }}</span>
                                                            </p>
                                                            <hr />
                                                            <p>
                                                                <strong>Evolution</strong> :<span class="float-right">{{
                                                                    anamnese.anamnesis_evolution }}
                                                                </span>
                                                            </p>

                                                        </div>
                                                    </div>
                                                </div>

                                                <div class="card" v-if="detailsAnthro">
                                                    <div class="card-header">
                                                        <h4 class="card-title">
                                                            <strong class="text-info">
                                                                DONNEES ANTHROPOMETRIQUE
                                                            </strong>
                                                        </h4>
                                                    </div>
                                                    <div class="card-content">
                                                        <div class="card-body">
                                                            <p>
                                                                <strong>Température</strong> :<span class="float-right">
                                                                    {{ detailsAnthro.temperature }}degré </span>
                                                            </p>
                                                            <hr />
                                                            <p>
                                                                <strong>Poids</strong> :<span class="float-right">{{
                                                                    detailsAnthro.poids }}kg</span>
                                                            </p>
                                                            <hr />
                                                            <p>
                                                                <strong>Taille</strong> :<span class="float-right">{{
                                                                    detailsAnthro.taille }}cm</span>
                                                            </p>
                                                            <hr />
                                                            <p>
                                                                <strong>Pouls</strong> :<span class="float-right">{{
                                                                    detailsAnthro.pouls }} BPM</span>
                                                            </p>
                                                            <hr />
                                                            <p>
                                                                <strong>IMC</strong> :<span class="float-right">{{
                                                                    detailsAnthro.imc }}</span>
                                                            </p>
                                                            <hr />
                                                            <p>
                                                                <strong>Bras gauche</strong> :<span class="float-right">{{
                                                                    detailsAnthro.brasGauche }}cmHG</span>
                                                            </p>
                                                            <hr />
                                                            <p>
                                                                <strong>Bras droit</strong> :<span class="float-right">{{
                                                                    detailsAnthro.brasDroit }}cmHG</span>
                                                            </p>
                                                            <hr />
                                                        </div>
                                                    </div>
                                                </div>

                                                <div class="card" v-if="analyses">
                                                    <div class="card-header">
                                                        <h4 class="card-title">
                                                            <strong class="text-info">
                                                                ANALYSES BIOLOGIQUES</strong>
                                                        </h4>
                                                        <!-- <button class="btn btn-info btn-sm float-right">
                                                            <i class="ft-plus white"></i> Imprimer
                                                        </button> -->
                                                    </div>

                                                    <div class="card-content">
                                                        <div class="card-body">
                                                            <table class="table table-bordered">
                                                                <thead class="bg-info white">
                                                                    <tr>
                                                                        <th>Analyse</th>
                                                                        <th>Interpretation</th>
                                                                        <th>Fichier</th>
                                                                    </tr>
                                                                </thead>
                                                                <tbody>
                                                                    <tr v-for="(analyse, index) in analyses" :key=index>
                                                                        <td>{{ analyse.name }}</td>
                                                                        <td>{{ analyse.interpretation }}</td>
                                                                        <td v-if="analyse.fichier"><i class="ft-eye"></i>
                                                                        </td>
                                                                        <td v-else>Aucun</td>
                                                                    </tr>

                                                                </tbody>
                                                            </table>
                                                        </div>
                                                    </div>
                                                </div>

                                                <div class="card" v-if="examens">
                                                    <div class="card-header">
                                                        <h4 class="card-title">
                                                            <strong class="text-info"> EXAMENS RADIOLOGIQUES</strong>
                                                        </h4>
                                                    </div>
                                                    <div class="card-content">
                                                        <div class="card-body">
                                                            <table class="table table-bordered">
                                                                <thead class="bg-info white">
                                                                    <tr>
                                                                        <th>Examen</th>
                                                                        <th>Interpretation</th>
                                                                        <th>Fichier</th>
                                                                    </tr>
                                                                </thead>
                                                                <tbody>
                                                                    <tr v-for="(examen, index) in examens " :key="index">
                                                                        <td>{{ examen.name }}</td>
                                                                        <td>{{ examen.interpretation }}</td>
                                                                        <td v-if="examen.fichier"><i class="ft-eye"></i></td>
                                                                        <td v-else>Aucun</td>
                                                                    </tr>

                                                                </tbody>
                                                            </table>
                                                        </div>
                                                    </div>
                                                </div>

                                                <div class="card" v-if="soins">
                                                    <div class="card-header">
                                                        <h4 class="card-title">
                                                            <strong class="text-info">
                                                                PROTOCOLES DE SOINS
                                                                <strong class="text-dark mx-3">{{ soins.protocole_date }} à {{ soins.heure }}</strong>
                                                            </strong>
                                                        </h4>
                                                    </div>

                                                    <h5 class="">
                                                        <strong class="text-center">
                                                            ADMISSIONS
                                                        </strong>
                                                    </h5>
                                                    <div class="card-body">
                                                        <table class="table table-bordered">
                                                            <thead class="bg-info white">
                                                                <tr>
                                                                    <th>No</th>
                                                                    <th>Soin</th>
                                                                    <th>Description</th>
                                                                </tr>
                                                            </thead>
                                                            <tbody>
                                                                <tr v-for="(admission, index) in admissions" :key=index>
                                                                    <td> {{ index + 1 }} </td>
                                                                    <td> {{ admission.soin }} </td>
                                                                    <td>{{ admission.description }}</td>
                                                                </tr>
                                                            </tbody>
                                                        </table>
                                                    </div>

                                                    <h5 class="">
                                                        <strong class="text-center">
                                                            CONSTANTES
                                                        </strong>
                                                    </h5>
                                                    <div class="card-body">
                                                        <table class="table table-bordered">
                                                            <thead class="bg-info white">
                                                                <tr>
                                                                    <th>Température</th>
                                                                    <th>Tension</th>
                                                                    <th>Pouls</th>
                                                                </tr>
                                                            </thead>
                                                            <tbody>
                                                                <tr>
                                                                    <td v-if="soins.temperature == 1">OUI</td>
                                                                    <td v-else>NON</td>
                                                                    <td v-if="soins.tension == 1">OUI</td>
                                                                    <td v-else>NON</td>
                                                                    <td v-if="soins.pouls == 1">OUI</td>
                                                                    <td v-else>NON</td>
                                                                </tr>
                                                            </tbody>
                                                        </table>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </section>
                    <!-- Tabs with Icons end -->
                </div>
            </div>
        </div>
    </div>
</template>

<script setup>
import { ref, reactive, onMounted } from 'vue'
import { Patients } from "../../../api/patient"
import { useRouter, useRoute } from "vue-router"
import { createToast } from "mosha-vue-toastify";
import VueMultiselect from 'vue-multiselect'
import "mosha-vue-toastify/dist/style.css";
import ConsultationService from "@/services/modules/consultation.service.js";

const router = useRouter()
const route = useRoute()

const infoPatient = ref([])
const detailsHistorie = ref([])
const detailsConsultation = ref([])
const analyses = ref([])
const examens = ref([])
const anamnese = ref([])
const soins = ref([])
const detailsAnthro = ref([])
const admissions = ref([])
const state = ref(null)


const getConsultation = (id) => {
    ConsultationService.get(route.params.id).then((data) => {
        detailsConsultation.value = data.data.data
        infoPatient.value = data.data.data.patient
        detailsHistorie.value = data.data.data.history
        anamnese.value = data.data.data.anamnesis
        detailsAnthro.value = data.data.data.anthropo_data
        examens.value = data.data.data.exams
        analyses.value = data.data.data.analyses
        soins.value = data.data.data.protocole
        admissions.value = data.data.data.protocole.admissions

    }).catch((e) => {
        console.log(e)
    })
}

onMounted(() => {
    getConsultation()
})
</script>
<style src="vue-multiselect/dist/vue-multiselect.css"></style>
<style scoped>
.curseur {
    cursor: pointer
}

.customInput {
    border: none;
    border-bottom: 1px solid black;
    outline: none;
    margin: 2px 5px;
}

.supprimer {
    color: red;
    font-size: 16px;
    font-weight: bold
}

.container {
    display: block;
    position: relative;
    padding-left: 35px;
    margin-bottom: 12px;
    cursor: pointer;
    font-size: 15px;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
}

/* Hide the browser's default checkbox */
.container input {
    position: absolute;
    opacity: 0;
    cursor: pointer;
    height: 0;
    width: 0;
}

/* Create a custom checkbox */
.checkmark {
    position: absolute;
    top: 0;
    left: 0;
    height: 25px;
    width: 25px;
    background-color: #eee;
}

/* On mouse-over, add a grey background color */
.container:hover input~.checkmark {
    background-color: #ccc;
}

/* When the checkbox is checked, add a blue background */
.container input:checked~.checkmark {
    background-color: #2196F3;
}

/* Create the checkmark/indicator (hidden when not checked) */
.checkmark:after {
    content: "";
    position: absolute;
    display: none;
}

/* Show the checkmark when checked */
.container input:checked~.checkmark:after {
    display: block;
}

/* Style the checkmark/indicator */
.container .checkmark:after {
    left: 9px;
    top: 5px;
    width: 5px;
    height: 10px;
    border: solid white;
    border-width: 0 3px 3px 0;
    -webkit-transform: rotate(45deg);
    -ms-transform: rotate(45deg);
    transform: rotate(45deg);
}
</style>
