<template>
  <div>
    <div class="modal animated slideInDown text-left" id="historie" tabindex="-1" role="dialog" aria-labelledby="historie"
      style="display: none" aria-hidden="true">
      <div class="modal-dialog" role="document">
        <div class="modal-content">
          <div class="modal-header">
            <h4 class="modal-title" id="historie">
              Details historiques
            </h4>
            <button type="button" class="close" data-dismiss="modal" aria-label="Close">
              <span aria-hidden="true">×</span>
            </button>
          </div>
          <div class="modal-body">
            <ul v-if="detailsHistorie != null || detailsHistorie != undefined" class="list-group list-group-flush">

              <li class="list-group-item">Ascendant : {{ detailsHistorie.ascendant }}</li>
              <li class="list-group-item">Colateraux: {{ detailsHistorie.colateraux }}</li>
              <li class="list-group-item">Descendant: {{ detailsHistorie.descendant }}</li>
              <li class="list-group-item">Alergie Medicale: {{ detailsHistorie.drug_allergies }}</li>
              <li class="list-group-item">Style de vie : {{ detailsHistorie.lifestyle }}</li>
              <li class="list-group-item">Nutritionnel {{ detailsHistorie.nutritional }}</li>
              <li class="list-group-item">Churigical {{ detailsHistorie.surgical }}</li>
              <li class="list-group-item">Totems {{ detailsHistorie.totems }}</li>

            </ul>
          </div>
          <div class="modal-footer">
            <button type="button" class="btn grey btn-outline-secondary" data-dismiss="modal">
              Fermer
            </button>

          </div>
        </div>
      </div>
    </div>

    <div class="modal animated slideInDown text-left" id="slideInDown" tabindex="-1"
      role="dialog" aria-labelledby="myModalLabel77" style="display: none"
      aria-hidden="true">
      <div class="modal-dialog" role="document">
        <div class="modal-content">
          <div class="modal-header">
            <h4 class="modal-title" id="myModalLabel77">
              Confimation de fin de
              consultation
            </h4>
            <button type="button" class="close" data-dismiss="modal" aria-label="Close">
              <span aria-hidden="true">×</span>
            </button>
          </div>
          <div class="modal-body">
            <h5></h5>

            <div class="input-group">
              <div class="d-inline-block custom-control custom-checkbox mr-1">
                <input type="checkbox" name="customer1" class="custom-control-input"
                  checked="" id="yes" />
                <label class="custom-control-label mr-2" for="yes">
                </label>

                Je confirme avoir pris le
                patient
                <strong>
                  {{infoPatient.name}}</strong>
                en charge et j'aimerais
                déclaré cette consultation
                comme terminée.
              </div>
            </div>
          </div>
          <div class="modal-footer">
            <button type="button" class="btn grey btn-outline-secondary"
              data-dismiss="modal">
              Fermer
            </button>
            <button type="button" data-dismiss="modal" class="btn btn-outline-success" @click.prevent="finishConsultation">
              Valider
            </button>
          </div>
        </div>
      </div>
    </div>
    <div class="app-content content">
      <div class="content-overlay"></div>
      <div class="content-wrapper">
        <div class="content-header row">
          <div class="content-header-left col-md-6 col-12 mb-2">
            <h3 class="content-header-title">
              Dossier Médical : {{ infoPatient.id }}
            </h3>

          </div>
          <div class="content-header-right col-md-6 col-12">

            <div class="btn-group float-md-right ml-1">
              <button type="button" class="btn mb-1 btn-danger btn-icon btn-block round"
                data-toggle="modal" data-target="#slideInDown">
                <i class="la la-trash"></i>
                Terminer la consultation
              </button>

            </div>
            <div class="btn-group float-md-right" role="group" aria-label="Button group with nested dropdown">
              <button class="btn btn-info round dropdown-toggle dropdown-menu-right box-shadow-2 px-2 mb-1"
                id="btnGroupDrop1" type="button" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                <i class="ft-edit icon-left"></i> Modifier
              </button>
              <div class="dropdown-menu" aria-labelledby="btnGroupDrop1">
                <a class="dropdown-item" href="#">Profile de {{ infoPatient.name }}
                </a>
                <a class="dropdown-item" href="#">Consultation en cours</a>
                <a class="dropdown-item" href="#">Consultations antérieures</a>
                <a class="dropdown-item" href="#">Historique Médical</a>
              </div>
            </div>
          </div>
        </div>

        <div class="content-body">
          <!-- Tabs with Icons start -->
          <section id="tabs-with-icons">
            <div class="row">
              <div class="col-lg-12">
                <div class="card collapse show">
                  <div class="row">
                    <div class="col-6">
                      <div class="card-body">
                        <div class="media-list media-bordered">
                          <div class="media">
                            <span class="media-left">
                              <img class="media-object" src="/hospital/app-assets/images/portrait/small/avatar-s-19.png"
                                alt="Generic placeholder image" height="64" width="64" />
                            </span>
                            <div class="media-body">
                              <h5 class="media-heading">
                                No Dossier :
                                <span class="badge badge-info float-right">{{ infoPatient.id }}</span>
                              </h5>
                              <hr />
                              <h5 class="media-heading">
                                Profession:
                                <span class="float-right content-header-title">{{ infoPatient.profession }}</span>
                              </h5>
                              <hr />
                              <h5 class="media-heading">
                                Sexe
                                <span class="badge badge-info float-right">{{ infoPatient.sex }}</span>
                              </h5>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div class="col-6">
                      <div class="card-body">
                        <div class="media-list media-bordered">
                          <div class="media">
                            <div class="media-body">
                              <h5 class="media-heading">
                                Situation Matrimoniale:
                                <span class="badge badge-info float-right">{{ infoPatient.marital_status }}</span>
                              </h5>
                              <hr />
                              <h5 class="media-heading">
                                Télephone
                                <span class="badge badge-info float-right">{{ infoPatient.phone }}</span>
                              </h5>
                              <hr />
                              <h5 class="media-heading">
                                Date Arrivée :
                                <span class="badge badge-info float-right">{{ infoPatient.date_arrive_fr }}</span>
                              </h5>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div class="col-xl-10 col-lg-10 offset-xl-1 offset-lg-1 mt-5">
              <div class="card">
                <div class="card-header">
                  <h4 class="card-title" id="from-actions-top-bottom-center">
                    Fiche de consultation
                  </h4>
                  <a class="heading-elements-toggle"><i class="la la-ellipsis-v font-medium-3"></i></a>
                  <div class="heading-elements">
                    <ul class="list-inline mb-0">
                      <li>
                        <a data-action="collapse"><i class="ft-minus"></i></a>
                      </li>
                      <li>
                        <a data-action="reload"><i class="ft-rotate-cw"></i></a>
                      </li>
                      <li>
                        <a data-action="expand"><i class="ft-maximize"></i></a>
                      </li>
                    </ul>
                  </div>
                </div>
                <div class="card-content">
                  <div class="card-body">
                    <ul class="nav nav-tabs nav-underline nav-justified">
                      <li class="nav-item">
                        <a class="nav-link active" id="linkIcon13-tab1" data-toggle="tab" href="#linkIcon13"
                          aria-controls="linkIcon13" aria-expanded="false"><i class="ft-link"></i>
                          <strong class="text-info">Profil</strong></a>
                      </li>
                      <li class="nav-item">
                        <a class="nav-link" id="linkIconOpt11-tab1" data-toggle="tab" href="#linkIconOpt11"
                          aria-controls="linkIconOpt11"><i class="ft-external-link"></i>
                          <strong class="text-info"> Antécédents </strong></a>
                      </li>
                      <li class="nav-item">
                        <a class="nav-link" id="linkIconOpt11-tab1" data-toggle="tab" href="#tab-conseil"
                          aria-controls="linkIconOpt11"><i class="ft-external-link"></i>
                          <strong class="text-info"> Conseil </strong></a>
                      </li>

                      <li class="nav-item">
                        <a class="nav-link" id="linkIcon12-tab1" data-toggle="tab" href="#linkIcon12"
                          aria-controls="linkIcon12" aria-expanded="false"><i class="ft-link"></i>
                          <strong class="text-info">
                            Consultation en cours
                          </strong></a>
                      </li>
                    </ul>
                    <div class="tab-content px-1 pt-1">
                      <!-- <ProfilePatient/> -->
                      <div class="tab-pane active" id="linkIcon13" role="tabpanel" aria-labelledby="linkIcon13-tab1"
                        aria-expanded="false">
                        <div class="row">
                          <div class="col-lg-12 col-md-12">
                            <div class="card" style="height: 321.7px">
                              <div class="card card-content collapse show">
                                <div class="card-body">
                                  <h4 class="card-title">
                                    Informations personnelles et familliales
                                  </h4>
                                  <div class="media-body mt-3">

                                    <h5 class="media-heading">
                                      Identité
                                      <span class="float-right">{{ infoPatient.name }}</span>
                                    </h5>
                                    <hr />
                                    <h5 class="media-heading">
                                      Adresse
                                      <span class="float-right">{{ infoPatient.address }}</span>
                                    </h5>
                                    <hr />
                                    <h5 class="media-heading">
                                      Téléphone
                                      <span class="float-right">{{ infoPatient.phone }}</span>
                                    </h5>
                                    <hr />

                                    <!-- <h5 class="media-heading">
                                        Lieu et date de naissance :
                                        <span class="badge badge-info float-right">20-03-1986 à cotonou</span>
                                      </h5> -->
                                    <!-- <h5 class="media-heading">
                                        Nationalité
                                        <span class="badge badge-info float-right">Béninoise</span>
                                      </h5> -->
                                    <h5 class="media-heading">
                                      Profession
                                      <span class="float-right">{{ infoPatient.profession }}</span>
                                    </h5>
                                    <hr />
                                  </div>
                                  <div class="media-body">
                                    <h5 class="media-heading">
                                      Situation matrimoniale:
                                      <span class="float-right content-header-title">{{ infoPatient.marital_status
                                      }}</span>
                                    </h5>
                                    <hr />
                                    <h5 class="media-heading">
                                      Nombre d'enfants
                                      <span class="badge badge-info float-right">{{ infoPatient.children_count }}</span>
                                    </h5>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                      
                      <!-- <Conseil/> -->
                      <div class="tab-pane" id="tab-conseil" role="tabpanel" aria-labelledby="linkIcon13-tab1"
                        aria-expanded="false">
                        <div class="row">
                          <div class="col-lg-12 col-md-12">
                            <div class="card" style="height: 321.7px">
                              <div class="card card-content collapse show">
                                <div class="card-body">
                                  <h3 class="card-title">
                                    Conseils et Recommandations
                                    <button type="button" class="btn mb-1 btn-primary btn-icon round float-md-right"
                                      data-toggle="modal" data-target="#modal-form-conseil">
                                      <i class="la la-plus"></i>
                                      Nouveau
                                    </button>
                                  </h3>

                                        <div class="modal animated bounce text-left" id="modal-form-conseil" tabindex="-1" role="dialog" aria-labelledby="myModalLabel36" aria-hidden="true">
                                          <div class="modal-dialog modal-lg" role="document">
                                            <div class="modal-content">
                                              <div class="modal-header">
                                                <h4 class="modal-title " id="myModalLabel36">
                                                  Ajout d'un conseil
                                                </h4>
                                                <button type="button" class="close" data-dismiss="modal"
                                                  aria-label="Close">
                                                  <span aria-hidden="true">&times;</span>
                                                </button>
                                              </div>
                                              <form class="modal-body">
                                                <div class="form-body">
                                                  <div class="row my-2">
                                                    <div class="col-md-12">
                                                      <div class="form-group mb-2">
                                                        <label for="userinput1">Conseil </label>
                                                        <textarea name="" v-model="current_conseil.comment"
                                                          placeholder="Entrer un conseil"
                                                          class="form-control round border-primary" id="" cols="30"
                                                          rows="2"></textarea>
                                                      </div>
                                                    </div>

                                                  </div>

                                                  <button type="submit" class="btn btn-primary w-100"
                                                    @click.prevent="saveConseil" data-dismiss="modal" aria-label="Close"> <i
                                                      class="la la-check-square-o"></i>Enregistrer
                                                  </button>

                                                </div>
                                              </form>
                                            </div>
                                          </div>
                                        </div>


                                        <div class="col-12 ">
                                            <div class="card">
                                                <div class="card-content collapse show">
                                                    <div class="card-body">
                                                        <div class="row">
                                                            <div class="col-6" v-for="(conseil, index) in list_conseils">
                                                                <div class="bs-callout-info callout-border-left p-1">
                                                                    <strong>Bonjour {{ infoPatient.name }}!</strong>
                                                                    <p>{{ conseil.comment }}
                                                                    </p>
                                                                    <strong class="float-right">Par Dr {{ conseil.doctor.name }}
                                                                        {{ conseil.created_at_fr }}</strong>

                                                                        <button @click="editConseil(conseil.id, conseil.comment)" type="button" class="btn mb-1 btn-primary btn-icon round float-md-right" data-toggle="modal" data-target="#modal-form-conseil" >
                                                                          <i class="la la-edit"></i> Modifier
                                                                        </button>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>

                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                      <!-- Antécédents -->
                      <div class="tab-pane" id="linkIconOpt11" role="tabpanel" aria-labelledby="linkIconOpt11-tab1"
                        aria-expanded="false">
                        <div class="row">
                          <div class="col-12">
                            <div class="card">
                              <div class="card-body">
                                <form @submit.prevent="saveHistorique" class="form">
                                  <div class="form-body">
                                    <div class="row">
                                      <div class="col-12">
                                        <h2 class="card-title text-center">
                                          I- ANTÉCÉDANTS PERSONNELS
                                        </h2>

                                        <h4 class="card-title" id="from-actions-top-bottom-center">
                                          I-1 Antécédants médicaux
                                        </h4>
                                        <div class="form-group">
                                          <div class="position-relative has-icon-left">

                                            <textarea id="timesheetinput7" rows="3"
                                              v-model="formData.antecedantPersonnel.medicaux"
                                              class="form-control round border-primary" name="antecedantsmedicaux"
                                              placeholder=""></textarea>
                                            <div class="form-control-position">
                                              <i class="ft-file"></i>
                                            </div>
                                          </div>
                                        </div>


                                        <h4 class="card-title" id="from-actions-top-bottom-center">
                                          I-2 Antécédants chirurgicaux
                                        </h4>
                                        <div class="form-body">
                                          <div class="row">
                                            <div class="col-12">
                                              <div class="form-group">
                                                <div class="position-relative has-icon-left">
                                                  <textarea id="timesheetinput7" rows="3"
                                                    v-model="formData.antecedantPersonnel.chirurgicaux"
                                                    class="form-control round border-primary"
                                                    name="antecedantschirurgicaux" placeholder=""></textarea>
                                                  <div class="form-control-position">
                                                    <i class="ft-file"></i>
                                                  </div>
                                                </div>
                                              </div>
                                            </div>
                                          </div>
                                        </div>



                                        <div class="form-body">
                                          <div class="row">
                                            <div class="col-12">
                                              <h4 class="card-title" id="from-actions-top-bottom-center">
                                                I-3 Antécédants
                                                gynecologiques et
                                                obstétricaux
                                              </h4>

                                              <div class="form-group">
                                                <div class="position-relative has-icon-left">
                                                  <textarea id="timesheetinput7" rows="3"
                                                    class="form-control round border-primary"
                                                    v-model="formData.antecedantPersonnel.gynecologiques"
                                                    name="antecedantsgynecologiques" placeholder=""></textarea>
                                                  <div class="form-control-position">
                                                    <i class="ft-file"></i>
                                                  </div>
                                                </div>
                                              </div>
                                            </div>
                                          </div>
                                        </div>



                                        <div class="form-body">
                                          <div class="row">
                                            <div class="col-12">
                                              <h4 class="card-title" id="from-actions-top-bottom-center">
                                                I-4 Antécédants
                                                Nutritionnels
                                              </h4>

                                              <div class="form-group">
                                                <div class="position-relative has-icon-left">
                                                  <textarea id="timesheetinput7" rows="3"
                                                    class="form-control round border-primary"
                                                    name="antecedantsnutritionnels"
                                                    v-model="formData.antecedantPersonnel.nutritionnels"
                                                    placeholder=""></textarea>
                                                  <div class="form-control-position">
                                                    <i class="ft-file"></i>
                                                  </div>
                                                </div>
                                              </div>
                                            </div>
                                          </div>
                                        </div>


                                      </div>
                                      <div class="col-12">
                                        <h2 class="card-title text-center">
                                          II- ANTÉCÉDANTS FAMILIAUX
                                        </h2>
                                        <h4 class="card-title" id="from-actions-top-bottom-center">
                                          II-1 Ascendants
                                        </h4>
                                        <div class="form-group">
                                          <div class="position-relative has-icon-left">
                                            <textarea id="timesheetinput7" rows="3"
                                              v-model="formData.antecedantFamiliaux.ascendant"
                                              class="form-control round border-primary" name="antecedantsmedicaux"
                                              placeholder=""></textarea>
                                            <div class="form-control-position">
                                              <i class="ft-file"></i>
                                            </div>
                                          </div>
                                        </div>

                                        <h4 class="card-title" id="from-actions-top-bottom-center">
                                          II-2 Colatéraux
                                        </h4>
                                        <div class="form-body">
                                          <div class="row">
                                            <div class="col-12">
                                              <div class="form-group">
                                                <div class="position-relative has-icon-left">
                                                  <textarea id="timesheetinput7" rows="3"
                                                    v-model="formData.antecedantFamiliaux.colateraux"
                                                    class="form-control round border-primary"
                                                    name="antecedantschirurgicaux" placeholder=""></textarea>
                                                  <div class="form-control-position">
                                                    <i class="ft-file"></i>
                                                  </div>
                                                </div>
                                              </div>
                                            </div>
                                          </div>
                                        </div>



                                        <div class="form-body">
                                          <div class="row">
                                            <div class="col-12">
                                              <h4 class="card-title" id="from-actions-top-bottom-center">
                                                II-3 Descendant
                                              </h4>

                                              <div class="form-group">
                                                <div class="position-relative has-icon-left">
                                                  <textarea id="timesheetinput7" rows="3"
                                                    class="form-control round border-primary"
                                                    name="antecedantsnutritionnels"
                                                    v-model="formData.antecedantFamiliaux.descendant"
                                                    placeholder=""></textarea>
                                                  <div class="form-control-position">
                                                    <i class="ft-file"></i>
                                                  </div>
                                                </div>
                                              </div>
                                            </div>
                                          </div>
                                        </div>



                                      </div>
                                      <div class="col-12">
                                        <h4 class="card-title" style="text-align: center">
                                          Allergies, Totem et mode de vie
                                        </h4>
                                        <div class="row">
                                          <div class="col-12">
                                            <div class="form-group">
                                              <label for="timesheetinput7">Allergies liées aux
                                                médicaments</label>
                                              <div class="position-relative has-icon-left">
                                                <textarea id="timesheetinput7" rows="2"
                                                  v-model="formData.autres.allergieMedicament"
                                                  class="form-control round border-primary" name="allergiesmedicaments"
                                                  placeholder=""></textarea>
                                                <div class="form-control-position">
                                                  <i class="ft-file"></i>
                                                </div>
                                              </div>
                                            </div>
                                          </div>

                                          <div class="col-12">
                                            <div class="form-group">
                                              <label for="timesheetinput7">Allergies liées aux
                                                aliments</label>
                                              <div class="position-relative has-icon-left">
                                                <textarea id="timesheetinput7" rows="2"
                                                  v-model="formData.autres.allergieAliment"
                                                  class="form-control round border-primary" name="allergiestotems"
                                                  placeholder=""></textarea>
                                                <div class="form-control-position">
                                                  <i class="ft-file"></i>
                                                </div>
                                              </div>
                                            </div>
                                          </div>

                                          <div class="col-6">
                                            <div class="form-group">
                                              <label for="timesheetinput7">Totem</label>
                                              <div class="position-relative has-icon-left">
                                                <textarea id="timesheetinput7" rows="2" v-model="formData.autres.totem"
                                                  class="form-control round border-primary" name="allergiesaliments"
                                                  placeholder=""></textarea>
                                                <div class="form-control-position">
                                                  <i class="ft-file"></i>
                                                </div>
                                              </div>
                                            </div>
                                          </div>

                                          <div class="col-6">
                                            <div class="form-group">
                                              <label for="timesheetinput7">Mode de vie</label>
                                              <div class="position-relative has-icon-left">
                                                <textarea id="timesheetinput7" rows="2" v-model="formData.autres.modeVie"
                                                  class="form-control round border-primary" name="modedevie"
                                                  placeholder=""></textarea>
                                                <div class="form-control-position">
                                                  <i class="ft-file"></i>
                                                </div>
                                              </div>
                                            </div>
                                          </div>
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                  <div class="text-right">
                                    <button type="submit" class="btn btn-primary">

                                      <span class="fs-5 fs-semibold" v-if="!chargement">
                                        <i class="la la-check-square-o"></i>
                                        Enregistrer
                                      </span>
                                      <span v-else class="d-flex align-items-center">
                                        <span class="mx-2 fs-semibold text-light">
                                          chargement ...
                                        </span>
                                        <div style="width: 1.5rem; height: 1.5rem" class="spinner-border text-light"
                                          role="status">
                                          <span class="sr-only">Loading...</span>
                                        </div>
                                      </span>
                                    </button>
                                  </div>

                                </form>


                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                      <!-- Consultation -->
                      <div class="tab-pane" id="linkIcon12" role="tabpanel" aria-labelledby="linkIcon12-tab1"
                        aria-expanded="false">
                        <div class="row justify-content-md-center">
                          <div class="col-md-12">
                            <div class="card">
                              <div class="card-header">
                                <h4 class="card-title" id="from-actions-top-bottom-center text-uppercase">
                                  <strong>1- GENERALITES</strong>
                                </h4>
                              </div>
                              <div class="card-content collpase show">
                                <div class="card-body">
                                  <form class="form">
                                    <h4 class="card-title" id="from-actions-top-bottom-center text-uppercase">
                                      <strong>MOTIF DE CONSULTATION</strong>
                                    </h4>
                                    <div class="form-body">
                                      <div class="row">
                                        <div class="form-group col-12 mb-2">
                                          <input type="text" id="eventInput1"
                                            v-model=consultationParams.consultation_reason
                                            class="form-control round border-primary"
                                            placeholder="Entrer le motif de consultation" name="motifconsultattion" />
                                        </div>
                                      </div>
                                    </div>

                                    <h4 class="card-title" id="from-actions-top-bottom-center">
                                      <strong>HYPOTHESES DIAGNOSTICS</strong>
                                    </h4>
                                    <div class="form-body">
                                      <div class="row">
                                        <div class="form-group col-12 mb-2">
                                          <input type="text" id="eventInput1" class="form-control round border-primary"
                                            placeholder="Entrer l'hypothese du diagnostic"
                                            v-model="consultationParams.hypothesis_diagnostic"
                                            name="hypothesediagnostic" />
                                        </div>
                                      </div>
                                    </div>

                                    <h4 class="card-title" id="from-actions-top-bottom-center">
                                      <strong>DIAGNOSTIC RETENU</strong>
                                    </h4>
                                    <div class="form-body">
                                      <div class="row">
                                        <div class="form-group col-12 mb-2">
                                          <input type="text" id="eventInput1" v-model="consultationParams.diagnostic"
                                            class="form-control round border-primary"
                                            placeholder="Entrer le diagnostic final" name="hypothesediagnostic" />
                                        </div>
                                      </div>
                                    </div>

                                    <div class="text-right">
                                      <button @click.prevent="addDiagnostic" type="submit" class="btn btn-primary">

                                        <span class="fs-5 fs-semibold" v-if="!chargementDiagnostics">
                                          <i class="la la-check-square-o"></i>
                                          Enregistrer
                                        </span>
                                        <span v-else class="d-flex align-items-center">
                                          <span class="mx-2 fs-semibold text-light">
                                            chargement ...
                                          </span>
                                          <div style="width: 1.5rem; height: 1.5rem" class="spinner-border text-light"
                                            role="status">
                                            <span class="sr-only">Loading...</span>
                                          </div>
                                        </span>
                                      </button>
                                    </div>
                                  </form>

                                  <hr style="background: #fd9800;">
                                  <form class="form" @submit.prevent="saveAnamnese">
                                    <h4 class="card-title" id="from-actions-top-bottom-center">
                                      <strong>2- ANAMNESE</strong>
                                    </h4>

                                    <div class="form-body">
                                      <div class="row">
                                        <div class="form-group col-md-6 mb-2">
                                          <label for="userinput1">Début</label>
                                          <input type="date" id="userinput1" v-model="consultationParams.anamnese.debut"
                                            class="form-control round border-primary" placeholder=""
                                            name="debutanamnese" />
                                        </div>
                                        <div class="form-group col-md-6 mb-2">
                                          <label for="userinput1">Signes associés</label>
                                          <input type="text" id="userinput1"
                                            v-model="consultationParams.anamnese.signeAssocie"
                                            class="form-control round border-primary"
                                            placeholder="Entrer les signes associés" name="signeassocie" />
                                        </div>

                                        <div class="form-group col-md-6 mb-2">
                                          <label for="userinput1">Signes négatifs</label>
                                          <input type="text" id="userinput1"
                                            v-model="consultationParams.anamnese.signeNegatif"
                                            class="form-control round border-primary"
                                            placeholder="Entrer les signes négatifs" name="Signesnegatifs" />
                                        </div>
                                        <div class="form-group col-md-6 mb-2">
                                          <label for="userinput1">Traitement avant admission</label>
                                          <input type="text" id="userinput1"
                                            v-model="consultationParams.anamnese.traitementAvantAdmission"
                                            class="form-control round border-primary" placeholder="Entrer le traitement"
                                            name="traitementavantadmission" />
                                        </div>

                                        <div class="form-group col-md-6 mb-2">
                                          <label for="userinput1">Bilan avant admission</label>
                                          <input type="text" id="userinput1" class="form-control round border-primary"
                                            placeholder="Entrer le bilan"
                                            v-model="consultationParams.anamnese.bilanAvantAdmission"
                                            name="bilanavantadmission" />
                                        </div>
                                        <div class="form-group col-md-6 mb-2">
                                          <label for="userinput1">Evolution</label>
                                          <input type="text" id="userinput1"
                                            v-model="consultationParams.anamnese.evolution"
                                            class="form-control round border-primary" placeholder="Entrer l'évolution"
                                            name="evolutionanamese" />
                                        </div>
                                      </div>
                                    </div>

                                    <div class="text-right">
                                      <button type="submit" class="btn btn-primary">

                                        <span class="fs-5 fs-semibold" v-if="!chargementAnamnese">
                                          <i class="la la-check-square-o"></i>
                                          Enregistrer
                                        </span>
                                        <span v-else class="d-flex align-items-center">
                                          <span class="mx-2 fs-semibold text-light">
                                            chargement ...
                                          </span>
                                          <div style="width: 1.5rem; height: 1.5rem" class="spinner-border text-light"
                                            role="status">
                                            <span class="sr-only">Loading...</span>
                                          </div>
                                        </span>
                                      </button>
                                    </div>
                                  </form>

                                  <hr style="background: #fd9800;">
                                  <form @submit.prevent="saveAnthro" class="form">
                                    <h4 class="card-title" id="from-actions-top-bottom-center">
                                      <strong>3- DONNÉES ANTHROPOMETRIQUE</strong>
                                    </h4>
                                    <div class="form-body">
                                      <div class="row">
                                        <div class="form-group col-md-4 mb-2">
                                          <label for="userinput1">Taille</label>
                                          <div class="input-group mt-0">
                                            <input type="number" id="userinput1"
                                              v-model="anthropoData.taille"
                                              class="form-control round border-primary" placeholder="Entrer la taille" name="taille" />
                                            <div class="input-group-append">
                                              <span class="input-group-text">cm</span>
                                            </div>
                                          </div>
                                        </div>
                                        <div class="form-group col-md-4 mb-2">
                                          <label for="userinput1">Poids</label>
                                          <div class="input-group mt-0">
                                            <input type="number" id="userinput1"
                                              v-model="anthropoData.poids"
                                              class="form-control round border-primary" placeholder="Entrer le poids" name="poids" />
                                            <div class="input-group-append">
                                              <span class="input-group-text">Kg</span>
                                            </div>
                                          </div>
                                        </div>
                                        <div class="form-group col-md-4 mb-2">
                                          <label for="userinput1">IMC</label>
                                          <div class="input-group mt-0">
                                            <input type="number" id="userinput1" class="form-control round border-primary"
                                              placeholder="Entrer la valeur"
                                              v-model="anthropoData.imc" name="imc" />
                                            <div class="input-group-append">
                                              <span class="input-group-text">Kg/m²</span>
                                            </div>
                                          </div>
                                        </div>

                                        <div class="form-group col-md-4 mb-2">
                                          <label for="userinput1">Température</label>
                                          <div class="input-group mt-0">
                                            <input type="number" id="userinput1" class="form-control round border-primary"
                                              placeholder="Entrer la température"
                                              v-model="anthropoData.temperature"
                                              name="temperature" />
                                            <div class="input-group-append">
                                              <span class="input-group-text">°C</span>
                                            </div>
                                          </div>
                                        </div>
                                        <div class="form-group col-md-4 mb-2">
                                          <label for="userinput1">Pouls</label>
                                          <div class="input-group mt-0">
                                            <input type="number" id="userinput1"
                                              v-model="anthropoData.pouls"
                                              class="form-control round border-primary" placeholder="Entrer le pouls"
                                              name="pouls" />
                                            <div class="input-group-append">
                                              <span class="input-group-text">BPM</span>
                                            </div>
                                          </div>
                                        </div>

                                        <div class="form-group col-md-4 mb-2">
                                          <label for="userinput1">Bras gauche</label>
                                          <div class="input-group mt-0">
                                            <input type="number" id="userinput1" class="form-control round border-primary"
                                              placeholder="Entrer la valeur"
                                              v-model="anthropoData.bras_gauche"/>
                                            <div class="input-group-append">
                                              <span class="input-group-text">cmHG</span>
                                            </div>
                                          </div>
                                        </div>
                                        <div class="form-group col-md-4 mb-2">
                                          <label for="userinput1">Bras droit</label>
                                          <div class="input-group mt-0">
                                            <input type="number" id="userinput1" class="form-control round border-primary"
                                              v-model="anthropoData.bras_droit"
                                              placeholder="Entrer la valeur" name="brasdroit" />
                                            <div class="input-group-append">
                                              <span class="input-group-text">cmHG</span>
                                            </div>
                                          </div>
                                        </div>

                                         <div class="form-group col-md-4 mb-2">
                                            <label for="userinput1" class="curseur" @click.prevent="addChamp"><i
                                                class="la la-plus "></i>
                                              Autre donnée</label>
                                          </div>

                                      </div>
                                         <div v-for="(champs, index) in anthropoData.autres" :key=index class="">
                                            <div class="input-group mb-1">
                                              <div class="input-group-prepend">
                                                <span class="input-group-text" id="">libellé</span>
                                              </div>
                                              <input v-model="champs.libelle" type="text" class="form-control" placeholder="libellé">
                                              <div class="input-group-prepend">
                                                <span class="input-group-text" id="">valeur</span>
                                              </div>
                                              <input v-model="champs.valeur" type="text" class="form-control" placeholder="valeur">
                                              <div class="input-group-prepend">
                                                <span class="input-group-text" id="">unité</span>
                                              </div>
                                              <input v-model="champs.unite" type="text" class="form-control" placeholder="unité">
                                              <div class="input-group-append">
                                                <span class="input-group-text btn btn-danger" id="" @click="supprimerChamps(index)"><i class="fa fa-trash"></i></span>
                                              </div>
                                            </div>
                                          </div>
                                    </div>

                                    <div class="text-right">
                                      <button type="submit" class="btn btn-primary">

                                        <span class="fs-5 fs-semibold" v-if="!chargementAntro">
                                          <i class="la la-check-square-o"></i>
                                          Enregistrer
                                        </span>
                                        <span v-else class="d-flex align-items-center">
                                          <span class="mx-2 fs-semibold text-light">
                                            chargement ...
                                          </span>
                                          <div style="width: 1.5rem; height: 1.5rem" class="spinner-border text-light"
                                            role="status">
                                            <span class="sr-only">Loading...</span>
                                          </div>
                                        </span>
                                      </button>
                                    </div>
                                  </form>

                                  <hr style="background: #fd9800;">
                                  <form class="form">
                                      <h4 class="card-title text-center" id="from-actions-top-bottom-center">
                                        <strong>4 - PROTOCOLES DE SOINS</strong>
                                      </h4>
                                      
                                      <div class="text-left">
                                          <button type="submit" class="btn btn-success" data-toggle="modal"
                                            data-target="#modal_protocole" id="btnGroupDrop1" aria-haspopup="true"
                                            aria-expanded="false" @click.prevent="addProtocole">
                                            Ajouter 
                                          </button>
                                        </div>

                                        <div class="modal animated bounce text-left" id="modal_protocole" tabindex="-1"
                                          role="dialog" aria-labelledby="myModalLabel36" aria-hidden="true">
                                          
                                      <div class="modal-dialog modal-lg" role="document">
                                        <div class="modal-content">
                                          <div class="modal-header bg-primary">
                                            <label class="modal-title white text-text-bold-600"
                                              id="myModalLabel33">Protocoles de soins</label>
                                            <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                                              <span aria-hidden="true">&times;</span>
                                            </button>
                                          </div>
                                          <form action="#">
                                            <div class="modal-body" style="background: #f4f5fa">
                                              <div class="form-body">
                                                <h4 class="text-center pt-2">
                                                  Protocoles de soins H(0)
                                                </h4>

                                                <div class="row">
                                                  <div class="col-md-6 offset-md-3">
                                                    <div class="row">
                                                      <div class="form-group col-md-6">
                                                        <label for="userinput1">Date</label>
                                                        <input type="date" id="userinput1"
                                                          class="form-control round border-primary" placeholder=""
                                                          v-model="protocoleData.date"
                                                          name="protocole_date" />
                                                      </div>
                                                      <div class="form-group col-md-6">
                                                        <label for="userinput1">Heure</label>
                                                        <input type="time" id="userinput1"
                                                          class="form-control round border-primary" placeholder=""
                                                          v-model="protocoleData.heure"
                                                          name="protocole_heure" />
                                                      </div>
                                                    </div>
                                                  </div>
                                                </div>

                                                <div class="col-xl-12 col-lg-12">
                                                  <h2 class="">
                                                    Admissions
                                                  </h2>

                                                  <div class="card" style="height: auto">
                                                    <div class="card-content">
                                                      <div class="card-body">
                                                        <div class="tab-content px-1 pt-1">
                                                          <div role="tabpanel" class="tab-pane active" id="pill31"
                                                            aria-expanded="true" aria-labelledby="base-pill31">
                                                            <div
                                                              v-for="(data, index) in protocoleData.admissions"
                                                              :key=index class="row">
                                                              <div class="form-group col-md-4 mb-2">
                                                                <label for="userinput1">Soin *</label>
                                                                <select v-model="data.soin" name="" id=""
                                                                  class="form-control round border-primary">
                                                                  <option value="pansement">
                                                                    Pansement
                                                                  </option>
                                                                  <option value="perfusion">
                                                                    Perfusion
                                                                  </option>
                                                                  <option value="injection en iv">
                                                                    Injection en IV
                                                                  </option>
                                                                  <option value="injection en im">
                                                                    Injection en IM
                                                                  </option>
                                                                </select>
                                                              </div>
                                                              <div class="form-group col-md-7 mb-2">
                                                                <label for="userinput1">Description </label>
                                                                <textarea name="" v-model="data.description"
                                                                  placeholder="Produit, quantité dosage consommable..."
                                                                  class="form-control round border-primary" id=""
                                                                  cols="30" rows="2"></textarea>
                                                              </div>
                                                              <div class="col-md-1 mb-2">
                                                                <button @click.prevent="supprimerSoin(index)"
                                                                  class="btn btn-danger"> x </button>
                                                              </div>

                                                            </div>
                                                          </div>
                                                        </div>
                                                        <span class="mb-2">
                                                          <button @click.prevent="addSoin" class="btn btn-primary">
                                                            Ajouter un soin </button>

                                                        </span>
                                                      </div>
                                                    </div>
                                                  </div>
                                                </div>
                                              </div>
                                              <div class="card">
                                                <div class="card-content">
                                                  <div class="card-body">
                                                    <h2 class="">Constantes</h2>

                                                    <div>
                                                      <label class="container">Temperature
                                                        <input type="checkbox"
                                                          v-model="protocoleData.temperature"
                                                          step="0.1" true-value="1" false-value="0">
                                                        <span class="checkmark"></span>
                                                      </label>

                                                      <label class="container">Tension
                                                        <input type="checkbox"
                                                          v-model="protocoleData.tension" step="0.1"
                                                          true-value="1" false-value="0">
                                                        <span class="checkmark"></span>
                                                      </label>

                                                      <label class="container">Pouls
                                                        <input type="checkbox"
                                                          v-model="protocoleData.pouls" step="0.1"
                                                          true-value="1" false-value="0">
                                                        <span class="checkmark"></span>
                                                      </label>
                                                    </div>
                                                  </div>
                                                </div>
                                              </div>
                                            </div>
                                            <div class="modal-footer">
                                              <input type="reset" class="btn btn-secondary" data-dismiss="modal"
                                                value="Vider" @click="resetProtocoleData()" />
                                              <button data-dismiss="modal" aria-label="Close"
                                                @click.prevent="saveProtocole"
                                                class="btn btn-primary">Enregistrer</button>
                                            </div>
                                          </form>
                                        </div>
                                      </div>
                                        </div>
                                        <div class="text-right">
                                          <div class="card-content">
                                            <div class="card-body text-center">
                                              <div class="row" id="select" data-select2-id="select">
                                                <div class="col-12" data-select2-id="20">
                                                  <div class="card" data-select2-id="19">
                                                    <div class="table-responsive">
                                                      <table class="table">
                                                        <thead>
                                                          <tr>
                                                            <th>#</th>
                                                            <th>Date</th>
                                                            <th>Heure</th>
                                                            <th>Soins</th>
                                                            <th>Actions</th>
                                                          </tr>
                                                        </thead>
                                                        <tbody>
                                                          <tr v-for="(protocole, index) in consultationParams.protocoleSoins"
                                                            :key=index>
                                                            <th scope="row"> {{ index + 1 }}</th>
                                                            <td>{{ protocole.protocole_date }}</td>
                                                            <td>{{ protocole.heure }}</td>
                                                            <td>
                                                                <ul class="list-group">
                                                                  <li v-for="amission in protocole.admissions" class="m-0 p-1 list-group-item d-flex justify-content-between align-items-center">
                                                                    <strong>{{ amission.soin }}</strong>
                                                                    <span class="badge badge-primary badge-pill">{{ amission.description }}</span>
                                                                  </li>
                                                                </ul>
                                                            </td>
                                                            <td>
                                                              <button  data-toggle="modal" data-target="#modal_protocole" @click.prevent="editProtocole(index)" class="btn btn-secondary">Modifier</button>
                                                              <button @click.prevent="deleteProtocole(index)" class="btn btn-danger">Supprimer</button>
                                                            </td>
                                                          </tr>
                                                        </tbody>
                                                      </table>
                                                  </div>
                                                </div>
                                              </div>
                                            </div>
                                          </div>
                                          </div>
                                        </div>
                                    </form>

                                  <hr style="background: #fd9800;">
                                  <h4 class="card-title " id="from-actions-top-bottom-center">
                                    <strong>5- EXAMENS PARACLINIQUES</strong>
                                  </h4>
                                  <div class="">
                                    <div class="">

                                      <form class="form">
                                        <h4 class="card-title text-center" id="from-actions-top-bottom-center">
                                          <strong>5-1 EXAMENS RADIOLOGIQUES</strong>
                                        </h4>
                                        <div class="text-left">
                                          <button type="submit" class="btn btn-success" data-toggle="modal"
                                            data-target="#bounce" id="btnGroupDrop1" aria-haspopup="true"
                                            aria-expanded="false" @click.prevent="addExam">
                                            Ajouter un examen
                                          </button>
                                        </div>

                                        <div class="modal animated bounce text-left" id="bounce" tabindex="-1"
                                          role="dialog" aria-labelledby="myModalLabel36" aria-hidden="true">
                                          <div class="modal-dialog modal-lg" role="document">
                                            <div class="modal-content">
                                              <div class="modal-header">
                                                <h4 class="modal-title " id="myModalLabel36">
                                                  Ajouter un nouvel examen
                                                </h4>
                                                <button type="button" class="close" data-dismiss="modal"
                                                  aria-label="Close">
                                                  <span aria-hidden="true">&times;</span>
                                                </button>
                                              </div>
                                              <form class="modal-body">
                                                <div class="form-body">
                                                  <div class="row my-2">
                                                    <div class="col-md-12">

                                                      <div>
                                                        <div class="row">
                                                          <div class="form-group col-md-4 mb-2">
                                                            <label for="userinput1">Nom</label>
                                                            <VueMultiselect v-model="examenData.name"
                                                              :options="taggingOptionsType"
                                                              :selectLabel="'Appuyez sur Entrée pour sélectionner'"
                                                              :deselectLabel="'Appuyez sur Entrée pour supprimer'"
                                                              selectedLabel="Selectionné"
                                                              placeholder="Selectionnez un examen" label="name"
                                                              track-by="code" />
                                                          </div>
                                                          <div class="form-group col-md-7 mb-2">
                                                            <label for="userinput1">Interpretation </label>
                                                            <textarea name="" v-model="examenData.interpretation"
                                                              placeholder="Entrer une interprétation"
                                                              class="form-control round border-primary" id="" cols="30"
                                                              rows="2"></textarea>
                                                          </div>
                                                          <!-- <div class="col-md-1 mt-3">
                                                            <button @click.prevent="supprimerExam(index)"
                                                              class="btn btn-danger"> x </button>
                                                          </div> -->
                                                        </div>
                                                        <div class="form-group col-md-4 mx-auto mb-2">
                                                          <!-- <label for="file-input-{{index}}">
                                                            <i class="ft-paperclip ml-1 cursor-pointer"></i>
                                                          </label> -->
                                                          <input :id="'file-input-' + index" type="file" name="file"
                                                            ref="file" @change="handleFileUpload" />
                                                        </div>
                                                      </div>
                                                    </div>

                                                  </div>

                                                  <button type="submit" class="btn btn-primary w-100"
                                                    @click.prevent="saveExam" data-dismiss="modal" aria-label="Close"> <i
                                                      class="la la-check-square-o"></i>Enregistrer
                                                  </button>

                                                </div>
                                              </form>
                                            </div>
                                          </div>
                                        </div>
                                        <div class="text-right">
                                          <div class="card-content">
                                            <div class="card-body text-center">
                                              <div class="row" id="select" data-select2-id="select">
                                                <div class="col-12" data-select2-id="20">
                                                  <div class="card" data-select2-id="19">
                                                    <div class="table-responsive">
                                                      <table class="table">
                                                        <thead>
                                                          <tr>
                                                            <th>#</th>
                                                            <th>Nom de l'examen</th>
                                                            <th>Interpretation</th>
                                                            <th>Fichier</th>
                                                            <th>Actions</th>
                                                          </tr>
                                                        </thead>
                                                        <tbody>
                                                          <tr v-for="(exam, index) in consultationParams.exams"
                                                            :key=index>
                                                            <th scope="row"> {{ index + 1 }}</th>
                                                            <td>{{ exam.name }}</td>
                                                            <td>{{ exam.interpretation }}</td>
                                                            <td v-if="exam.fichier">
                                                              <a target="blank" :href="examUrl[exam.id]">Voir</a>
                                                            </td>
                                                            <td v-else>Non</td>
                                                            <td><button @click.prevent="supprimerExam(exam.id)"
                                                              class="btn btn-outline-danger"> x </button></td>
                                                          </tr>
                                                        </tbody>
                                                      </table>
                                                  </div>
                                                </div>
                                              </div>
                                            </div>
                                          </div>
                                        </div>
                                    </div>
                                    </form>
                                  </div>
                                  <div class="">
                                    <form class="form">
                                      <h4 class="card-title text-center" id="from-actions-top-bottom-center">
                                        <STrong>5-2 ANALYSES BIOLOGIQUES</STrong>
                                      </h4>
                                      
                                      <div class="text-left">
                                          <button type="submit" class="btn btn-success" data-toggle="modal"
                                            data-target="#bounce1" id="btnGroupDrop1" aria-haspopup="true"
                                            aria-expanded="false" @click.prevent="addExam">
                                            Ajouter une analyse
                                          </button>
                                        </div>

                                        <div class="modal animated bounce text-left" id="bounce1" tabindex="-1"
                                          role="dialog" aria-labelledby="myModalLabel36" aria-hidden="true">
                                          <div class="modal-dialog modal-lg" role="document">
                                            <div class="modal-content">
                                              <div class="modal-header">
                                                <h4 class="modal-title " id="myModalLabel36">
                                                  Ajouter une nouvelle analyse
                                                </h4>
                                                <button type="button" class="close" data-dismiss="modal"
                                                  aria-label="Close">
                                                  <span aria-hidden="true">&times;</span>
                                                </button>
                                              </div>
                                              <form class="modal-body">
                                                <div class="form-body">
                                                  <div class="row my-2">
                                                    <div class="col-md-12">

                                                      <div>
                                                        <div class="row">
                                                          <div class="form-group col-md-4 mb-2">
                                                            <label for="userinput1">Nom</label>
                                                            <VueMultiselect v-model="analyseData.name"
                                                              :options="taggingOptionsBio"
                                                              :selectLabel="'Appuyez sur Entrée pour sélectionner'"
                                                              :deselectLabel="'Appuyez sur Entrée pour supprimer'"
                                                              selectedLabel="Selectionné"
                                                              placeholder="Selectionnez un examen" label="name"
                                                              track-by="code" />
                                                          </div>
                                                          <div class="form-group col-md-7 mb-2">
                                                            <label for="userinput1">resultat </label>
                                                            <input type="text" name="" v-model="analyseData.resultat" />
                                                          </div>
                                                          <div class="form-group col-md-7 mb-2">
                                                            <label for="userinput1">Interpretation </label>
                                                            <textarea name="" v-model="analyseData.interpretation"
                                                              placeholder="Entrer une interprétation"
                                                              class="form-control round border-primary" id="" cols="30"
                                                              rows="2"></textarea>
                                                          </div>
                                                        </div>
                                                        <div class="form-group col-md-4 mx-auto mb-2">
                                                          <input type="file" name="file"
                                                            ref="file" @change="handleFileUploading" />
                                                        </div>
                                                      </div>
                                                    </div>

                                                  </div>

                                                  <button type="submit" class="btn btn-primary w-100"
                                                    @click.prevent="saveAnalyse" data-dismiss="modal" aria-label="Close"> <i
                                                      class="la la-check-square-o"></i>Enregistrer
                                                  </button>

                                                </div>
                                              </form>
                                            </div>
                                          </div>
                                        </div>
                                        <div class="text-right">
                                          <div class="card-content">
                                            <div class="card-body text-center">
                                              <div class="row" id="select" data-select2-id="select">
                                                <div class="col-12" data-select2-id="20">
                                                  <div class="card" data-select2-id="19">
                                                    <div class="table-responsive">
                                                      <table class="table">
                                                        <thead>
                                                          <tr>
                                                            <th>#</th>
                                                            <th>Nom de l'examen</th>
                                                            <th>Interpretation</th>
                                                            <th>Fichier</th>
                                                            <th>Actions</th>
                                                          </tr>
                                                        </thead>
                                                        <tbody>
                                                          <tr v-for="(analyse, index) in consultationParams.analyses"
                                                            :key=index>
                                                            <th scope="row"> {{ index + 1 }}</th>
                                                            <td>{{ analyse.name }}</td>
                                                            <td>{{ analyse.interpretation }}</td>
                                                            <td v-if="analyse.fichier">
                                                              <a target="blank" :href="imageUrl[analyse.id]">Voir</a>
                                                            </td>
                                                            <td v-else>Non</td>
                                                            <td><button @click.prevent="supprimerAnalyse(analyse.id)"
                                                              class="btn btn-outline-danger"> x </button></td>
                                                          </tr>
                                                        </tbody>
                                                      </table>
                                                  </div>
                                                </div>
                                              </div>
                                            </div>
                                          </div>
                                          </div>
                                        </div>
                                    </form>
                                  </div>
                                  </div>

                                <!-- <form class="form">
                                  <h4 class="card-title" id="from-actions-top-bottom-center">
                                    <strong>9- ORDONNANCIER</strong>
                                  </h4>
                                  <div class="text-right">
                                    <router-link to="/admin/ordonnance/index" class="btn btn-info mr-1">
                                      Faire une ordonnance
                                    </router-link>

                                    <div class="card-content">
                                      <div class="card-body text-center">
                                        <span class="alert-icon"><i class="la la-info-circle"
                                            style="font-size: 3.2rem; color"></i></span>
                                        <p class="text-danger">
                                          Aucune donnée disponible pour le
                                          moment!
                                        </p>
                                      </div>
                                    </div>
                                  </div>
                                  <div class="form-body"></div>
                                </form> -->

                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
        </div>
        </section>
        <!-- Tabs with Icons end -->
      </div>
    </div>
  </div>
</div></template>

<script setup>
import { ref, reactive, onMounted } from 'vue'
import { Patients } from "../../../api/patient"
import { useRouter, useRoute } from "vue-router"
import { createToast } from "mosha-vue-toastify";
import VueMultiselect from 'vue-multiselect'
import "mosha-vue-toastify/dist/style.css";
import ProtocoleService from "@/services/modules/protocole.service.js";
import AnthropoService from "@/services/modules/anthropo.service.js";
import AnamneseService from "@/services/modules/anamnese.service.js";
import HistoriquePatientService from "@/services/modules/historique.patient.service.js";
import ConseilService from "@/services/modules/conseil.patient.service.js";
import PatientService from "@/services/modules/patient.service.js";
import ConsultationService from "@/services/modules/consultation.service.js";
import ExamenService from "@/services/modules/examen.service.js";
import AnalyseService from "@/services/modules/analyse.service.js";
import FinconsultationService from "@/services/modules/finconsultation.service.js";

const router = useRouter()
const route = useRoute()

const patients = ref([])
const consultationId = ref(null)
const consultationType = ref(null)
const infoPatient = ref([])
const PatientId = ref(null)
const PatientHistory = ref(null)
const HistoryId = ref(null)
const imageUrl = ref({});
const examUrl = ref({});
const dataExams = new FormData();

const consultationParams = reactive({
  patient_id: "",
  consultation_reason: "",
  hypothesis_diagnostic: "",
  diagnostic: "",
  anamnese: {
    debut: '',
    signeAssocie: '',
    signeNegatif: '',
    traitementAvantAdmission: '',
    bilanAvantAdmission: '',
    evolution: '',
  },
  protocoleSoins: [],
  exams: [],
  analyses: []
});

const examenData = reactive(
  {
    name: '',
    interpretation: '',
    fichier: '',
  }
)


const detailsHistorie = ref()

// const consultation = reactive({
//   motif: '',
//   hypotheseDiagnostics: '',
//   examenParaclinique: {
//     typeAnalyse: '',
//     analyseBiologique: '',
//   },
//   diagnostics: '',
//   protocoleSoins: []
// }

// )


const anthropoData = reactive({
  taille: '',
  poids: '',
  imc: '',
  temperature: '',
  pouls: '',
  etat_general: '',
  bras_gauche: '',
  bras_droit: '',
  autres: [],
  patient_id: '',
  consultation_id: ''
})
const historiquesPatiens = ref([])
const list_conseils = ref([])

const current_user = reactive({id: '', doctor_id: ''})
const getCurrentUser = () => {
    let usersInfo = JSON.parse(localStorage.getItem('paevcliniqueInfo'))
    if(usersInfo != null || usersInfo != undefined ) {
      current_user.id = usersInfo.users.id
      current_user.doctor_id = usersInfo.doctor_id
    }
}

const getConsultation = (id) => {
  ConsultationService.get(route.params.id).then((data) => {
    let infos = data.data.data
    consultationId.value = id
    consultationType.value= infos.consultation_type
    infoPatient.value = infos.patient
    PatientId.value = infoPatient.value.id
    // current_conseil.value = infos.patient.id
    PatientHistory.value = infos.history
    getConseil()
    if (infos.consultation_reason) {
      consultationParams.consultation_reason = infos.consultation_reason
    }
    if (infos.hypothesis_diagnostic) {
      consultationParams.hypothesis_diagnostic = infos.hypothesis_diagnostic
    }
    if (infos.diagnostic) {
      consultationParams.diagnostic = infos.diagnostic
    }
    if (infos.anamnesis) {
      consultationParams.anamnese.debut = infos.anamnesis.anamnesis_beginning
      consultationParams.anamnese.signeAssocie = infos.anamnesis.anamnesis_associated_signs
      consultationParams.anamnese.signeNegatif = infos.anamnesis.anamnesis_negative_signs
      consultationParams.anamnese.traitementAvantAdmission = infos.anamnesis.anamnesis_previous_treatment
      consultationParams.anamnese.bilanAvantAdmission = infos.anamnesis.anamnesis_previous_results
      consultationParams.anamnese.evolution = infos.anamnesis.anamnesis_evolution
    }
    if (infos.history) {
      HistoryId.value = infos.history.id
      formData.antecedantPersonnel.medicaux = infos.history.medical
      formData.antecedantPersonnel.chirurgicaux = infos.history.surgical
      formData.antecedantPersonnel.gynecologiques = infos.history.gynecological
      formData.antecedantPersonnel.nutritionnels = infos.history.nutritional
      formData.antecedantFamiliaux.ascendant = infos.history.ascendant
      formData.antecedantFamiliaux.colateraux = infos.history.colateraux
      formData.antecedantFamiliaux.descendant = infos.history.descendant
      formData.autres.allergieMedicament = infos.history.drug_allergies
      formData.autres.allergieAliment = infos.history.food_allergies
      formData.autres.totem = infos.history.totems
      formData.autres.modeVie = infos.history.lifestyle
    }
    if (infos.anthropodata) {
      anthropoData.taille = infos.anthropodata.taille
      anthropoData.poids = infos.anthropodata.poids
      anthropoData.imc = infos.anthropodata.imc
      anthropoData.temperature = infos.anthropodata.temperature
      anthropoData.pouls = infos.anthropodata.pouls
      anthropoData.etat_general = infos.anthropodata.etat_general
      anthropoData.bras_gauche = infos.anthropodata.bras_gauche
      anthropoData.bras_droit = infos.anthropodata.bras_droit
      anthropoData.autres = infos.anthropodata.autres ?? []
    }
    if (infos.protocoles) {
      consultationParams.protocoleSoins = infos.protocoles
    }
    if (infos.exams.length != 0) {
      infos.exams.forEach(elm => {
            if (elm.fichier) {
              fetchExam(elm)
            }
        });
      consultationParams.exams = infos.exams
    }
    if (infos.analyses.length != 0) {

      infos.analyses.forEach(elm => {
            if (elm.fichier) {
                fetchImg(elm)
            }
        });
      consultationParams.analyses = infos.analyses
    }

  }).catch((e) => {
    // console.log(e)
  })
}

// const storeConsultation = function () {
//   consultationParams.patient_id = route.params.id
//   ConsultationService.create(consultationParams).then((response) => {
//     if (response.data.status == 'error') {
//       toast(response.data.message, 'danger')
//     }
//     else {
//       consultationParams.consultation_type = ""
//       getPatient()
//       toast("Vous avez ajouté une consultation pour ce patient", 'success')
//     }

//   })
// }
// const getDataAntho = () => {
//   AnthropoService.get().then((data) => {
//     const datas = data.data.data
//     anthropoData = datas.data
//   }).catch((e) => {
//   })
// }
// const getHistorie = function (data) {
//   detailsHistorie.value = data
// }
// const getHistorique = () => {
//   HistoriquePatientService.get().then((data) => {
//     const datas = data.data.data
//     historiquesPatiens.value = datas.data
//   }).catch((e) => {
//   })
// }
const getConseil = () => {
  ConseilService.get(infoPatient.value.id).then((data) => {
    // console.log(data.data);
    const datas = data.data.data
    list_conseils.value = datas
  }).catch((e) => {
    // console.log(e)
  })
}




const formData = reactive(
  {
    antecedantPersonnel: {
      medicaux: '',
      chirurgicaux: '',
      gynecologiques: '',
      nutritionnels: ''
    },
    antecedantFamiliaux: {
      ascendant: '',
      colateraux: '',
      descendant: '',
    },
    autres: {
      allergieMedicament: '',
      allergieAliment: '',
      totem: '',
      modeVie: ''
    }
  }
)

const addDiagnostic = function () {
  if (chargementDiagnostics.value == false) {
    chargementDiagnostics.value = true
    const donneesConsultations = {}
    donneesConsultations.consultation_type = consultationType.value
    donneesConsultations.patient_id = PatientId.value
    donneesConsultations.consultation_reason = consultationParams.consultation_reason
    donneesConsultations.hypothesis_diagnostic = consultationParams.hypothesis_diagnostic
    donneesConsultations.diagnostic = consultationParams.diagnostic
    ConsultationService.update(route.params.id, donneesConsultations).then((response) => {
      if (response.data.status == 'error') {
        chargementDiagnostics.value = false
        toast(response.data.message, 'danger')
      }
      else {
        chargementDiagnostics.value = false
        toast("Modifications éffectuées avec succès !!!", 'success')
      }

    })
  }
}


//upload de photo 
function handleFileUpload(event) {
  const file = event.target.files[0];
  examenData.fichier = file;
  toast("Fichier uploadé avec succès", "success");
};

const saveExam = function () {
    const dataExams = new FormData();
    dataExams.append('name', examenData.name.name);
    dataExams.append('interpretation', examenData.interpretation);
    dataExams.append('fichier', examenData.fichier);
    dataExams.append('patient_id', PatientId.value);
    dataExams.append('consultation_id', route.params.id);

    ExamenService.create(dataExams)
      .then((response) => {
        if (response.data.status == 'error') {
          toast(response.data.message, 'danger')
        } else {
          examenData.name = ""
          examenData.interpretation = ""
          examenData.fichier = ""
          getConsultation()
          toast("Mise à jour !!!", 'success')
        }
      });
}




const saveAnamnese = function () {
  if (chargementAnamnese.value == false) {
    chargementAnamnese.value = true
    const datas = {}
    datas.anamnesis_beginning = consultationParams.anamnese.debut
    datas.anamnesis_associated_signs = consultationParams.anamnese.signeAssocie
    datas.anamnesis_negative_signs = consultationParams.anamnese.signeNegatif
    datas.anamnesis_previous_treatment = consultationParams.anamnese.traitementAvantAdmission
    datas.anamnesis_previous_results = consultationParams.anamnese.bilanAvantAdmission
    datas.anamnesis_evolution = consultationParams.anamnese.evolution
    datas.patient_id = PatientId.value
    datas.consultation_id = route.params.id

    AnamneseService.create(datas).then((data) => {
      const response = data.data
      if (response.status === 'error') {
        chargementAnamnese.value = false
        toast(response.message, 'danger')
      }
      else {
        chargementAnamnese.value = false
        toast("Anamnèse enregistrée avec succès !!!", 'success')
      }
    })
  }
}

const saveHistorique = function () {

  const datas = {}
  datas.medical = formData.antecedantPersonnel.medicaux
  datas.surgical = formData.antecedantPersonnel.chirurgicaux
  datas.gynecological = formData.antecedantPersonnel.gynecologiques
  datas.nutritional = formData.antecedantPersonnel.nutritionnels
  datas.ascendant = formData.antecedantFamiliaux.ascendant
  datas.colateraux = formData.antecedantFamiliaux.colateraux
  datas.descendant = formData.antecedantFamiliaux.descendant
  datas.drug_allergies = formData.autres.allergieMedicament
  datas.food_allergies = formData.autres.allergieAliment
  datas.totems = formData.autres.totem
  datas.lifestyle = formData.autres.modeVie
  datas.patient_id = PatientId.value
  datas.consultation_id = route.params.id
  if (chargement.value == false) {
    chargement.value = true

    HistoriquePatientService.create(datas).then((data) => {
      const response = data.data
      if (response.status === 'error') {
        chargement.value = false
        toast(response.message, 'danger')
      }
      else {
        chargement.value = false
        toast("Antécedants sauvegardés avec succès !!!", 'success')
      }
    })
  }
}



//##########################--- fin---################################

//##########################--- debut---################################

//##########################--- fin---################################

//##########################---protocole debut---################################

  const protocoleData = reactive(
    {
      id: '',
      date: '',
      heure: '',
      admissions: [],
      temperature: '',
      tension: '',
      pouls: ''
    }
  )
  const resetProtocoleData = function() {
    protocoleData.id = '';
    protocoleData.date = '';
    protocoleData.heure = '';
    protocoleData.admissions = []; //
    protocoleData.temperature = '';
    protocoleData.tension = '';
    protocoleData.pouls = '';
  }
  const addSoin = function () {
    protocoleData.admissions.push({ soin: '', description: '' })
  }

  const supprimerSoin = function (index) {
    protocoleData.admissions.splice(index, 1);
  }

  const editProtocole = function (index) {
    let selected = consultationParams.protocoleSoins[index]
    console.log(selected)
    protocoleData.id = selected.id;
    protocoleData.date = selected.date;
    protocoleData.heure = selected.heure;
    protocoleData.admissions = selected.admissions; //
    protocoleData.temperature = selected.temperature;
    protocoleData.tension = selected.tension;
    protocoleData.pouls = selected.pouls;
  }

  const saveProtocole = function () {
    const datas = {}
    datas.id = protocoleData.id
    datas.protocole_date = protocoleData.date
    datas.heure = protocoleData.heure
    datas.temperature = protocoleData.temperature
    datas.tension = protocoleData.tension
    datas.pouls = protocoleData.pouls
    datas.admissions = protocoleData.admissions
    datas.patient_id = PatientId.value
    datas.consultation_id = route.params.id
    console.log(datas)
    if(datas.id){
      ProtocoleService.update(datas.id, datas).then((data) => {
        const response = data.data
        if (response.status === 'error') {
          toast(response.message, 'danger')
        }
        else {
          resetProtocoleData()
          getConsultation()
          toast("Protocoles modifié avec succès !!!", 'success')
        }
      })
    }else{
      ProtocoleService.create(datas).then((data) => {
        const response = data.data
        if (response.status === 'error') {
          toast(response.message, 'danger')
        }
        else {
          resetProtocoleData()
          getConsultation()
          toast("Protocoles enregistrés avec succès !!!", 'success')
        }
      })
    }
  }

  const deleteProtocole = function (index) {
    let selected = consultationParams.protocoleSoins[index]
    ProtocoleService.destroy(selected.id).then((data) => {
        const response = data.data
        if (response.status === 'error') {
          toast(response.message, 'danger')
        }
        else {
          resetProtocoleData()
          getConsultation()
          toast("Protocoles supprimé avec succès !!!", 'success')
        }
      })
  }

//-----------------------------protocole fin//-----------------------------

//##########################--- analyse debut---################################

const analyseData = reactive(
  {
    name: '',
    resultat: '',
    interpretation: '',
    fichier: '',
  }
)
//
function handleFileUploading(event) {
  const file = event.target.files[0];
  analyseData.fichier = file;
  toast("Fichier uploadé avec succès", "success");
};

const saveAnalyse = function () {
    const dataAnalyse = new FormData();
    dataAnalyse.append('name', analyseData.name.name);
    dataAnalyse.append('resultat', analyseData.resultat);
    dataAnalyse.append('interpretation', analyseData.interpretation);
    dataAnalyse.append('fichier', analyseData.fichier);
    dataAnalyse.append('patient_id', PatientId.value);
    dataAnalyse.append('consultation_id', route.params.id);

    AnalyseService.create(dataAnalyse)
      .then((response) => {
        if (response.data.status == 'error') {
          toast(response.data.message, 'danger')
        } else {
          analyseData.name = ""
          analyseData.resultat = ""
          analyseData.interpretation = ""
          analyseData.fichier = ""
          getConsultation()
          toast("Mise à jour !!!", 'success')
        }
      });
}

//----------------------------- analyse fin-----------------------------

//##########################--- Anthropo debut---################################

const saveAnthro = function () {
  if (chargementAntro.value == false) {
    chargementAntro.value = true
    anthropoData.patient_id = PatientId.value
    anthropoData.consultation_id = route.params.id
    AnthropoService.create(anthropoData).then((data) => {
      const response = data.data
      if (response.status === 'error') {
        chargementAntro.value = false
        toast(response.message, 'danger')
      }
      else {
        chargementAntro.value = false
        toast("Données anthropologiques enregistrées vec succès !!!", 'success')
      }
    })
  }
};
//champs 
const addChamp = function () {
  anthropoData.autres.push({ libelle: '', valeur: '', unite: '' })
}
const supprimerChamps = function (index) {
  anthropoData.autres.splice(index, 1);
}

//----------------------------- Anthropo fin-----------------------------


//##########################---Conseil debut---################################
const current_conseil = reactive({
  "consultation_id": route.params.id, 
  "id": null, 
  "patient_id": null, 
  "doctor_id": null, 
  "comment": null
})
const saveConseil = function () {
    // est utiliser pour soumettre les conseils en create ou update
    current_conseil.patient_id = infoPatient.value.id
    current_conseil.doctor_id = current_user.doctor_id
    current_conseil.consultation_id = route.params.id

    if(current_conseil.id === null){
        ConseilService.create(current_conseil)
        .then((data) => {
          const response = data.data
          if (response.status === 'error') {
            toast(response.message, 'danger')
          }
          else {
            emptyConseil()
            getConseil()
            toast("enregistré vec succès !!!", 'success')
          }
        })
        .catch((error) => {
          let data = error.response.data
          if (data.data?.doctor_id ){
            toast("Vous n'êtes pas autorisé a effectué cette action", 'danger')
          }else{
            toast(data.message, 'danger')
          }
        });
    }else{
      ConseilService.update(current_conseil.id, current_conseil).then((data) => {
        const response = data.data
        if (response.status === 'error') {
          toast(response.message, 'danger')
        }
        else {
          emptyConseil()
          getConseil()
          toast("enregistré vec succès !!!", 'success')
        }
      })
    }
};
const editConseil = function (id, comment) {
  // est utilisé pour quand l'user click pour modifier un conseil remplir le formulaire avec les infos du conseil
    current_conseil.id = id
    current_conseil.comment = comment
};
const emptyConseil = function () {
    current_conseil.id = null
    current_conseil.comment = null
};

//-------------------------------Conseil fin-----------------------------






//multiselected option
const taggingSelectedType = ref(null)
const taggingOptionsType = ref(
  [
    { name: 'Radiographie', code: "ra" },
    { name: 'Scanner', code: 'sc' },
    { name: 'Mammographie', code: 'ma' },
    { name: 'Echographie', code: 'ec' },
    { name: 'IRM', code: 'irm' },
  ]
)
const taggingSelectedBio = ref(null)
const taggingOptionsBio = ref(
  [
    { name: 'TCK', code: 'tck' },
    { name: 'TP/INR', code: 'tp' },
    { name: 'CPK', code: 'cpk' },
    { name: "GSRH", code: 'gsrh' },
    { name: "G6PD", code: 'g6pd' },
    { name: "BIA", code: 'bia' },
    { name: "EHb", code: 'ehb' },
    { name: 'Glycémie', code: "gl" },
    { name: 'NFS', code: 'nsf' },
    { name: 'Natrémie', code: 'na' },
    { name: 'Kaliémie', code: 'ka' },
    { name: 'Chlorémie', code: 'ch' },
    { name: 'Calcémie', code: 'ca' },
    { name: 'Magnésémie', code: 'ma' },
    { name: 'Transaminases + TGO +TGT', code: 'tr' },
    { name: 'Amylasémie', code: 'amy' },
    { name: 'Azotémie', code: 'azo' },
    { name: 'Créatininémie', code: 'cre' },
    { name: 'Uricémie', code: 'urc' },
    { name: 'ECG', code: 'ecg' },
    { name: 'Cholestérolémie T', code: 'chot' },
    { name: 'Cholestérolémie HDL', code: 'chohdl' },
    { name: 'Triglycéridémie', code: 'trig' },
    { name: 'PSA totales ', code: 'psat' },
    { name: 'PSA prostatiques/', code: 'psap' },
    { name: 'FPSA', code: 'fpsa' },
    { name: 'Electrophorèse des protéines', code: 'elecP' },
    { name: "Electrophorèse de l'HB", code: 'elech' },
    { name: "Ag HBs", code: 'ags' },
    { name: "Ag HBc", code: 'agc' },
    { name: "Alpha foeto protéines", code: 'afp' },
    { name: "Sérologie Rubéole", code: 'seror' },
    { name: "Sérologie Toxoplasmose ", code: 'serot' },
    { name: "ASLO ", code: 'aslo' },
    { name: "Biluribine D", code: 'bild' },
    { name: "Biluribine I", code: 'bili' },
    { name: "ECBU + Antibiogramme ", code: 'ecbu' },
    { name: "Spermoogramme + Antibiogramme ", code: 'sperAn' },
    { name: "Sérologie HVI", code: 'serohvi' },
  ]
)


// tag selected 
// const addTagType = function (newTag) {
//   const tag = {
//     name: newTag,
//     code: newTag.substring(0, 2) + Math.floor((Math.random() * 10000000))
//   }
//   taggingOptionsType.push(tag)
//   taggingSelectedType.push(tag)
// }
//les chargements
const chargement = ref(false)
const chargementDiagnostics = ref(false)
const chargementAntro = ref(false)
const chargementAnamnese = ref(false)
//addConsultantDiagnostic
//addConsultationAntro
//addConsultationAnamnes
//addConsultationMotif
//chargementAntro

//get data
const getData = JSON.parse(localStorage.getItem('patients'))
if (getData != null || getData != undefined) {
  const datas = getData
  patients.value = [...Patients, ...datas]
} else {
  patients.value = Patients
}

patients.value = patients.value.filter(patient => patient.id == route.params.id)

const toast = (message, type) => {
  createToast(message, { type: type })
}
// const calculIMC = function () {
//   if(consultation.donneesAnthropometrique){
//     const mettre = (consultation.donneesAnthropometrique.taille ?? 1) / 100
//     const mettreCarre = mettre * mettre
//     const poids = consultation.donneesAnthropometrique.poids ?? 1

//     const resultat = poids / mettreCarre
//     consultation.donneesAnthropometrique.imc = resultat.toFixed(2)
//   }
// }
// const addAntecedantPersonnel = function () {
//   if (chargementPersonel.value == false) {
//     chargementPersonel.value = true
//     setTimeout(() => {
//       chargementPersonel.value = false
//       toast('Nouveau enregistrment', 'success')
//     }, 7000)

//   }

// }
// const addAntecedantFamiliaux = function () {
//   if (chargementFamiliaux.value == false) {
//     chargementFamiliaux.value = true
//     setTimeout(() => {
//       chargementFamiliaux.value = false
//       toast('Nouveau enregistrment', 'success')
//     }, 7000)

//   }

// }


// const addConsultationMotif = function () {
//   if (chargementMotif.value == false) {
//     chargementMotif.value = true
//     setTimeout(() => {
//       chargementMotif.value = false
//       toast('Nouveau enregistrment', 'success')
//     }, 7000)

//   }
// }


//soins  modale

const addExam = function () {
}

const supprimerExam = function (id) {
    ExamenService.destroy(id).then((data) => {
      if (data.data.status == "success") {
        getConsultation()
        toast("Suppression de l'examen", "success");
      } else {
        toast(data.data.message, "danger");
      }
    })
}
const supprimerAnalyse = function (id) {
    AnalyseService.destroy(id).then((data) => {
      if (data.data.status == "success") {
        getConsultation()
        toast("Suppression de l'examen", "success");
      } else {
        toast(data.data.message, "danger");
      }
    })
}

//soins  modale





// function custom
function formatDateToFrench(dateStr) {
  const date = new Date(dateStr);

  const options = { year: 'numeric', month: 'long', day: 'numeric' };
  const formattedDate = date.toLocaleDateString('fr-FR', options);

  return formattedDate;
}



function fetchExam(elm) {
    const userInfo = JSON.parse(localStorage.getItem("paevcliniqueInfo"));
    const url = 'https://api.paevclinique.com/api/private-files/' + elm.fichier;
    const token = 'Bearer ' + userInfo.token;

    fetch(url, {
    headers: {
        'Authorization': token
    }
    })
    .then(response => response.blob())
    .then(blob => {
        examUrl.value[elm.id] = URL.createObjectURL(blob);
    // Utilisez l'URL de l'image pour l'afficher dans votre application Vue.js
    })
    .catch(error => console.error(error));
}

function fetchImg(elm) {
    const userInfo = JSON.parse(localStorage.getItem("paevcliniqueInfo"));
    const url = 'https://api.paevclinique.com/api/private-files/' + elm.fichier;
    const token = 'Bearer ' + userInfo.token;

    fetch(url, {
    headers: {
        'Authorization': token
    }
    })
    .then(response => response.blob())
    .then(blob => {
        imageUrl.value[elm.id] = URL.createObjectURL(blob);
    // Utilisez l'URL de l'image pour l'afficher dans votre application Vue.js
    })
    .catch(error => console.error(error));
}


const finishConsultation = function () {
  FinconsultationService.get(route.params.id).then((data) => {
    const response = data.data
    if (response.status === 'error') {
      toast(response.message, 'danger')
    }
    else {
      router.go(-2)
      toast("Consultation terminée !!!", 'success')
    }
  })
}

onMounted(() => {
  getConsultation()
  getCurrentUser()
})

</script>
<style src="vue-multiselect/dist/vue-multiselect.css"></style>
<style scoped>
  .curseur {
    cursor: pointer
  }

  .customInput {
    border: none;
    border-bottom: 1px solid black;
    outline: none;
    margin: 2px 5px;
  }

  .supprimer {
    color: red;
    font-size: 16px;
    font-weight: bold
  }

  .container {
    display: block;
    position: relative;
    padding-left: 35px;
    margin-bottom: 12px;
    cursor: pointer;
    font-size: 15px;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
  }

  /* Hide the browser's default checkbox */
  .container input {
    position: absolute;
    opacity: 0;
    cursor: pointer;
    height: 0;
    width: 0;
  }

  /* Create a custom checkbox */
  .checkmark {
    position: absolute;
    top: 0;
    left: 0;
    height: 25px;
    width: 25px;
    background-color: #eee;
  }

  /* On mouse-over, add a grey background color */
  .container:hover input~.checkmark {
    background-color: #ccc;
  }

  /* When the checkbox is checked, add a blue background */
  .container input:checked~.checkmark {
    background-color: #2196F3;
  }

  /* Create the checkmark/indicator (hidden when not checked) */
  .checkmark:after {
    content: "";
    position: absolute;
    display: none;
  }

  /* Show the checkmark when checked */
  .container input:checked~.checkmark:after {
    display: block;
  }

  /* Style the checkmark/indicator */
  .container .checkmark:after {
    left: 9px;
    top: 5px;
    width: 5px;
    height: 10px;
    border: solid white;
    border-width: 0 3px 3px 0;
    -webkit-transform: rotate(45deg);
    -ms-transform: rotate(45deg);
    transform: rotate(45deg);
  }
</style>
