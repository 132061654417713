
import ApiService from '@/services/configs/api.service'

const MessageService = {
  get(slug,element) {
    return ApiService.get(`messages`, slug,element);
  },
 
  create(params) {
    return ApiService.post("messages", params);
  },

  

  update(slug, params) {
    return ApiService.update("messages", slug, params);
  },

  destroy(slug) {
    return ApiService.delete(`messages/${slug}`);
  },
 

 
};

  export default MessageService;
  