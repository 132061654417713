<template>
  <div>
    <div class="app-content content">
      <div class="content-overlay"></div>
      <div class="content-wrapper">
        <div class="content-header row">
          <div class="content-header-left col-md-6 col-12 mb-2">
            <h3 class="content-header-title">
              Informations de compte
            </h3>

          </div>
        </div>

        <div class="content-body">
          <!-- Tabs with Icons start -->
          <section id="tabs-with-icons">
            <div class="col-xl-10 col-lg-10 offset-xl-1 offset-lg-1 mt-5">
              <div class="card">
                <div class="card-header">
                  <h4 class="card-title" id="from-actions-top-bottom-center">
                    Modifications des informations du compte
                  </h4>
                  <a class="heading-elements-toggle"><i class="la la-ellipsis-v font-medium-3"></i></a>
                  <div class="heading-elements">
                    <ul class="list-inline mb-0">
                      <li>
                        <a data-action="collapse"><i class="ft-minus"></i></a>
                      </li>
                      <li>
                        <a data-action="reload"><i class="ft-rotate-cw"></i></a>
                      </li>
                      <li>
                        <a data-action="expand"><i class="ft-maximize"></i></a>
                      </li>
                    </ul>
                  </div>
                </div>
                <div class="card-content">
                  <div class="card-body">
                    <ul class="nav nav-tabs nav-underline nav-justified">
                      <li class="nav-item">
                        <a class="nav-link active" id="linkIcon13-tab1" data-toggle="tab" href="#linkIcon13"
                          aria-controls="linkIcon13" aria-expanded="false"><i class="ft-link"></i>
                          <strong class="text-info">Profil</strong></a>
                      </li>
                      <li class="nav-item">
                        <a class="nav-link" id="linkIconOpt11-tab1" data-toggle="tab" href="#linkIconOpt11"
                          aria-controls="linkIconOpt11"><i class="ft-external-link"></i>
                          <strong class="text-info"> Sécurité </strong></a>
                      </li>
                    </ul>
                    <div class="tab-content px-1 pt-1">
                      <!-- <ProfilePatient/> -->
                      <div class="tab-pane active" id="linkIcon13" role="tabpanel" aria-labelledby="linkIcon13-tab1"
                        aria-expanded="false">
                        <div class="row">
                          <div class="col-lg-12 col-md-12">
                            <div class="card">
                              <div class="card card-content collapse show">
                                <div class="card-body">
                                  <!-- <h4 class="card-title">
                                      Informations personnelles et familliales
                                    </h4> -->
                                  <div class="media-body mt-3">

                                    <h5 class="media-heading">
                                      Identité
                                      <div class="form-group">
                                        <input class="form-control" id="fullName" name="fullName" required
                                          v-model="infos.name" type="text" />
                                      </div>
                                    </h5>
                                    <hr />
                                    <h5 class="media-heading">
                                      Email
                                      <div class="form-group">
                                        <input class="form-control" id="email" name="email" required v-model="infos.email"
                                          type="email" />
                                      </div>
                                    </h5>
                                    <hr />
                                    <h5 class="media-heading">
                                      Téléphone
                                      <div class="form-group">
                                        <input class="form-control" id="phone" name="phone" required v-model="infos.phone"
                                          type="tel" />
                                      </div>
                                    </h5>
                                    <hr />
                                    <h5 class="media-heading">
                                      Profession
                                      <div class="form-group">
                                        <select v-model="infos.profession" required name="role_list" id=""
                                          class="form-control">
                                          <option value="Médecin géneraliste">
                                            Médecin géneraliste</option>
                                          <option value="Specialiste">Specialiste
                                          </option>
                                          <option value="Nutritioniste">
                                            Nutritioniste</option>
                                          <option value="Sage Femme">Sage Femme
                                          </option>
                                          <option value="Aide soignant">Aide
                                            soignant</option>
                                          <option value="Infirmier/ère">
                                            Infirmier/ère</option>
                                          <option value="Receptionniste">
                                            Receptionniste</option>
                                        </select>
                                      </div>
                                    </h5>
                                    <hr />
                                    <h5 class="media-heading">
                                      Pays
                                      <div class="form-group">
                                        <input class="form-control" id="country" name="country" required
                                          v-model="infos.country" type="text" />
                                      </div>
                                    </h5>
                                    <hr />
                                    <h5 class="media-heading">
                                      Ville
                                      <div class="form-group">
                                        <input class="form-control" id="city" name="city" required v-model="infos.city"
                                          type="text" />
                                      </div>
                                    </h5>
                                    <hr />
                                    <h5 class="media-heading">
                                      Addresse
                                      <div class="form-group">
                                        <input class="form-control" id="address" name="address" required
                                          v-model="infos.address" type="text" />
                                      </div>
                                    </h5>
                                    <hr />
                                  </div>

                                  <div class="d-flex justify-content-end my-4">
                                    <button @click.prevent="updateInfos" type="submit" class="btn btn-success">

                                      <span class="fs-5 fs-semibold" v-if="!chargement">
                                        <i class="la la-check-square-o"></i>
                                        Modifier les informations
                                      </span>
                                      <span v-else class="d-flex align-items-center">
                                        <span class="mx-2 fs-semibold text-light">
                                          chargement ...
                                        </span>
                                        <div style="width: 1.5rem; height: 1.5rem" class="spinner-border text-light"
                                          role="status">
                                          <span class="sr-only">Loading...</span>
                                        </div>
                                      </span>
                                    </button>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                      <!-- Antécédents -->
                      <div class="tab-pane" id="linkIconOpt11" role="tabpanel" aria-labelledby="linkIconOpt11-tab1"
                        aria-expanded="false">
                        <div class="row">
                          <div class="col-12">
                            <div class="card">

                              <h5 class="media-heading my-1">
                                Ancien mot de passe
                                <div class="form-group">
                                  <input class="form-control" id="fullName" name="fullName" required
                                    v-model="changePsw.old_password" type="password" />
                                </div>
                              </h5>
                              <hr />
                              <h5 class="media-heading my-1">
                                Nouveau mot de passe
                                <div class="form-group">
                                  <input class="form-control" id="fullName" name="fullName" required
                                    v-model="changePsw.new_password" type="password" />
                                </div>
                              </h5>
                              <hr />
                              <h5 class="media-heading my-1">
                                Confirmer nouveau mot de passe
                                <div class="form-group">
                                  <input class="form-control" id="fullName" name="fullName" required
                                    v-model="changePsw.confirmation_password" type="password" />
                                </div>
                              </h5>
                              <hr />
                              
                              <div class="d-flex justify-content-end my-4">
                                  <button @click.prevent="updatePsw" type="submit" class="btn btn-success">

                                    <span class="fs-5 fs-semibold" v-if="!chargement">
                                      <i class="la la-check-square-o"></i>
                                      Changer mot de passe
                                    </span>
                                    <span v-else class="d-flex align-items-center">
                                      <span class="mx-2 fs-semibold text-light">
                                        chargement ...
                                      </span>
                                      <div style="width: 1.5rem; height: 1.5rem" class="spinner-border text-light"
                                        role="status">
                                        <span class="sr-only">Loading...</span>
                                      </div>
                                    </span>
                                  </button>
                                </div>
                          </div>
                          </div>

                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </section>
          <!-- Tabs with Icons end -->
        </div>
      </div>
    </div>
  </div>
</template>
  
<script setup>
import { ref, reactive, onMounted } from 'vue'
import { useRouter, useRoute } from "vue-router"
import { createToast } from "mosha-vue-toastify";
import VueMultiselect from 'vue-multiselect'
import "mosha-vue-toastify/dist/style.css";
import UserService from "@/services/modules/user.service.js";

const router = useRouter()
const route = useRoute()

const toast = (message, type) => {
	createToast(message, { type: type });
};
const infos = reactive(
  {
    name: '',
    email: '',
    phone: '',
    country: '',
    address: '',
    profession: '',
    city: '',
  }
)

const changePsw = reactive(
  {
    old_password: '',
    new_password: '',
    confirmation_password: '',
  }
)
// const infos = ref([])
const chargement = ref(false)
const userEmail = ref(null)

const getInfo = () => {
  UserService.get().then((data) => {
    infos.name = data.data.name
    infos.email = data.data.email
    infos.phone = data.data.phone
    infos.address = data.data.address
    infos.city = data.data.city
    infos.profession = data.data.profession
    infos.country = data.data.country
    userEmail.value = data.data.email
  }).catch((e) => {
    console.log(e)
  })
}

const updateInfos = function () {
  if (chargement.value == false) {
    chargement.value = true
    UserService.create(infos).then((response) => {
      if (response.data.status == 'error') {
        chargement.value = false
        toast(response.data.message, 'danger')
      }
      else {
        chargement.value = false
        getInfo()
        toast("Modifications éffectuées avec succès !!!", 'success')
      }

    })
  }
}

const updatePsw = function () {
  if (chargement.value == false) {
    chargement.value = true
	  const datas = {}
    datas.email = userEmail.value
    datas.password = changePsw.old_password
    datas.new_password = changePsw.new_password
    datas.new_password_confirmation = changePsw.confirmation_password

    UserService.editPwd(datas).then((response) => {
      if (response.data.status == 'error') {
        chargement.value = false
        toast(response.data.message, 'danger')
      }
      else {
        chargement.value = false
        changePsw.old_password= '',
        changePsw.new_password= '',
        changePsw.confirmation_password= '',
        toast("Modifications éffectuées avec succès !!!", 'success')
      }

    })
  }
}

onMounted(() => {
  getInfo()
})




</script>
<style src="vue-multiselect/dist/vue-multiselect.css"></style>
<style scoped>
.curseur {
  cursor: pointer
}

.customInput {
  border: none;
  border-bottom: 1px solid black;
  outline: none;
  margin: 2px 5px;
}

.supprimer {
  color: red;
  font-size: 16px;
  font-weight: bold
}

.container {
  display: block;
  position: relative;
  padding-left: 35px;
  margin-bottom: 12px;
  cursor: pointer;
  font-size: 15px;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

/* Hide the browser's default checkbox */
.container input {
  position: absolute;
  opacity: 0;
  cursor: pointer;
  height: 0;
  width: 0;
}

/* Create a custom checkbox */
.checkmark {
  position: absolute;
  top: 0;
  left: 0;
  height: 25px;
  width: 25px;
  background-color: #eee;
}

/* On mouse-over, add a grey background color */
.container:hover input~.checkmark {
  background-color: #ccc;
}

/* When the checkbox is checked, add a blue background */
.container input:checked~.checkmark {
  background-color: #2196F3;
}

/* Create the checkmark/indicator (hidden when not checked) */
.checkmark:after {
  content: "";
  position: absolute;
  display: none;
}

/* Show the checkmark when checked */
.container input:checked~.checkmark:after {
  display: block;
}

/* Style the checkmark/indicator */
.container .checkmark:after {
  left: 9px;
  top: 5px;
  width: 5px;
  height: 10px;
  border: solid white;
  border-width: 0 3px 3px 0;
  -webkit-transform: rotate(45deg);
  -ms-transform: rotate(45deg);
  transform: rotate(45deg);
}
</style>
  