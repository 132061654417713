import { httpClient } from "@/plugins/http-client";

export default async function auth ({ next, store }){

    try {
        // console.log("-----------------auth--------------------");
        let rep = await httpClient.get(`dashboard`);
        // console.log(rep);
    } catch (error) {
        // console.log("-----------------auth2--------------------");
        const rep = error.response
        // console.log(rep);
        if (rep.status == 401) {
            localStorage.removeItem('paevcliniqueInfo')
            // return next('/');
        }

        const usersInfo = JSON.parse(localStorage.getItem("paevcliniqueInfo"));
        if (usersInfo == undefined || usersInfo == null) {
            localStorage.removeItem('paevcliniqueInfo')
            return next('/');
        }
    }

    

   // return next('/401-non-autorise');
   return next();
   
}