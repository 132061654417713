<template>
    <div>
        <div class="app-content content" style="padding-top: 5rem;">
            <div class="content-wrapper">
            <div class="content-body">
                <!-- Hospital Info cards -->
                <div class="row">
                    <div class="col-xl-4 col-lg-6 col-md-6 col-12">
                        <div class="card pull-up">
                            <div class="card-content">
                                <div class="card-body">
                                    <div class="media d-flex">
                                        <div class="align-self-center">
                                            <i class="la la-stethoscope font-large-2 warning"></i>
                                        </div>
                                        <div class="media-body text-right">
                                            <h5 class="text-muted text-bold-500">Consultation en attente</h5>
                                            <h3 class="text-bold-600">{{infos.pendding_consultations}}</h3>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="col-xl-4 col-lg-6 col-md-6 col-12">
                        <div class="card pull-up">
                            <div class="card-content">
                                <div class="card-body">
                                    <div class="media d-flex">
                                        <div class="align-self-center">
                                            <i class="la la-calendar-check-o font-large-2 info"></i>
                                        </div>
                                        <div class="media-body text-right">
                                            <h5 class="text-muted text-bold-500">Consultation d'aujourd'hui</h5>
                                            <h3 class="text-bold-600">{{infos.day_consultations}}</h3>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="col-xl-4 col-lg-6 col-md-6 col-12">
                        <div class="card pull-up">
                            <div class="card-content">
                                <div class="card-body">
                                    <div class="media d-flex">
                                        <div class="align-self-center">
                                            <i class="la la-bed font-large-2 danger"></i>
                                        </div>
                                        <div class="media-body text-right">
                                            <h5 class="text-muted text-bold-500">Consultation du mois de </h5>
                                            <h3 class="text-bold-600">{{infos.month_consultations}}</h3>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <!-- Hospital Info cards Ends -->


                <!-- Appointment Table -->
                <div class="row match-height">
                    <div id="recent-appointments" class="col-12 col-md-12">
                        <div >
                            <div class="col-12">
                                <h4 class="card-title">Traitement en cours</h4>

                                <div class="table-responsive">
                                    <table class="table table-bordered table-striped table-hover ">
                                        <thead class="bg-info white">
                                            <tr>
                                                <th>Patients</th>
                                                <th>Diagnostic</th>
                                                <th>Soins programmés</th>
                                                <th>Timings</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            <tr v-for="(protocole, index) in infos.protocoles">
                                                <td class="text-truncate p-1">
                                                    {{protocole.patient.name}}
                                                </td>
                                                <td>{{protocole.consultation.diagnostic}}</td>
                                                <td>
                                                    <p v-for="(admission, index) in protocole.admissions">
                                                        <strong>Soin</strong> {{ admission.soin }} <br>
                                                        <strong>Description</strong> {{ admission.description }}
                                                    </p>
                                                </td>
                                                <td>
                                                    {{protocole.protocole_date}} {{protocole.heure}}
                                                </td>
                                            </tr>
                                        </tbody>
                                    </table>
                                </div>
                                
                            </div>
                        </div>
                    </div>

                    <!-- <div class="col-12 col-md-12">
                        <div class="card">
                            <div class="card-header">
                                <h4 class="card-title">Patients récents</h4>
                            </div>
                            <div class="card-content">
                                <div class="table-responsive">
                                    <table id="recent-orders" class="table table-hover table-xl mb-0">
                                        <tbody>

                                            <tr v-for="(meeting) in infos.last_meetings">
                                                <td class="text-truncate p-1 border-top-0">
                                                </td>
                                                <td class="text-truncate pl-0 border-top-0">
                                                    <div class="name"> <h6>{{meeting.patient.name }}</h6> </div>
                                                    <div class="designation text-light font-small-2"></div>
                                                </td>
                                                <td class="text-right border-top-0">
                                                    <h6>{{meeting.type_consultation}}</h6>
                                                </td>
                                            </tr>
                                        </tbody>
                                    </table>
                                </div>
                            </div>
                        </div>
                    </div> -->
                </div>
                <!-- Appointment Table Ends -->
            </div>
            </div>
        </div>
    </div>
</template>

<script setup>

    import { ref, reactive, onMounted } from 'vue'
    // import { Patients } from "../../api/patient"
    import { useRouter, useRoute } from "vue-router"
    import { createToast } from "mosha-vue-toastify";
    import UserService from "@/services/modules/user.service.js";

    const infos = ref({})

    const getData = async () => {

        UserService.dashboard().then((response) => {
            console.log(response.data)
            infos.value = response.data
        })

        // UserService.editPwd().then((data) => {
        //     // let datas = data.data.data
        //     // current_patient.value = datas.patient
        //     // consultation_id = datas.id

        //     // getQfc()
        //     // getCurrentUser()
        //     // getFiche()
        //     // getMenu()
        //     // getReminds()
        //     // getComposante()
        //     // getConseil()
        // }).catch((e) => {
        //     console.log(e)
        // })
    }


    onMounted(() => {
        getData()
    })
</script>

<style>

</style>