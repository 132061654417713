import { httpClient } from "@/plugins/http-client";

const ApiService = {

  async get(resource, slug = "", element) {
    try {
      let response;

      if (!slug) {
        response = await httpClient.get(`${resource}?${element}`);
      } else {
        response = await httpClient.get(`${resource}/${slug}`);
      }

      return response;

    } catch (error) {
      const rep = error.response;
      if (rep && rep.status === 401) {
        localStorage.removeItem('paevcliniqueInfo');
        // Assuming 'next' is a router function, but it's not defined here
        // return next('/');
      }
      throw error;
    }
  },

  async post(resource, params) {
    try {
      const response = await httpClient.post(`${resource}`, params);
      return response;
    } catch (error) {
      throw error;
    }
  },

  async update(resource, slug, params) {
    try {
      const response = await httpClient.put(`${resource}/${slug}`, params);
      return response;
    } catch (error) {
      throw error;
    }
  },

  async put(resource, params) {
    try {
      const response = await httpClient.put(`${resource}`, params);
      return response;
    } catch (error) {
      throw error;
    }
  },

  async delete(resource) {
    try {
      const response = await httpClient.delete(resource);
      return response;
    } catch (error) {
      throw error;
    }
  }
};

export default ApiService;
