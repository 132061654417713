
import ApiService from '@/services/configs/api.service'

const UserService = {
  get(slug,element) {
    return ApiService.get(`user`, slug,element);
  },
 
  create(params) {
    return ApiService.post("my_account", params);
  },

  editPwd(params) {
    return ApiService.post("modify-password", params);
  },

  dashboard() {
    return ApiService.get("dashboard");
  },
 
};

  export default UserService;
  