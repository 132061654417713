<template>
	<div>
		<div class="modal animated bounce text-left" id="supFiche" tabindex="-1" role="dialog"
			aria-labelledby="myModalLabel36" aria-hidden="true">
			<div class="modal-dialog" role="document">
				<div class="modal-content">
					<div class="modal-header">
						<h4 class="modal-title text-danger" id="myModalLabel36">
							<span class="alert-icon text-danger"><i class="la la-warning"></i></span>
							Confirmation de la suppression
						</h4>
						<button type="button" class="close" data-dismiss="modal" aria-label="Close">
							<span aria-hidden="true">&times;</span>
						</button>
					</div>
					<div class="modal-body">
						<h5 class="text-danger text-center">Attention! Cette action est irresvocable, êtes vous sur de
							vouloir
							continuer ?</h5>

						<p>.</p>
					</div>
					<div class="modal-footer">
						<button type="button" class="btn grey btn-outline-primary" data-dismiss="modal">Annuler</button>
						<button type="button"  @click.prevent="deleteFiche" data-dismiss="modal"
							class="btn btn-outline-danger">Supprimer</button>
					</div>
				</div>
			</div>
		</div>

		<div class="modal animated bounce text-left" id="resultat" tabindex="-1" role="dialog"
			aria-labelledby="myModalLabel36" aria-hidden="true">
			<div class="modal-dialog" role="document">
				<div class="modal-content" v-if="ficheStresseur">
					<div class="modal-header">
						<h4 class="modal-title " id="myModalLabel36">
							Resultat analyse
						</h4>
						<button type="button" class="close" data-dismiss="modal" aria-label="Close">
							<span aria-hidden="true">&times;</span>
						</button>
					</div>
					<div class="modal-body">
						<div>
							<ul>
								<li>SCORE D’EVALUATION ELEMENT STRESSEUR: <b>{{ficheStresseur.total_elm_stress}}</b></li>
								<li>INTERPRETATION: <b>{{ficheStresseur.appreciation_elm_stresseur}}</b></li>
							</ul>
						</div>
							<hr />
						<div>
							<ul>
								<li>SCORE D’EVALUATION NIVEAU STRESSEUR: <b>{{ficheStresseur.total_niveau_stress}}</b></li>
								<li>INTERPRETATION: <b>{{ficheStresseur.appreciation_niveau_stress}}</b></li>
							</ul>
						</div>
							<hr />
						<div>
							<h4>DIAGNOSTIC</h4>
							<p>{{ ficheStresseur.diagnostic }}</p>
						</div>
						<!-- <div v-else>
							<p class="text-danger">Veuillez remplir la fiche d'evaluation et reesayer !!!!</p>
						</div> -->
					</div>
				</div>
			</div>
		</div>

		<div class="modal animated slideInDown text-left" id="finish_consultation" tabindex="-1"
      role="dialog" aria-labelledby="myModalLabel77" style="display: none"
      aria-hidden="true">
      <div class="modal-dialog" role="document">
        <div class="modal-content">
          <div class="modal-header">
            <h4 class="modal-title" id="myModalLabel77">
              Confimation de fin de consultation
            </h4>
            <button type="button" class="close" data-dismiss="modal" aria-label="Close">
              <span aria-hidden="true">×</span>
            </button>
          </div>
          <div class="modal-body">
            <h5></h5>

            <div class="input-group">
              <div class="d-inline-block custom-control custom-checkbox mr-1">
                <input type="checkbox" name="customer1" class="custom-control-input"
                  checked="" id="yes" />
                <label class="custom-control-label mr-2" for="yes">
                </label>

                Je confirme avoir pris le
                patient en charge et j'aimerais
                déclaré cette consultation
                comme terminée.
              </div>
            </div>
          </div>
          <div class="modal-footer">
            <button type="button" class="btn grey btn-outline-secondary"
              data-dismiss="modal">
              Fermer
            </button>
            <button type="button" data-dismiss="modal" class="btn btn-outline-success" @click.prevent="finishConsultation">
              Valider
            </button>
          </div>
        </div>
      </div>
    </div>
		
		<delete-confirmation-modal :modalId="'delete_usual_menu'" :deleteFunction="deleteUsualMenuItems"/>
		<delete-confirmation-modal :modalId="'delete_rewind'" :deleteFunction="deleteRemindItems"/>
		<delete-confirmation-modal :modalId="'delete_composant'" :deleteFunction="deleteComponent"/>
		<delete-confirmation-modal :modalId="'delete_qfc'" :deleteFunction="deleteQfc"/>

		<div class="app-content content">
			<div class="content-overlay"></div>
			<div class="content-wrapper">
				<div class="content-header row">
					<div class="content-header-left col-md-6 col-12 mb-2">
						<h4 class="card-title text-uppercase" id="from-actions-top-bottom-center">
							<strong style="text-decoration: underline;">
								Dossier Nutritionnel
							</strong>
						</h4>
					</div>
					<div class="content-header-right col-md-6 col-12">
						<div class="btn-group float-md-right" role="group" aria-label="Button group with nested dropdown">
							<router-link to="/admin/nutrition"  class="btn btn-info round  box-shadow-2 px-2 mb-1">
								<i class="ft-arrow-left  icon-left"></i>
								Retour au patient
							</router-link>
						</div>
						<div class="btn-group float-md-right mr-1" role="group" aria-label="Button group with nested dropdown">
							<!-- <router-link @click.prevent="" to="#"  class="btn btn-danger round  box-shadow-2 px-2 mb-1">
								Cloturer le dossier
							</router-link> -->
							<button type="button" class="btn mb-1 btn-danger btn-icon btn-block round"
								data-toggle="modal" data-target="#finish_consultation">
								<i class="la la-trash"></i>
								Terminer la consultation
							</button>
						</div>
					</div>
				</div>
				<div class="content-body">
					<!-- Tabs with Icons start -->
					<section id="tabs-with-icons">
						<div class="row">
							<div class="col-lg-6 col-md-12">
								<div class="card" style="height: 160.125px;">
									<div class="card-body">
										<div class="row">
											<div class="col-lg-12 d-flex justify-content-around" style="overflow: auto; height:9em;">
												<div class="media-body"  >
															<h5 class="media-heading">
																<strong>Nom & prénom : </strong>
																<span class="float-right content-header-title">
																	<strong class="text-uppercase">{{ current_patient.name }}</strong>
																</span>
															</h5>
															<hr/>
															<h5 class="media-heading">
																<strong>Sexe : </strong>
																<span class="float-right content-header-title">{{current_patient.sex}}</span>
															</h5>
															<hr/>
															<h5 class="media-heading">
																<strong>AGE:</strong>
																<span class="float-right content-header-title">{{current_patient.age}} ans </span>
															</h5>
															<hr />
															<h5 class="media-heading">
																<strong>Profession:</strong>
																<span class="float-right content-header-title">{{current_patient.profession}}</span>
															</h5>
															<hr />
															<h5 class="media-heading">
																<strong>Situation Matrimoniale:</strong>
																<span class="float-right content-header-title">{{current_patient.marital_status}}</span>
															</h5>
															
															<hr />
															<h5 class="media-heading">
																<strong>Télephone:</strong>
																<span class="float-right content-header-title">{{current_patient.phone}}</span>
															</h5>
															<hr />
															<h5 class="media-heading">
																<strong>Email:</strong>
																<span class="float-right content-header-title">{{current_patient.email}}</span>
															</h5>

															<hr />
															<h5 class="media-heading">
																<strong>Personne à contacter en cas d'urgence:</strong>
																<span class="float-right content-header-title"></span>
															</h5>
														</div>
													
											</div>
										</div>
									</div>
								</div>
							</div>
							<div class="col-lg-3 col-md-6">
								<div class="card bg-gradient-y-info" style="height: 160.125px;">
									<div class="card-body" style="overflow: auto; height:3em; color:#fff;">
										<h5 class="media-heading" style="color:#fff;">
											GSRH:
											<span class="float-right content-header-title text-white">B+</span>
										</h5>
										<h5 class="media-heading" style="color:#fff;">
											Electrophorèse de l'hb:
											<span class="float-right content-header-title text-white">AC</span>
										</h5>
										<h5 class="media-heading" style="color:#fff;">
											G6PD
											<span class="float-right content-header-title text-white">Null</span>
										</h5>

										<h5 class="media-heading" style="color:#fff;">
											TCK
											<span class="float-right content-header-title text-white">Null</span>
										</h5>
										<h5 class="media-heading" style="color:#fff;">
											TPINR
											<span class="float-right content-header-title text-white">Null</span>
										</h5>
										<h5 class="media-heading" style="color:#fff;">
											BIA
											<span class="float-right content-header-title text-white">Null</span>
										</h5>
										<h5 class="media-heading" style="color:#fff;">
											CPK
											<span class="float-right content-header-title text-white">Null</span>
										</h5>
									</div>
								</div>
							</div>
							<div class="col-lg-3 col-md-6">
								<div class="card bg-gradient-y-warning" style="height: 160.125px;">
									<div class="card-header">
										<h5 class="card-title text-white">Contrôles récents</h5>
									</div>
									<div class="card-content mx-2">
										<ul class="list-unstyled text-white">
											<li></li>
										</ul>
									</div>
								</div>
							</div>
						</div>
							
						<div class="row match-height">

							<div class="col-xl-12 col-lg-12 ">
								<div>
									
									<div class="card-content">
										<div class="card-body">
											<!-- MENU DE NAVIGATION -->
											<ul class="nav nav-tabs nav-underline nav-justified">
												<li class="nav-item">
													<a class="nav-link" id="linkIcon13-tab1" data-toggle="tab"
														href="#linkIcon13" aria-controls="linkIcon13" aria-expanded="false">
														<i class="ft-male"></i>
														<strong class="text-info float-left">BIO IMPÉDANCEMÉTRIE </strong></a>
												</li>
												

												<li class="nav-item">
													<a class="nav-link" id="linkIconOpt11-tab1" data-toggle="tab"
														href="#linkIconOpt14" aria-controls="linkIconOpt11">
														<strong class="text-info"> QFC</strong></a>
												</li>

												<li class="nav-item">
													<a class="nav-link" id="linkIcon12-tab1" data-toggle="tab"
														href="#linkIcon12" aria-controls="linkIcon12" aria-expanded="false">
														
														<strong class="text-info "> COMPOSANTES NUTRITIONNELLES </strong></a>
												</li>
												<li class="nav-item">
													<a class="nav-link" id="linkIconOpt11-tab1" data-toggle="tab"
														href="#linkIconOpt11" aria-controls="linkIconOpt11">
														
														<strong class="text-info float-left">MENU HABITUEL</strong></a>
												</li>
												<li class="nav-item">
													<a class="nav-link" id="linkIconOpt11-tab1" data-toggle="tab"
														href="#linkIconOpt15" aria-controls="linkIconOpt11">
														<strong class="text-info float-left">RAPPEL DES 24H</strong></a>
												</li>
												<li class="nav-item">
													<a class="nav-link" id="linkIconOpt11-tab1" data-toggle="tab" href="#tab-conseil"
													aria-controls="linkIconOpt11">
													<strong class="text-info"> CONSEIL </strong></a>
												</li>

												<li class="nav-item ">
													<a class="nav-link active" id="activeIcon12-tab1" data-toggle="tab"
														href="#activeIcon12" aria-controls="activeIcon12"
														aria-expanded="true">
														
														<strong class="text-info float-right">FICHE D'EVALUATION</strong></a>
												</li>
											</ul>

											<div class="tab-content px-1 pt-1">

												<!-- TABLEAU DE LA COMPOSITION DU CORPS -->
												<div class="tab-pane" id="linkIcon13" role="tabpanel" aria-labelledby="linkIcon13-tab1" aria-expanded="false">

													<div class="content-header row mb-2 mt-1">
														<div class="content-header-left col-md-6">
															<h4 class="card-title"><strong>Tableau de la composition du corps</strong></h4>
														</div>
														<div class="d-flex justify-content-end col-md-6">
															<button type="button" class="btn btn-outline-info btn-xs" data-toggle="modal" data-target="#defaultSize">
																Modifier 
															</button>
														</div>
													</div>

													<div class="modal fade text-left" id="defaultSize" tabindex="-1" role="dialog" aria-labelledby="myModalLabel17" style="display: none;" aria-hidden="true">
                                                        <div class="modal-dialog modal-lg" role="document">
                                                            <div class="modal-content">
                                                                <div class="modal-header">
                                                                    <h4 class="modal-title" id="myModalLabel17">COMPOSITION DU CORPS</h4>
                                                                    <button id="close_composition" type="button" class="close" data-dismiss="modal" aria-label="Close">
                                                                        <span aria-hidden="true">×</span>
                                                                    </button>
                                                                </div>
                                                                <div class="modal-body" style="overflow: auto; height:500px;">                                     
																	<div class="row">
																		<div class="col-md-12">																		
																			<div class="" style="height: 600.58px; ">
																				<form class="form">
																					<div class="form-body">
																						<div class="row">
																							<div class="form-group col-md-3 mb-2">
																								<label for="userinput1">GRAISSE %</label>
																								<input v-model="current_composition.graisse_percent" type="number" id="userinput1" class="form-control border-primary" placeholder="">
																							</div>
																							<div class="form-group col-md-3 mb-2">
																								<label for="userinput2">POIDS GRASSE</label>
																								<input type="number" v-model="current_composition.poids_grasse" class="form-control border-primary" >
																							</div>
																						
																							<div class="form-group col-md-3 mb-2">
																								<label for="userinput3">MAIGREUR % </label>
																								<input type="number" v-model="current_composition.maigreur_percent" class="form-control border-primary">
																							</div>
																							<div class="form-group col-md-3 mb-2">
																								<label for="userinput4">POIDS MAIGRE</label>
																								<input type="number" v-model="current_composition.poids_maigre" class="form-control border-primary">
																							</div>


																							<div class="form-group col-md-3 mb-2">
																								<label for="userinput1">POIDS SEC MAIGRE </label>
																								<input type="number" v-model="current_composition.poids_sec_maigre" class="form-control border-primary">
																							</div>
																							<div class="form-group col-md-3 mb-2">
																								<label for="userinput2">POIDS TOTAL</label>
																								<input type="number" v-model="current_composition.poids_total" class="form-control border-primary">
																							</div>
																						
																							
																							<div class="form-group col-md-3 mb-2">
																								<label for="userinput4">BMR/WEIGHT</label>
																								<input type="number" v-model="current_composition.bmr_weight" class="form-control border-primary">
																							</div>

																							<div class="form-group col-md-3 mb-2">
																								<label for="userinput1">L'EAU DU 3ème ESPACE </label>
																								<input type="number" v-model="current_composition.eau_du_3eme_espace" class="form-control border-primary">
																							</div>
																							<div class="form-group col-md-3 mb-2">
																								<label for="userinput2">IMPEDANCE 5KHZ </label>
																								<input type="number" v-model="current_composition.impedance_5khz" class="form-control border-primary">
																							</div>
																						
																							<div class="form-group col-md-3 mb-2">
																								<label for="userinput3">IMPEDANCE 50KHZ</label>
																								<input type="number" v-model="current_composition.impedance_50khz" class="form-control border-primary">
																							</div>
																							<div class="form-group col-md-3 mb-2">
																								<label for="userinput4">IMPEDANCE 100KHZ</label>
																								<input type="number" v-model="current_composition.impedance_100khz" class="form-control border-primary">
																							</div>

																							<div class="form-group col-md-3 mb-2">
																								<label for="userinput4">IMPEDANCE 200KHZ</label>
																								<input type="number" v-model="current_composition.impedance_200khz" class="form-control border-primary">
																							</div>
																							<div class="form-group col-md-3 mb-2">
																								<label for="userinput4">% TBW</label>
																								<input type="number" v-model="current_composition.tbw_percent" class="form-control border-primary">
																							</div>
																							<div class="form-group col-md-3 mb-2">
																								<label for="userinput4">TBW</label>
																								<input type="number" v-model="current_composition.tbw" class="form-control border-primary">
																							</div>
																							
																							<div class="form-group col-md-3 mb-2">
																								<label for="userinput4">ECW %</label>
																								<input type="number" v-model="current_composition.ecw_percent" class="form-control border-primary">
																							</div>
																							<div class="form-group col-md-3 mb-2">
																								<label for="userinput4">ECW </label>
																								<input type="number" v-model="current_composition.ecw" class="form-control border-primary">
																							</div>
																							<div class="form-group col-md-3 mb-2">
																								<label for="userinput4">ICW %</label>
																								<input type="number" v-model="current_composition.icw_percent" class="form-control border-primary">
																							</div>
																							<div class="form-group col-md-3 mb-2">
																								<label for="userinput4">ICW </label>
																								<input type="number" v-model="current_composition.icw" class="form-control border-primary">
																							</div>
																							<div class="form-group col-md-3 mb-2">
																								<label for="userinput4">RATIO TAILLE/HANCHE</label>
																								<input type="number" v-model="current_composition.ratio_taille_hanche" class="form-control border-primary">
																							</div>
																							<div class="form-group col-md-3 mb-2">
																								<label for="userinput4">BASAL.MET.RATE </label>
																								<input type="number" v-model="current_composition.basal_met_rate" class="form-control border-primary">
																							</div>
																							<div class="form-group col-md-3 mb-2">
																								<label for="userinput4">ESTIMATE .AVERAGE REQ.</label>
																								<input type="number" v-model="current_composition.estimate_average_req" class="form-control border-primary">
																							</div>
																							<div class="form-group col-md-3 mb-2">
																								<label for="userinput4">IMC</label>
																								<input type="number" v-model="current_composition.imc" class="form-control border-primary">
																							</div>

																							<div class="form-group col-md-3 mb-2">
																								<label for="userinput4">BFMI</label>
																								<input type="number" v-model="current_composition.bfmi" class="form-control border-primary">
																							</div>
																							
																							<div class="form-group col-md-3 mb-2">
																								<label for="userinput4">FFMI</label>
																								<input type="number" v-model="current_composition.ffmi" class="form-control border-primary">
																							</div>

																							<div class="form-group col-md-3 mb-2">
																								<label for="userinput4">INDICE NUTRITIONNEL</label>
																								<input type="number" v-model="current_composition.indice_nutritionnel" class="form-control border-primary">
																							</div>

																							<div class="form-group col-md-4 mb-2">
																								<label for="userinput3">MASSE CELLULAIRE DU CORPS</label>
																								<input type="number" v-model="current_composition.masse_cellulaire_du_corps" class="form-control border-primary">
																							</div>
																						</div>
																					</div>
																				</form>
																			</div>
																		</div>
																	</div>
                                                                   
                                                                </div>
                                                                <div class="modal-footer">
                                                                    <button type="button" class="btn grey btn-outline-danger" data-dismiss="modal">Fermer</button>
                                                                    <button type="button" @click.prevent="saveComposition()" class="btn btn-outline-info">Sauvegarder</button>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>

													<div class="row">
														<div class="col-lg-6 col-md-6">
															<div class="card" style="height: 680.7px;">

																<div class="card card-content collapse show">

																	<div class="card-body">
																		<!-- <span class="badge badge-info float-right">{{ current_patient.address }}</span> -->

																		<div class="media-body">
																			<h5 class="media-heading">
																				GRAISSE % :
																				<span class="badge badge-info float-right">
																					{{current_composition.graisse_percent}}
																				</span>

																			</h5>
																			<hr>
																			<!-- <h5 class="media-heading">
																				Nationalité
																				<span class="badge badge-info float-right">Béninoise</span>
																			</h5>
																			<hr> -->
																			<h5 class="media-heading">
																				POIDS GRASSE 
																				<span class="badge badge-info float-right">
																					{{current_composition.poids_grasse}}
																				</span>
																			</h5>
																			<hr>
																		</div>
																		<div class="media-body">
																			<h5 class="media-heading">
																				MAIGREUR % 
																				<span class="badge badge-info float-right">
																					{{current_composition.maigreur_percent}}
																				</span>

																			</h5>
																			<hr>

																			<h5 class="media-heading">
																				POIDS MAIGRE
																				<span class="badge badge-info float-right">
																					{{current_composition.poids_maigre}}
																				</span>
																			</h5>
																			<hr>
																			<h5 class="media-heading">
																				POIDS SEC MAIGRE
																				<span class="badge badge-info float-right">
																					{{current_composition.poids_sec_maigre}}
																				</span>
																			</h5>
																			<hr>
																			<h5 class="media-heading">
																				POIDS TOTAL
																				<span class="badge badge-info float-right">
																					{{current_composition.poids_total}}
																				</span>
																			</h5>
																			<hr>
																			<h5 class="media-heading">
																				MASSE CELLULAIRE DU CORPS
																				<span class="badge badge-info float-right">
																					{{current_composition.masse_cellulaire_du_corps}}
																				</span>
																			</h5>
																			<hr>
																			<h5 class="media-heading">
																				BMR/WEIGHT
																				<span class="badge badge-info float-right">
																					{{current_composition.bmr_weight}}
																				</span>
																			</h5>
																			<hr>
																			
																			<h5 class="media-heading">
																				L'EAU DU 3ème ESPACE
																				<span class="badge badge-info float-right">
																					{{current_composition.eau_du_3eme_espace}}
																				</span>
																			</h5>
																			<hr>
																			<h5 class="media-heading">
																				IMPEDANCE 5KHZ
																				<span class="badge badge-info float-right">
																					{{current_composition.impedance_5khz}}
																				</span>
																			</h5>

																			<hr>
																			<h5 class="media-heading">
																				IMPEDANCE 50KHZ
																				<span class="badge badge-info float-right">
																					{{current_composition.impedance_50khz}}
																				</span>
																			</h5>
																			<hr>
																			<h5 class="media-heading">
																				IMPEDANCE 100KHZ
																				<span class="badge badge-info float-right">
																					{{current_composition.impedance_100khz}}
																				</span>
																			</h5>
																			<hr>
																			<h5 class="media-heading">
																				IMPEDANCE 200KHZ
																				<span class="badge badge-info float-right">
																					{{current_composition.impedance_200khz}}
																				</span>
																			</h5>
																		</div>
																	</div>
																</div>
															</div>
														</div>
														<div class="col-lg-6 col-md-6">
															<div class="card" style="height: 600.7px;">

																<div class="card card-content collapse show">

																	<div class="card-body">
																		<!-- <span class="badge badge-info float-right">{{ current_patient.address }}</span> -->
																		<div class="media-body">
																			
																			<h5 class="media-heading">
																				% TBW 
																				<span class="badge badge-info float-right">
																					{{current_composition.tbw_percent}}
																				</span>
																			</h5>
																			<hr>
																			<h5 class="media-heading">
																				TBW 
																				<span class="badge badge-info float-right">
																					{{current_composition.tbw}}
																				</span>
																			</h5>
																			<hr>
																			<h5 class="media-heading">
																				RATIO TAILLE/HANCHE 
																				<span class="badge badge-info float-right">
																					{{current_composition.ratio_taille_hanche}}
																				</span>
																			</h5>
																			<hr>
																			<h5 class="media-heading">
																				ECW %
																				<span class="badge badge-info float-right">
																					{{current_composition.ecw_percent}}
																				</span>
																			</h5>
																			<hr>
																			<h5 class="media-heading">
																				ECW 
																				<span class="badge badge-info float-right">
																					{{current_composition.ecw}}
																				</span>
																			</h5>
																			<hr>
																			<h5 class="media-heading">
																				ICW % 
																				<span class="badge badge-info float-right">
																					{{current_composition.icw_percent}}
																				</span>
																			</h5>
																			<hr>
																			<h5 class="media-heading">
																				ICW 
																				<span class="badge badge-info float-right">
																					{{current_composition.icw}}
																				</span>
																			</h5>
																			<hr>
																			<h5 class="media-heading">
																				BASAL.MET.RATE
																				<span class="badge badge-info float-right">
																					{{current_composition.basal_met_rate}}
																				</span>
																			</h5>
																			<hr>
																			<h5 class="media-heading">
																				ESTIMATE .AVERAGE REQ.
																				<span class="badge badge-info float-right">
																					{{current_composition.estimate_average_req}}
																				</span>
																			</h5>
																			<hr>
																			<h5 class="media-heading">
																				IMC 
																				<span class="badge badge-info float-right">
																					{{current_composition.imc}}
																				</span>
																			</h5>
																			<hr>
																			<h5 class="media-heading">
																				BFMI 
																				<span class="badge badge-info float-right">
																					{{current_composition.bfmi}}
																				</span>
																			</h5>
																			<hr>
																			<h5 class="media-heading">
																				FFMI 
																				<span class="badge badge-info float-right">
																					{{current_composition.ffmi}}
																				</span>
																			</h5>
																			
																			<hr>
																			<h5 class="media-heading">
																				INDICE NUTRITIONNEL 
																				<span class="badge badge-info float-right">
																					{{current_composition.indice_nutritionnel}}
																				</span>
																			</h5>
																		</div>
																	</div>
																</div>
															</div>
														</div>
													</div>
												</div>

												<!-- MENU HABITUEL -->
												<div class="tab-pane" id="linkIconOpt11" role="tabpanel"
													aria-labelledby="linkIconOpt11-tab1" aria-expanded="false">
													<div class="container">
														<div class="row">
															<div class="col-12">
																<div class="card">
																	<div class="card-body">
																		<form class="form">
																			<div class="form-body">
																				<div class="content-header row mt-1">
																					<div class="content-header-left col-md-6">
																						<h4 class="card-title"><strong>MENU HABITUEL</strong></h4>
																					</div>
																					<div class="d-flex justify-content-end col-md-6 ">
																						<div class="form-group">
																							<button type="button"
																								@click="openMenuModal"
																								class="btn btn-outline-info block btn-xs"
																								data-toggle="modal"
																								data-target="#modal_usual_menu">
																								Ajouter
																							</button>

																							<!-- Modal -->
																							<div class="modal fade text-left"
																								id="modal_usual_menu" tabindex="-1"
																								role="dialog"
																								aria-labelledby="myModalLabel16"
																								aria-hidden="true">
																								<div class="modal-dialog modal-xl"
																									role="document">
																									<div class="modal-content" style="overflow: auto; height:500px">
																										<div class="modal-header">
																											<h4 class="modal-title" id="myModalLabel16">Formulaire du menu habituel</h4>
																											<button
																												type="button" id="close_usual_manu"
																												class="close"
																												data-dismiss="modal"
																												aria-label="Close">
																												<span
																													aria-hidden="true">&times;</span>
																											</button>
																										</div>
																										<div class="modal-body">
																											<form>
																													<div class="row">
																														<div class="col-lg-6 offset-lg-3">
																															<label for="Aliment">NOM DU MENU</label>
																															<input
																																v-model="menuHabituel.name"
																																type="text"
																																class="form-control"
																																placeholder="Menu complet">
																														</div>
																														<div class="col-lg-2">
																															<label for="type_ratio">
																																TYPE DE RATIO
																															</label>
																															<select	name=""	v-model="menuHabituel.type_ratio" id="" class="form-control">
																																<option	value="Petit dejeuner">Petit déjeuner</option>
																																<option value="Dejeuner">Déjeuner</option>
																																<option value="Collation">Collation</option>
																																<option value="Souper">Souper</option>
																																<option value="Diner"> Diner</option>
																															</select>
																														</div>

																														<div class="col-lg-2">
																															<label
																																for="lieu">Lieu</label>
																															<input
																																v-model="menuHabituel.lieu"
																																type="text"
																																class="form-control">
																														</div>

																														<div class="col-lg-2">
																															<label
																																for="heure">L'heure</label>
																															<input
																																v-model="menuHabituel.heure"
																																type="time"
																																class="form-control">
																														</div>

																														<div class="col-lg-2">
																															<label
																																for="Description">Description</label>
																															<textarea
																																v-model="menuHabituel.description"
																																name="description"
																																id=""
																																class="form-control"
																																rows="1"></textarea>
																														</div>
																													</div>
																									
																												<div  v-for="(data, index) in menuHabituel.details ">

																													<div class="row mt-2">
																														<div class="col-lg-3">
																															<label for="Aliment">ALIMENT</label>
																															<input
																																v-model="data.aliment"
																																type="text"
																																class="form-control"
																																placeholder="Aliment">
																														</div> 
																														<div class="col-lg-1">
																															<label for="Aliment">VOL./POIDS</label>
																															<input
																																v-model="data.poids_vol"
																																type="number"
																																class="form-control"
																																placeholder="Quantité">
																														</div>

																													</div>

																													<div
																														class="d-flex justify-content-end my-2">
																														<button
																															type="button"
																															class="btn btn-danger"
																															@click="deleteMenu(index)">x</button>
																													</div>

																													<hr>


																												</div>
																												<div>
																													<button
																														type="button"
																														@click="addMenu"
																														class="btn btn-info">+
																														Ajouter
																														nouveau</button>
																												</div>
																											</form>
																										</div>
																										<div
																											class="modal-footer">
																											<button
																												type="button"
																												class="btn grey btn-outline-danger"
																												data-dismiss="modal">Fermer</button>
																											<button
																												v-if="!isUpdateMenu"
																												@click="saveUsualMenu"
																												type="button"
																												data-dismiss="modal"
																												class="btn btn-outline-info">
																												<span
																													class="fs-5 fs-semibold"
																													v-if="!chargement">
																													Enregistrer
																												</span>
																												<span v-else
																													class="d-flex justify-content-center align-items-center">
																													<span
																														class="mx-2 fs-semibold text-light">
																														chargement
																														...
																													</span>
																													<div style="width: 1.5rem; height: 1.5rem"
																														class="spinner-border text-light"
																														role="status">
																														<span
																															class="sr-only">Loading...</span>
																													</div>
																												</span>

																											</button>
																											<button v-else
																												@click="updateUsualMenu"
																												type="button"
																												data-dismiss="modal"
																												class="btn btn-outline-primary">

																												<span
																													class="fs-5 fs-semibold"
																													v-if="!chargement">
																													Modifier
																												</span>
																												<span v-else
																													class="d-flex justify-content-center align-items-center">
																													<span
																														class="mx-2 fs-semibold text-light">
																														chargement
																														...
																													</span>
																													<div style="width: 1.5rem; height: 1.5rem"
																														class="spinner-border text-light"
																														role="status">
																														<span
																															class="sr-only">Loading...</span>
																													</div>
																												</span>
																											</button>
																										</div>
																									</div>
																								</div>
																							</div>
																						</div>
																					</div>
																				</div>
																			</div>
																		</form>
																	</div>

																	<div class="table-responsive">
																		<table class="table table-bordered table-striped table-hover ">
																			<thead class="bg-info white">
																				<tr>
																					<th class="text-left" colspan="2">NOM</th>
																					<th class="text-left">TYPE DE RATIO</th>
																					<th colspan="2" class="text-center">LIEU ET L'HEURE</th>
																					<th colspan="3" class="text-center">DESCRIPTION </th>
																					<th class="text-center" >ACTIONS</th>
																				</tr>
																			</thead>
																			<tbody>
																				<tr v-for="(menu, index) in menus" :key="index">
																					<td class="text-left" colspan="2"><div v-html="mapDetails(menu.details)"></div></td>
																					<td>{{menu.type_ratio}}</td>
																					<td colspan="2">{{menu.lieu}} {{menu.heure}}</td>
																					<td colspan="3">{{menu.description}}</td>
																					<td>
																						<div class="d-flex ">
																							<button title="modifer un composant"
																								type="button"
																								data-toggle="modal"
																								data-target="#modal_usual_menu"
																								@click.prevent="modifierMenu(index, menu)"
																								class="btn btn-primary mx-1 round btn-sm waves-effect waves-light">
																								<i class="fas fa-edit"></i>
																							</button>
																							<button data-toggle="modal"
																								data-target="#delete_usual_menu"
																								title="supprimer un composant"
																								@click.prevent="deleteUsualMenu(index, menu)"
																								class=" mx-2 btn btn-danger  round btn-sm waves-effect waves-light">
																								<i class="fas fa-trash"></i>
																							</button>
																						</div>
																					</td>
																				</tr>

																			</tbody>
																		</table>
																	</div>

																</div>
															</div>
														</div>
													</div>
												</div>
												
												<!-- FREQUENCE DE CONSOMMATION DES ALIMENTS -->
												<div class="tab-pane" id="linkIconOpt14" role="tabpanel"
													aria-labelledby="linkIconOpt11-tab1" aria-expanded="false">
													<div class="row">
														<div class="col-12">
															<div class="card">
																<div class="card-body">
																	<form class="form">
																		<div class="form-body">
																			<div class="content-header row mt-1">
																				<div class="content-header-left col-md-6"> 
																					<h4 class="card-title"><strong>FREQUENCE DE CONSOMMATION DES ALIMENTS</strong></h4>
																				</div>
																				<div class="d-flex justify-content-end col-md-6">
																					<div class="form-group">

																						<button type="button" class="btn btn-outline-info block btn-xs"
																							data-toggle="modal"
																							data-target="#modal_qfc">
																							Ajouter
																						</button>

																						<!-- Modal -->
																						<div class="modal fade text-left" id="modal_qfc"
																							tabindex="-1" role="dialog"
																							aria-labelledby="myModalLabel17"
																							aria-hidden="true">
																							<div class="modal-dialog modal-lg"
																								role="document">
																								<div class="modal-content">
																									<div class="modal-header">
																										<h4 class="modal-title" id="myModalLabel17">
																											FREQUENCE DE CONSOMMATION DES ALIMENTS
																										</h4>
																										<button type="button" id="close_qfc"
																											class="close"
																											data-dismiss="modal"
																											aria-label="Close">
																											<span aria-hidden="true">&times;</span>
																										</button>
																									</div>
																									<div class="modal-body">
																										<form >
																											<div class="row">
																												<div class="col-md-6">
																													<fieldset
																														class="form-group floating-label-form-group">
																														<label for="composant">Sélectionner le groupe d'aliment
																														</label>

																														<VueMultiselect
																															v-model="current_qfc.aliment_group"
																															:selectLabel="'Appuyez sur Entrée pour sélectionner'"
																															:deselectLabel="'Appuyez sur Entrée pour supprimer'"
																															selectedLabel="Selectionné"
																															tag-placeholder="Rechercher un composant"
																															placeholder="Rechercher un composant"
																															:options="Object.keys(aliments)">
																														</VueMultiselect>
																													</fieldset>
																												</div>
																												<div class="col-md-6">
																													<fieldset
																														class="form-group floating-label-form-group">
																														<label for="composant">Sélectionner l'aliment
																														</label>

																														<VueMultiselect
																															v-model="current_qfc.aliment"
																															:selectLabel="'Appuyez sur Entrée pour sélectionner'"
																															:deselectLabel="'Appuyez sur Entrée pour supprimer'"
																															selectedLabel="Selectionné"
																															tag-placeholder="Rechercher un composant"
																															placeholder="Rechercher un composant"
																															:options="aliments[current_qfc.aliment_group]">
																														</VueMultiselect>
																													</fieldset>
																												</div>
																											
																												<div class="col-lg-4">
																													<label for="type_ratio">TYPE DE RATIO</label>
																													<select	name=""	v-model="current_qfc.type_ration" id="" class="form-control">
																														<option	value="Petit dejeuner">Petit déjeuner</option>
																														<option value="Dejeuner">Déjeuner</option>
																														<option value="Collation">Collation</option>
																														<option value="Souper">Souper</option>
																														<option value="Diner"> Diner</option>
																													</select>
																												</div>
																												<div class="col-lg-4">
																													<label for="resultat">Lieu</label>
																													<input
																														v-model="current_qfc.lieu"
																														type="text"
																														class="form-control">
																												</div>
																												<div class="col-lg-4">
																													<label for="resultat">Heure</label>
																													<input
																														v-model="current_qfc.heure"
																														type="time"
																														class="form-control">
																												</div>
																												<div class="col-lg-4">
																													<label for="resultat">Nbr / jours</label>
																													<input
																														v-model="current_qfc.freq_jours"
																														type="number"
																														class="form-control">
																												</div>
																												<div class="col-lg-4">
																													<label for="resultat">Nbr / sem</label>
																													<input
																														v-model="current_qfc.freq_sem"
																														type="number"
																														class="form-control">
																												</div>
																												<div class="col-lg-4">
																													<label for="resultat">Nbr / mois</label>
																													<input
																														v-model="current_qfc.freq_mois"
																														type="number"
																														class="form-control">
																												</div>
																											</div>
																										</form>
																									</div>
																									<div
																										class="modal-footer">
																										<button
																											type="button"
																											class="btn grey btn-outline-danger"
																											data-dismiss="modal">Fermer</button>
																										<button
																											v-if="!isUpdateQfc"
																											@click="saveQfc"
																											type="button"
																											data-dismiss="modal"
																											class="btn btn-outline-info">
																											<span
																												class="fs-5 fs-semibold"
																												v-if="!chargement">
																												Enregistrer
																											</span>
																											<span v-else
																												class="d-flex justify-content-center align-items-center">
																												<span
																													class="mx-2 fs-semibold text-light">
																													chargement
																													...
																												</span>
																												<div style="width: 1.5rem; height: 1.5rem"
																													class="spinner-border text-light"
																													role="status">
																													<span
																														class="sr-only">Loading...</span>
																												</div>
																											</span>

																										</button>
																										<button v-else
																											@click="updateQfc"
																											type="button"
																											data-dismiss="modal"
																											class="btn btn-outline-primary">

																											<span
																												class="fs-5 fs-semibold"
																												v-if="!chargement">
																												Modifier
																											</span>
																											<span v-else
																												class="d-flex justify-content-center align-items-center">
																												<span
																													class="mx-2 fs-semibold text-light">
																													chargement
																													...
																												</span>
																												<div style="width: 1.5rem; height: 1.5rem"
																													class="spinner-border text-light"
																													role="status">
																													<span
																														class="sr-only">Loading...</span>
																												</div>
																											</span>
																										</button>
																									</div>
																								</div>
																							</div>
																						</div>
																					</div>
																				</div>
																			</div>	

																		</div>


																	</form>
																</div>


																<div class="table-responsive">
																	<table class="table table-bordered table-striped table-hover ">
																		<thead class="bg-info white">
																			<tr>
																				<th class="text-left">#</th>
																				<th class="text-left">ALIMENTS</th>
																				<th class="text-left">GROUPE</th>
																				<th colspan="3" class="text-center">FREQUENCES </th>
																				<th class="text-left">TYPE DE RATIO</th>
																				<th colspan="2" class="text-center">LIEU ET L'HEURE</th>
																				<th class="text-center" >ACTIONS</th>
																			</tr>
																		</thead>
																		<tbody>
																			<tr>
																				<th class="text-left"></th>
																				<th class="text-left"></th>
																				<th class="text-left"></th>
																				<th >N/jr </th>
																				<th >N/Se </th>
																				<th >N/Mo </th>
																				<th> </th>
																				<th colspan="2"></th>
																				<th ></th>
																			</tr>

																			<tr v-for="(data, index) in list_qfc" :key="index">
																				<td class="text-left"><strong>{{index+1}}</strong></td>
																				<td class="text-left"><strong>{{data.aliment_group}}</strong></td>
																				<td class="text-left"><strong>{{data.aliment}}</strong></td>
																				<td >{{data.freq_jours}}</td>
																				<td >{{data.freq_sem}}</td>
																				<td>{{data.freq_mois}}</td>
																				<td >{{data.type_ration}}</td>
																				<td colspan="2">{{data.lieu}} à {{data.heure}}</td>
																				<td>
																					<div class="d-flex ">
																						<button title="modifer un composant"
																							type="button"
																							data-toggle="modal"
																							data-target="#modal_qfc"
																							@click.prevent="editQfc(index, data)"
																							class="btn btn-primary mx-1 round btn-sm waves-effect waves-light">
																							<i class="fas fa-edit"></i>
																						</button>
																						<button data-toggle="modal"
																							data-target="#delete_qfc"
																							title="supprimer un QFC"
																							@click.prevent="supprimerQfc(index, data);"
																							class=" mx-2 btn btn-danger  round btn-sm waves-effect waves-light">
																							<i class="fas fa-trash"></i>
																						</button>
																					</div>
																				</td>
																			</tr>

																		</tbody>
																	</table>
																</div>
															</div>
														</div>
													</div>
												</div>

												<!--COMPOSANTE NUTRITIONNEL -->
												<div class="tab-pane" id="linkIcon12" role="tabpanel" aria-labelledby="linkIcon12-tab1" aria-expanded="false">
													<div class="content-header row mb-2 mt-1">
														<div class="content-header-left col-md-6"> 
															<h4 class="card-title"><strong>COMPOSANTES NUTRITIONNELLES</strong></h4>
														</div>

														<div class="d-flex justify-content-end col-md-6 ">
															<!-- <button @click="openComponentModal" type="button"
																class="btn btn-outline-info btn-xs"
																data-toggle="modal" data-target="#modal_composant">
																Ajouter
															</button> -->

															<div class="col-lg-4 col-md-6 col-sm-12">
																<div class="form-group">

																	<!-- Modal -->
																	<div class="modal fade text-left" id="modal_composant"
																		tabindex="-1" role="dialog"
																		aria-labelledby="myModalLabel17"
																		aria-hidden="true">
																		<div class="modal-dialog modal-lg"
																			role="document">
																			<div class="modal-content">
																				<div class="modal-header">
																					<h4 class="modal-title" id="myModalLabel17">
																						Formulaire composantes nutritionnelles
																					</h4>
																					<button type="button" id="close_component"
																						class="close"
																						data-dismiss="modal"
																						aria-label="Close">
																						<span aria-hidden="true">&times;</span>
																					</button>
																				</div>
																				<div class="modal-body">
																					<form >
																						<div class="row">
																							<div class="col-md-6">
																								<fieldset
																									class="form-group floating-label-form-group">
																									<label for="composant">Sélectionner le composant
																									</label>

																									<VueMultiselect
																										v-model="current_composante.component"
																										:selectLabel="'Appuyez sur Entrée pour sélectionner'"
																										:deselectLabel="'Appuyez sur Entrée pour supprimer'"
																										selectedLabel="Selectionné"
																										tag-placeholder="Rechercher un composant"
																										placeholder="Rechercher un composant"
																										:options="all_composantes">
																									</VueMultiselect>
																								</fieldset>
																							</div>
																						
																							<div class="col-lg-6">
																								<label for="resultat">Résultat</label>
																								<input
																									v-model="current_composante.result"
																									type="number"
																									class="form-control">
																							</div>
																						</div>
																						<div class="row my-1">
																							<div
																								class="col-lg-8 offset-lg-2 ">
																								<label
																									for="Observation">OBSERVATIONS</label>
																								<textarea v-model="current_composante.observation"
																									name="Observation"
																									id=""
																									class="form-control"
																									rows="3">
																								</textarea>
																							</div>

																						</div>
																						<!-- isUpdateComponent
																						<button type="submit"
																							class="btn btn-primary w-100 ">
																							<span v-if="!chargement" class="fs-5 fs-semibold">
																								Enregistrer
																							</span>
																							<span v-else class="d-flex justify-content-center align-items-center">
																								<span class="mx-2 fs-semibold text-light">
																									chargement ...
																								</span>
																								<div style="width: 1.5rem; height: 1.5rem"
																									class="spinner-border text-light"
																									role="status">
																									<span
																										class="sr-only">Loading...</span>
																								</div>
																							</span>

																						</button> -->
																					</form>
																				</div>
																				<div
																					class="modal-footer">
																					<button
																						type="button"
																						class="btn grey btn-outline-danger"
																						data-dismiss="modal">Fermer</button>
																					<button
																						v-if="!isUpdateComponent"
																						@click="saveComponent"
																						type="button"
																						data-dismiss="modal"
																						class="btn btn-outline-info">
																						<span
																							class="fs-5 fs-semibold"
																							v-if="!chargement">
																							Enregistrer
																						</span>
																						<span v-else
																							class="d-flex justify-content-center align-items-center">
																							<span
																								class="mx-2 fs-semibold text-light">
																								chargement
																								...
																							</span>
																							<div style="width: 1.5rem; height: 1.5rem"
																								class="spinner-border text-light"
																								role="status">
																								<span
																									class="sr-only">Loading...</span>
																							</div>
																						</span>

																					</button>
																					<button v-else
																						@click="updateComponent"
																						type="button"
																						data-dismiss="modal"
																						class="btn btn-outline-primary">

																						<span
																							class="fs-5 fs-semibold"
																							v-if="!chargement">
																							Modifier
																						</span>
																						<span v-else
																							class="d-flex justify-content-center align-items-center">
																							<span
																								class="mx-2 fs-semibold text-light">
																								chargement
																								...
																							</span>
																							<div style="width: 1.5rem; height: 1.5rem"
																								class="spinner-border text-light"
																								role="status">
																								<span
																									class="sr-only">Loading...</span>
																							</div>
																						</span>
																					</button>
																				</div>
																			</div>
																		</div>
																	</div>
																</div>
															</div>

														</div>
													</div>
													<div class="table-responsive">
														<table class="table table-bordered table-striped table-hover ">
															<thead class="bg-info white">
																<tr>
																	<th>No</th>
																	<th>COMPOSANTES</th>
																	<th>RESULTATS</th>
																	<th>OBSERVATIONS</th>
																	<th class="text-center" >ACTIONS</th>
																</tr>
															</thead>
															<tbody>
																<tr v-for="(composante, index) in list_composantes" :key="index">
																	<td> <strong>{{index+1}}</strong></td>
																	<td><strong>{{composante.component}}</strong></td>
																	<td><strong>{{composante.result}} Cal</strong> </td>
																	<td>{{composante.observation}}</td>
																	<td>
																		<div class="d-flex ">
																			<button title="modifer un composant"
																				type="button"
																				data-toggle="modal"
																				data-target="#modal_composant"
																				@click.prevent="editComponent(index, composante)"
																				class="btn btn-primary mx-1 round btn-sm waves-effect waves-light">
																				<i class="fas fa-edit"></i>
																			</button>
																			<button data-toggle="modal"
																				data-target="#delete_composant"
																				title="supprimer un composant"
																				@click.prevent="supprimerComposant(index, composante)"
																				class=" mx-2 btn btn-danger  round btn-sm waves-effect waves-light">
																				<i class="fas fa-trash"></i>
																			</button>
																		</div>
																	</td>
																</tr>
															</tbody>
														</table>
													</div>														
												</div>

												<!-- RAPPEL DES 24H -->
												<div class="tab-pane" id="linkIconOpt15" role="tabpanel"
													aria-labelledby="linkIconOpt11-tab1" aria-expanded="false">
													<div class="row">
														<div class="col-12">
															<div class="card">
																<div class="card-body">
																	<form class="form">
																			<div class="content-header row  mt-1">
																				<div class="content-header-left col-md-6"> 
																					<h4 class="card-title"><strong>RAPPEL DES 24H</strong></h4>
																				</div>
																				<div class="d-flex justify-content-end col-md-6 ">
																					<div class="form-group">

																						<button type="button"
																							@click="openMenuModal"
																							class="btn btn-outline-info btn-xs"
																							data-toggle="modal"
																							data-target="#form_remind">
																							Ajouter
																						</button>


																						<div class="modal fade text-left"
																								id="form_remind" tabindex="-1"
																								role="dialog"
																								aria-labelledby="myModalLabel16"
																								aria-hidden="true">
																								<div class="modal-dialog modal-xl"
																									role="document">
																									<div class="modal-content" style="overflow: auto; height:500px">
																										<div class="modal-header">
																											<h4 class="modal-title" id="myModalLabel16">Formulaire de rappel de 24h</h4>
																											<button id="close_remind"
																												type="button"
																												class="close"
																												data-dismiss="modal"
																												aria-label="Close">
																												<span
																													aria-hidden="true">&times;</span>
																											</button>
																										</div>
																										<div class="modal-body">
																											<form>
																													<div class="row">
																														<div class="col-lg-6 offset-lg-3">
																															<label for="Aliment">NOM DU MENU</label>
																															<input
																																v-model="current_remind.name"
																																type="text"
																																class="form-control"
																																placeholder="Menu complet">
																														</div>
																														<div class="col-lg-2">
																															<label for="type_ratio">
																																TYPE DE RATIO
																															</label>
																															<select	name=""	v-model="current_remind.type_ratio" id="" class="form-control">
																																<option	value="Petit dejeuner">Petit déjeuner</option>
																																<option value="Dejeuner">Déjeuner</option>
																																<option value="Collation">Collation</option>
																																<option value="Souper">Souper</option>
																																<option value="Diner"> Diner</option>
																															</select>
																														</div>

																														<div class="col-lg-2">
																															<label
																																for="lieu">Lieu</label>
																															<input
																																v-model="current_remind.lieu"
																																type="text"
																																class="form-control">
																														</div>

																														<div class="col-lg-2">
																															<label
																																for="heure">L'heure</label>
																															<input
																																v-model="current_remind.heure"
																																type="time"
																																class="form-control">
																														</div>

																														<div class="col-lg-2">
																															<label
																																for="Description">Description</label>
																															<textarea
																																v-model="current_remind.description"
																																name="description"
																																id=""
																																class="form-control"
																																rows="1"></textarea>
																														</div>
																													</div>
																									
																												<div  v-for="(data, index) in current_remind.details ">

																													<div class="row mt-2">
																														<div class="col-lg-3">
																															<label for="Aliment">ALIMENT</label>
																															<input
																																v-model="data.aliment"
																																type="text"
																																class="form-control"
																																placeholder="Aliment">
																														</div> 
																														<div class="col-lg-1">
																															<label for="Aliment">VOL./POIDS</label>
																															<input
																																v-model="data.poids_vol"
																																type="number"
																																class="form-control"
																																placeholder="Quantité">
																														</div>

																													</div>

																													<div
																														class="d-flex justify-content-end my-2">
																														<button
																															type="button"
																															class="btn btn-danger"
																															@click="deleteRemind(index)">x</button>
																													</div>

																													<hr>


																												</div>
																												<div>
																													<button
																														type="button"
																														@click="addRemind"
																														class="btn btn-info">+
																														Ajouter
																														nouveau</button>
																												</div>
																											</form>
																										</div>
																										<div
																											class="modal-footer">
																											<button
																												type="button"
																												class="btn grey btn-outline-danger"
																												data-dismiss="modal">Fermer</button>
																											<button
																												v-if="!isUpdateRemind"
																												@click="saveRemind"
																												type="button"
																												data-dismiss="modal"
																												class="btn btn-outline-info">
																												<span
																													class="fs-5 fs-semibold"
																													v-if="!chargement">
																													Enregistrer
																												</span>
																												<span v-else
																													class="d-flex justify-content-center align-items-center">
																													<span
																														class="mx-2 fs-semibold text-light">
																														chargement
																														...
																													</span>
																													<div style="width: 1.5rem; height: 1.5rem"
																														class="spinner-border text-light"
																														role="status">
																														<span
																															class="sr-only">Loading...</span>
																													</div>
																												</span>

																											</button>
																											<button v-else
																												@click="updateRemind"
																												type="button"
																												data-dismiss="modal"
																												class="btn btn-outline-primary">

																												<span
																													class="fs-5 fs-semibold"
																													v-if="!chargement">
																													Modifier
																												</span>
																												<span v-else
																													class="d-flex justify-content-center align-items-center">
																													<span
																														class="mx-2 fs-semibold text-light">
																														chargement
																														...
																													</span>
																													<div style="width: 1.5rem; height: 1.5rem"
																														class="spinner-border text-light"
																														role="status">
																														<span
																															class="sr-only">Loading...</span>
																													</div>
																												</span>
																											</button>
																										</div>
																									</div>
																								</div>
																							</div>

																					</div>
																				</div>
																			</div>
																	</form>
																</div>

																<div class="table-responsive">
																		<table class="table table-bordered table-striped table-hover ">
																			<thead class="bg-info white">
																				<tr>
																					<th class="text-left" colspan="2">NOM</th>
																					<th class="text-left">TYPE DE RATIO</th>
																					<th colspan="2" class="text-center">LIEU ET L'HEURE</th>
																					<th colspan="3" class="text-center">DESCRIPTION </th>
																					<th class="text-center" >ACTIONS</th>
																				</tr>
																			</thead>
																			<tbody>
																				<tr v-for="(remind, index) in list_reminds" :key="index">
																					<td class="text-left" colspan="2"><div v-html="mapDetails(remind.details)"></div></td>
																					<td>{{remind.type_ratio}}</td>
																					<td colspan="2">{{remind.lieu}} {{remind.heure}}</td>
																					<td colspan="3">{{remind.description}}</td>
																					<td>
																						<div class="d-flex ">
																							<button title="modifer un composant"
																								type="button"
																								data-toggle="modal"
																								data-target="#form_remind"
																								@click.prevent="editRemind(index, remind)"
																								class="btn btn-primary mx-1 round btn-sm waves-effect waves-light">
																								<i class="fas fa-edit"></i>
																							</button>
																							<button data-toggle="modal"
																								data-target="#delete_rewind"
																								title="supprimer un composant"
																								@click.prevent="deleteRemind(index, remind)"
																								class=" mx-2 btn btn-danger  round btn-sm waves-effect waves-light">
																								<i class="fas fa-trash"></i>
																							</button>
																						</div>
																					</td>
																				</tr>

																			</tbody>
																		</table>
																</div>

															</div>
														</div>
													</div>
												</div>

												<!-- <Conseil/> -->

												<div class="tab-pane" id="tab-conseil" role="tabpanel" aria-labelledby="linkIcon13-tab1" aria-expanded="false">
													<div class="row">
													<div class="col-lg-12 col-md-12">
														<div class="card" style="height: 321.7px">
															<div class="card card-content collapse show">
																<div class="card-body">
																<h3 class="card-title">
																	Conseils et Recommandations
																	<button type="button" class="btn mb-1 btn-primary btn-icon round float-md-right"
																	data-toggle="modal" data-target="#modal-form-conseil">
																	<i class="la la-plus"></i>
																	Nouveau
																	</button>
																</h3>

																	<div class="modal animated bounce text-left" id="modal-form-conseil" tabindex="-1" role="dialog" aria-labelledby="myModalLabel36" aria-hidden="true">
																		<div class="modal-dialog modal-lg" role="document">
																			<div class="modal-content">
																			<div class="modal-header">
																				<h4 class="modal-title " id="myModalLabel36">
																				Ajout d'un conseil
																				</h4>
																				<button type="button" class="close" data-dismiss="modal"
																				aria-label="Close">
																				<span aria-hidden="true">&times;</span>
																				</button>
																			</div>
																			<form class="modal-body">
																				<div class="form-body">
																				<div class="row my-2">
																					<div class="col-md-12">
																					<div class="form-group mb-2">
																						<label for="userinput1">Conseil </label>
																						<textarea name="" v-model="current_conseil.comment"
																						placeholder="Entrer un conseil"
																						class="form-control round border-primary" id="" cols="30"
																						rows="2"></textarea>
																					</div>
																					</div>

																				</div>

																				<button type="submit" class="btn btn-primary w-100"
																					@click.prevent="saveConseil" data-dismiss="modal" aria-label="Close"> <i
																					class="la la-check-square-o"></i>Enregistrer
																				</button>

																				</div>
																			</form>
																			</div>
																		</div>
																	</div>


																	<div class="col-12 ">
																		<div class="card">
																			<div class="card-content collapse show">
																				<div class="card-body">
																					<div class="row">
																						<div class="col-6" v-for="(conseil, index) in list_conseils">
																							<div class="bs-callout-info callout-border-left p-1">
																								<strong>Bonjour {{ current_patient.name }}!</strong>
																								<p>{{ conseil.comment }}
																								</p>
																								<strong class="float-right">Par Dr {{ conseil.doctor.name }}
																									{{ conseil.created_at }}</strong>
																								<button @click="editConseil(conseil.id, conseil.comment)" type="button" class="btn mb-1 btn-primary btn-icon round float-md-right" data-toggle="modal" data-target="#modal-form-conseil" >
																								<i class="la la-edit"></i> Modifier
																								</button>
																							</div>
																						</div>
																					</div>
																				</div>
																			</div>
																		</div>
																	</div>

																</div>
															</div>
														</div>
													</div>
													</div>
												</div>
												<!-- FICHE STRESSEUR -->
												<div role="tabpanel" class="tab-pane active" id="activeIcon12"
													aria-labelledby="activeIcon12-tab1" aria-expanded="true">

													<div >
														
														<h2 class="card-title text-center">
															<strong class="text-danger">
																Evaluation des éléments
																stresseurs {{ficheStresseur.id }}
															</strong>
														</h2>

														<div class="table-responsive">
														<table class="table table-bordered table-striped table-hover ">
															<thead class="bg-info white">
																	<tr>
																		<th># </th>
																		<th>Pas du tout</th>
																		<th>Faiblement </th>
																		<th>Un peu</th>
																		<th>Assez</th>
																		<th>Beaucoup</th>
																		<th>Extremement</th>
																	</tr>
																</thead>
																<tbody>
																	<tr>
																		<th scope="row">Cote</th>
																		<th scope="row">1</th>
																		<th scope="row">2</th>
																		<th scope="row">3</th>
																		<th scope="row">4</th>
																		<th scope="row">5</th>
																		<th scope="row">6</th>

																	</tr>
																	<tr v-for="(data, index) in elementStresseurs"
																		:key="index">

																		<th scope="row" class="whiteSpace"> {{ data.libelle }}
																		</th>
																		<td>
																			<input @change="checkElmStress(index, 1)" type="radio" class="form-control" :name="`stresseurs_${index}`" v-model="data.niveau" value="1">
																		</td>
																		<td>
																			<input @change="checkElmStress(index, 2)" type="radio" class="form-control" :name="`stresseurs_${index}`" v-model="data.niveau" value="2">
																		</td>
																		<td>
																			<input @change="checkElmStress(index, 3)" type="radio" class="form-control" :name="`stresseurs_${index}`" v-model="data.niveau" value="3">
																		</td>
																		<td>
																			<input @change="checkElmStress(index, 4)" type="radio" class="form-control" :name="`stresseurs_${index}`" v-model="data.niveau" value="4">
																		</td>
																		<td>
																			<input @change="checkElmStress(index, 5)" type="radio" class="form-control" :name="`stresseurs_${index}`" v-model="data.niveau" value="5">
																		</td>
																		<td>
																			<input @change="checkElmStress(index, 6)" type="radio" class="form-control" :name="`stresseurs_${index}`" v-model="data.niveau" value="6">
																		</td>
																	</tr>



																	<tr>
																		<th scope="row" class="text-right ">
																			Total Element streseur
																		</th>
																		<td colspan="6" class="text-center"> 
																			<strong>{{ficheStresseur.total_elm_stress }}</strong>
																		</td>

																	</tr>

																	<tr>
																		<th scope="row" class="text-right">
																			Interpretation
																		</th>
																		<td colspan="6" class="text-center">
																			<strong >Niveau de stresseurs {{ficheStresseur.interpretation_elm}}</strong>
																		</td>
																	</tr>

																</tbody>
															</table>
														</div>

														<h2 class="card-title mt-5 text-center">
															<strong class="text-danger">Evaluation du niveau de stress</strong>
														</h2>

													<div class="table-responsive">
														<table class="table table-bordered table-striped table-hover ">
															<thead class="bg-info white">
																	<tr>
																		<th># </th>
																		<th>Pas du tout</th>
																		<th>Faiblement </th>
																		<th>Un peu</th>
																		<th>Assez</th>
																		<th>Beaucoup</th>
																		<th>Extremement</th>
																	</tr>
																</thead>
																<tbody>
																	<tr>
																		<th scope="row">Cote</th>
																		<th scope="row">1</th>
																		<th scope="row">2</th>
																		<th scope="row">3</th>
																		<th scope="row">4</th>
																		<th scope="row">5</th>
																		<th scope="row">6</th>

																	</tr>

																	<tr v-for="(data, index) in niveauDeStress" :key="index">
																		<th class="whiteSpace" scope="row"> {{ data }}
																		</th>
																		<td>
																			<input  @change="checkNivStress()" type="radio" class="form-control" :name="`stress_${index}`" v-model="data.niveau" value="1">
																		</td>
																		<td>
																			<input  @change="checkNivStress()" type="radio" class="form-control" :name="`stress_${index}`" v-model="data.niveau" value="2">
																		</td>
																		<td>
																			<input  @change="checkNivStress()" type="radio" class="form-control" :name="`stress_${index}`" v-model="data.niveau" value="3">
																		</td>
																		<td>
																			<input  @change="checkNivStress()" type="radio" class="form-control" :name="`stress_${index}`" v-model="data.niveau" value="4">
																		</td>
																		<td>
																			<input  @change="checkNivStress()" type="radio" class="form-control" :name="`stress_${index}`" v-model="data.niveau" value="5">
																		</td>
																		<td>
																			<input  @change="checkNivStress()" type="radio" class="form-control" :name="`stress_${index}`" v-model="data.niveau" value="6">
																		</td>

																	</tr>
																	<tr>
																		<th scope="row" class="text-right ">
																			Total géneral
																		</th>
																		<td colspan="6" class="text-center"> <strong> {{
																			ficheStresseur.total_niveau_stress }} </strong> </td>

																	</tr>

																	<tr>
																		<th scope="row" class="text-right">
																			Interpretation
																		</th>
																		<td colspan="6" class="text-center">
																			<strong>Stress {{ficheStresseur.interpretation_niv}}</strong>
																		</td>
																	</tr>

																</tbody>
															</table>
														</div>

														<div>
															{{ficheStresseur.diagnostic }}
														</div>

														<div class="d-flex justify-content-center my-4">
															<button
																class="btn btn-primary d-flex justify-content-between align-items-center"
																@click.prevent="saveFiche">
																<span class="px-2">Enregistrer la fiche</span>
															</button>
														</div>

														<h2 class="card-title mt-5 text-center">
															<strong class="text-danger">
																Evaluation activité physique
															</strong>
														</h2>

													<div class="table-responsive">
														<table class="table table-bordered table-striped table-hover ">
															<thead class="bg-info white">
																	<tr>
																		<th rowspan="2">Question</th>
																		<th colspan="5" class="text-center">POINTS</th>
																		<!-- <th rowspan="2">SCORES </th> -->
																	</tr>
																	<tr>
																		<th scope="row">1 
																		</th>
																		<th scope="row">2</th>
																		<th scope="row">3</th>
																		<th scope="row">4</th>
																		<th scope="row">5</th>
																	</tr>
																</thead>
																<tbody>

																	<tr v-for="(data, index) in actPhysique"
																		:key="index">
																		<td class="whiteSpace" scope="row"> {{ data.libelle }}
																		</td>
																		<td>
																			{{ data.ranges[0] }}
																			<input v-if="data.ranges[0] != ''"  @change="checkActPrysique()" type="radio" class="form-control" :name="`stress_${index}`" v-model="data.niveau" value="1">
																		</td>
																		<td>
																			{{ data.ranges[1] }}
																			<input v-if="data.ranges[1] != ''" @change="checkActPrysique()" type="radio" class="form-control" :name="`stress_${index}`" v-model="data.niveau" value="2">
																		</td>
																		<td>
																			{{ data.ranges[2] }}
																			<input v-if="data.ranges[2] != ''"  @change="checkActPrysique()" type="radio" class="form-control" :name="`stress_${index}`" v-model="data.niveau" value="3">
																		</td>
																		<td>
																			{{ data.ranges[3] }}
																			<input v-if="data.ranges[3] != ''"  @change="checkActPrysique()" type="radio" class="form-control" :name="`stress_${index}`" v-model="data.niveau" value="4">
																		</td>
																		<td>
																			{{ data.ranges[4] }}
																			<input v-if="data.ranges[4] != ''"  @change="checkActPrysique()" type="radio" class="form-control" :name="`stress_${index}`" v-model="data.niveau" value="5">
																		</td>
																		<td>
																			{{ data.ranges[5] }}
																			<input v-if="data.ranges[5] != ''"  @change="checkActPrysique()" type="radio" class="form-control" :name="`stress_${index}`" v-model="data.niveau" value="6">
																		</td>

																	</tr>
																	<tr>
																		
																		<td colspan="4" class="text-center"><Strong>TOTAL</Strong></td>
																		<td colspan="2">{{ficheStresseur.total_act_physique }}</td>
																	</tr>
																	
																	
																	

																	<tr>
																		<th scope="row" class="text-right">
																			Résultat Interpretation
																		</th>
																		<td colspan="6" class="text-center">
																			<strong>
																				{{ficheStresseur.interpretation_act}}
																			</strong>
																			
																		</td>
																	</tr>

																</tbody>
															</table>
														</div>

														
													</div>

													<div class="d-flex justify-content-center my-4">
														<button
															class="btn btn-primary d-flex justify-content-between align-items-center"
															@click.prevent="saveFiche">
															<span class="px-2">Enregistrer la fiche</span>
														</button>
													</div>
												</div>
											</div>

										</div>
									</div>
								</div>
							</div>

						</div>

					</section>
					<!-- Tabs with Icons end -->

				</div>
			</div>
		</div>
	</div>
</template>

<style>
	/* .form-control{
		height: 1.9rem !important;
	} */
</style>

<script setup>

import DeleteConfirmationModal from './DeleteConfirmationModal.vue';
import { ref, reactive, computed, onMounted } from 'vue'
import { useRouter, useRoute } from "vue-router"
import VueMultiselect from "vue-multiselect";
import ConsultationService from "@/services/modules/consultation.service.js";
import PatientService from "@/services/modules/patient.service.js";
import QuestionService from "@/services/modules/question.service.js";
import FicheStresseurService from "@/services/modules/ficheStresseur.service.js";
import PatientMenuService from "@/services/modules/patientMenu.service.js";
import MenusService from "@/services/modules/menus.service.js";
import PatientRemindService from "@/services/modules/patientRemind.service.js";
import RemindsService from "@/services/modules/reminds.service.js";
import PatientComposantService from "@/services/modules/patientComposant.service.js";
import ComposantService from "@/services/modules/composant.nutrition.service.js";
import PatientQfcService from "@/services/modules/patientQfc.service.js";
import QfcService from "@/services/modules/qfc.nutrition.service.js";
import BodyService from "@/services/modules/body.nutrition.service.js";
import PatientComposition from "@/services/modules/PatientComposition.service.js";
import PatientStresseurService from "@/services/modules/patientStresseur.service.js";
import PraticienService from "@/services/modules/praticien.service.js";
import FinconsultationService from "@/services/modules/finconsultation.service.js";
import ConseilService from "@/services/modules/conseil.patient.service.js";
import { createToast } from "mosha-vue-toastify";
// import the styling for the toast
import "mosha-vue-toastify/dist/style.css";



const router = useRouter()
const route = useRoute()
const current_patient = ref([])
let consultation_id
const docteurs = ref([])
const questions = ref([])

const elementStresseurs = ref([])
const niveauDeStress = ref([])
const actPhysique = ref([])
const sheet_questions = ref([])
const deleteData = reactive({})
const saveUpdate = reactive({})
const pagination = reactive({
	first: null,
	last: null,
	next: null,
	prev: null,
	current: null,
	total: null
})


const fiche = reactive(
	{
		doctor_id: '',
	},

	{
		composante_id: '',
	}
)

const isUpdate = ref(false)
const isUpdateMenu = ref(false)
const isUpdateRemind = ref(false)
const isUpdateComponent = ref(false)
const isUpdateQfc = ref(false)
const chargement = ref(false)
const showSelectDoctor = ref(false)


const current_user = reactive({id: '', doctor_id: ''})
const getCurrentUser = () => {
    let usersInfo = JSON.parse(localStorage.getItem('paevcliniqueInfo'))
    if(usersInfo != null || usersInfo != undefined ) {
		current_user.id = usersInfo.users.id
		current_user.doctor_id = usersInfo.doctor_id
    }
}

const prevMenu = function () {
	if (pagination.current >= 0) {
		pagination.current = 1
		getMenu(pagination.current)
	}
	else {
		pagination.current--
		getMenu(pagination.current)
	}
}
const nextMenu = function () {
	pagination.current++
	if (pagination.current <= pagination.total) {
		getMenu(pagination.current)
	}
}

const getData = async () => {
	 ConsultationService.get(route.params.id).then(async (data) => {
		let datas = await data.data.data
		current_patient.value = datas.patient
		consultation_id = datas.id

		getQfc()
		getCurrentUser()
		getFiche()
		getMenu()
		getReminds()
		getComposante()
    	getConseil()
	}).catch((e) => {
		console.error(e)
	})
}

const getDocteurs = () => {
	PraticienService.get().then((data) => {
		const datas = data.data.data
		docteurs.value = datas.data
	}).catch((e) => {
		console.error(e)
	})
}






const updateId = ref(0)





//  fiche stresseur

let ficheStresseur = reactive({
	"id": null,
	'total_elm_stress': null,
	"total_niveau_stress": null,
	"diagnostic": null,
	"patient_id": null,
	"doctor_id": null,
	"questions": []
})
const current_fiche = reactive(
		{
			name: '',
			type_ratio: '',
			lieu: '',
			heure: '',
			description: '',
			details: [
				{
					aliment: '',
					poids_vol: '',
				}
			],
			patient_id: null
		}
	)
const question_type = reactive(
	{
		type: ''
	}
)

const getFiche = () => {
	PatientStresseurService.get(current_patient.value.id).then((data) => {
			// datas = data.data.data
			// console.log(data.data.data);
		if( data.data.data){
			console.log(data.data.data);
			elementStresseurs.value.length = 0
			niveauDeStress.value.length = 0
			actPhysique.value.length = 0
			data.data.data.sheet_questions.forEach((elm) => {
				if(elm.question.type == "elm_stresseur"){
					elementStresseurs.value.push({ libelle: elm.question.libelle, niveau: elm.niveau, question_id: elm.question.id})
				}else if (elm.question.type == "niveau_stress"){
					niveauDeStress.value.push({ libelle: elm.question.libelle, niveau: elm.niveau, question_id: elm.question.id})
				}else if (elm.question.type == "act_physique"){
					actPhysique.value.push({ libelle: elm.question.libelle, niveau: elm.niveau, question_id: elm.question.id, ranges: elm.question.ranges})
				}
			})
			checkElmStress()
			checkNivStress()
			checkActPrysique()
		}else{
			getQuestionsElm()
			getQuestionsNiv()
			getActPhysique()
		}
		changediagnostic()
	}).catch((e) => {
		console.error(e)
	})
}

async function checkElmStress() {
	let sum = 0;
	elementStresseurs.value.forEach((elm) => {
		sum = sum + Number(elm.niveau)
	})
	ficheStresseur.total_elm_stress = sum;
	if (sum >= 8 && sum < 14) {
		ficheStresseur.interpretation_elm = "très bas"
	} else if (sum >= 14 && sum < 18) {
		ficheStresseur.interpretation_elm = "bas"
	} else if (sum >= 18 && sum < 28) {
		ficheStresseur.interpretation_elm = "élevé"
	} else if (sum >= 28) {
		ficheStresseur.interpretation_elm = "très élevé"
	}
	changediagnostic()
}

function checkNivStress() {
	let sum = 0;
	niveauDeStress.value.forEach((elm) => {
		sum = sum + Number(elm.niveau)
	})
	ficheStresseur.total_niveau_stress = sum;
	if (sum >= 11 && sum < 19) {
		ficheStresseur.interpretation_niv = "très bas"
	} else if (sum >= 19 && sum < 30) {
		ficheStresseur.interpretation_niv = "bas"
	} else if (sum >= 30 && sum < 45) {
		ficheStresseur.interpretation_niv = "élevé"
	} else if (sum >= 45) {
		ficheStresseur.interpretation_niv = "très élevé"
	}
	changediagnostic()
}

function checkActPrysique() {
	let sum = 0;
	actPhysique.value.forEach((elm) => {
		sum = sum + Number(elm.niveau)
	})
	ficheStresseur.total_act_physique = sum;
	if ( sum < 18) {
		ficheStresseur.interpretation_act = "Inactif"
	} else if (sum >= 18 && sum < 35) {
		ficheStresseur.interpretation_act = "Actif"
	} else if (sum >= 35) {
		ficheStresseur.interpretation_act = "Très actif"
	}
}

const diagnostics_list = [
	`-Vous êtes un sujet tendu et fortement stressé.
	-Les risques principaux sont l’état d’épuisement, les
	problèmes de santé dont les plus fréquents sont les maladies
	cardio-vasculaires.
	-Plus le score est élevé, plus les risques sont importants. il est
	urgent d’approfondir votre bilan de stress et d’apprendre à
	gérer votre stress`,
	`-Vous êtes plutôt serein, peu stressé.
	-Ce cas de figure est bénéfique sauf si des stresseurs trop
	importants apparaissent. votre manque « d’entrainement »
	pour affronter une situation stressante risque d’aboutir à un
	état de crise émotionnelle et ou un manque d’efficacité.`,
	`Ce cas de figure est plutôt bénéfique et montre que malgré
	de nombreuses
	sollicitations, votre niveau de stress reste bas.
	-Toute fois attention de pas sous évaluer votre état de stress
	car dans ce cas les problèmes seront les mêmes que dans la
	situation du cas n°1`,
	`-Il est important à apprendre à gérer son stress afin d’adapter
	au mieux la réponse aux stimulations extérieures.
	-Si un peu de stresseurs provoquent un stress élevé, votre
	réponse est inadaptée et votre état de santé n’est po préservé.`,
]
function changediagnostic() {
	let str = ficheStresseur.interpretation_elm+ficheStresseur.interpretation_niv;

	if (str.includes("très ")) {
		str = str.replace(/très /g, "");
	}

	switch (str) {
		case "basbas":
			ficheStresseur.diagnostic = diagnostics_list[1]
			break;
		case "élevébas":
			ficheStresseur.diagnostic = diagnostics_list[2]
			break;
		case "basélevé":
			ficheStresseur.diagnostic = diagnostics_list[3]
			break;
		case "élevéélevé":
			ficheStresseur.diagnostic = diagnostics_list[0]
			break;
	}
}

const getQuestionsElm = () => {
	QuestionService.get('elm_stresseur').then((data) => {
		for (let j = 0; j < data.data.data.length; j++) {
			elementStresseurs.value.push({ libelle: data.data.data[j].libelle, niveau: 1, question_id: data.data.data[j].id})
		}
		checkElmStress()
	}).catch((e) => {
		console.error(e)
	})
}

const getQuestionsNiv = () => {
	QuestionService.get('niveau_stress').then((data) => {
		for (let j = 0; j < data.data.data.length; j++) {
			niveauDeStress.value.push({ libelle: data.data.data[j].libelle, niveau: 1, question_id: data.data.data[j].id})
		}
		checkNivStress()
	}).catch((e) => {
		console.error(e)
	})
}

const getActPhysique = () => {
	QuestionService.get('act_physique').then((data) => {
		console.log('-----------------data act_physique-----------------')
		// console.log(data.data.data)
		let datas = data.data.data
		datas.forEach(elt => {
			actPhysique.value.push({ libelle: elt.libelle, niveau: 1, question_id: elt.id, ranges: elt.ranges})
		});
		checkNivStress()
	}).catch((e) => {
		console.error(e)
	})
}
const deleteFiche = function () {
    FicheStresseurService.destroy(ficheStresseur.id).then((data) => {
      if (data.data.status == "success") {
        getFiche()
        toast("Suppression de la fiche stresseur", "success");
      } else {
        toast(data.data.message, "danger");
      }
    })
}

const saveFiche = function () {
	ficheStresseur.patient_id = current_patient.value.id
	ficheStresseur.consultation_id = consultation_id
	ficheStresseur.doctor_id = current_user.doctor_id
	ficheStresseur.sheet_questions = []
	ficheStresseur.sheet_questions = [...elementStresseurs.value, ...niveauDeStress.value, ...actPhysique.value]
	// datas.sheet_questions = questions
	FicheStresseurService.create(ficheStresseur).then((data) => {
		const response = data.data
		if (response.status === 'error') {
			toast(response.message, 'danger')
		}
		else {
        	getFiche()
			toast('Vous avez ajouté une fiche stresseur', 'success')
		}

	})
};


function openComponentModal() {
	isUpdate.value = false
}




const modifierComposant = function (index, data) {
	updateId.value = data.id
	saveUpdate.index = index
	saveUpdate.component = data.component
	saveUpdate.result = data.result
	saveUpdate.observation = data.observation
	isUpdate.value = true
}


//##########################---Conseil---################################
const current_conseil = reactive({
  "consultation_id": route.params.id, 
  "id": null, 
  "patient_id": null, 
  "doctor_id": null, 
  "comment": null
})
const list_conseils = ref([])

const getConseil = () => {
  ConseilService.get(route.params.id).then((data) => {
    const datas = data.data.data
    list_conseils.value = datas
  }).catch((e) => {
    console.error(e)
  })
}


const saveConseil = function () {
    // est utiliser pour soumettre les conseils en create ou update
    current_conseil.patient_id = current_patient.value.id
    current_conseil.doctor_id = current_user.doctor_id
    current_conseil.consultation_id = route.params.id

    if(current_conseil.id === null){
      ConseilService.create(current_conseil).then((data) => {
        const response = data.data
        if (response.status === 'error') {
          toast(response.message, 'danger')
        }
        else {
          emptyConseil()
          getConseil()
          toast("enregistré vec succès !!!", 'success')
        }
      })
    }else{
      ConseilService.update(current_conseil.id, current_conseil).then((data) => {
        const response = data.data
        if (response.status === 'error') {
          toast(response.message, 'danger')
        }
        else {
          emptyConseil()
          getConseil()
          toast("enregistré vec succès !!!", 'success')
        }
      })
    }
};
const editConseil = function (id, comment) {
  // est utilisé pour quand l'user click pour modifier un conseil remplir le formulaire avec les infos du conseil
    current_conseil.id = id
    current_conseil.comment = comment
};
const emptyConseil = function () {
    current_conseil.id = null
    current_conseil.comment = null
};

//##########################---Menu habituel---################################
const menus = ref([])

const menuHabituel = reactive(
	{
		name: '',
		type_ratio: '',
		lieu: '',
		heure: '',
		description: '',
		details: [
			{
				aliment: '',
				poids_vol: '',
			}
		],
		patient_id: null
	}
)

const getMenu = () => {
	PatientMenuService.get(current_patient.value.id).then((data) => {
		const datas = data.data
		menus.value = datas.data

	}).catch((e) => {
		console.error(e)
	})
}

function mapDetails(data) {
	const result = data.map(item => `<br/>${item.aliment} => ${item.poids_vol}`).join(', ');
	return result
}
function addMenu() {
	menuHabituel.details.push({
		aliment: '',
		poids_vol: '',
	})
}
function deleteMenu(index) {
	menuHabituel.details.splice(index, 1);
}
function closeMenu() {
document.getElementById('close_usual_manu').click();
	menuHabituel.details = [
		{
			aliment: '',
			poids_vol: '',
		}

	]
}
function openMenuModal() {
	isUpdate.value = false
}
const saveUsualMenu = function () {
	if (chargement.value == false) {
		chargement.value = true
		menuHabituel.patient_id = current_patient.value.id
		menuHabituel.consultation_id = consultation_id
		MenusService.create(menuHabituel).then((data) => {
			const response = data.data
			if (response.status === 'error') {
				chargement.value = false
				toast(response.message, 'danger')
			}
			else {
				chargement.value = false
				getMenu()
				closeMenu()
				toast("vous avez creer un menu", 'success')
			}

		})
	}
};

const modifierMenu = function (index, menu) {
	// console.log(menu.name)
	updateId.value = menu.id
	menuHabituel.index = index
	menuHabituel.name = menu.name
	menuHabituel.type_ratio = menu.type_ratio
	menuHabituel.lieu = menu.lieu
	menuHabituel.heure = menu.heure
	menuHabituel.description = menu.description
	menuHabituel.details = menu.details
	isUpdateMenu.value = true

}
const updateUsualMenu = function () {
	
	if (chargement.value == false) {
		chargement.value = true
		menuHabituel.patient_id = current_patient.value.id

		MenusService.update(updateId.value, menuHabituel).then((data) => {
			const response = data.data
			if (response.status === 'error') {
				chargement.value = false
				toast(response.message, 'danger')
			}
			else {
				chargement.value = false
				getMenu()
				closeMenu()
				toast('vous avez effectué une mise à jours', 'success')
			}

		})
	}
};

const deleteUsualMenu = function (index, data) {
	deleteData.id = data.id;
	deleteData.nom = data.nom;
	deleteData.index = index;
};
const deleteUsualMenuItems = function () {
	menus.value.splice(menus.value.indexOf(deleteData.index), 1);
	MenusService.destroy(deleteData.id)
		.then((data) => {
			toast("Suppression effectué avec succèss", "success");
			getMenu()
		})
		.catch((error) => {
			if (error.response) {
				// Requête effectuée mais le serveur a répondu par une erreur.
				const erreurs = error.response.data.message;
				toast(erreurs, "danger");
			} else if (error.request) {
				// Demande effectuée mais aucune réponse n'est reçue du serveur.
				//console.log(error.request);
			} else {
				// Une erreur s'est produite lors de la configuration de la demande
			}
		});
};
//fin menu habituel

//crud body composition
const list_compositions = ref([])

const current_composition = reactive({})

const getCompossition = () => {
	PatientComposition.get(current_patient.value.id).then((data) => {
		const datas = data.data.data[0]
		for (let key in datas) {
			current_composition[key] = datas[key];
		}

	}).catch((e) => {
		console.error(e)
	})
}

function closeCompossition() {
	document.getElementById('close_composition').click();
	// current_composition = {}
}
function openCompositionModal() {
	isUpdate.value = false
}
const saveComposition = function () {
	if (chargement.value == false) {
		chargement.value = true
		current_composition.patient_id = current_patient.value.id
		current_composition.consultation_id = consultation_id
		BodyService.create(current_composition).then((data) => {
			const response = data.data
			if (response.status === 'error') {
				chargement.value = false
				toast(response.message, 'danger')
			}
			else {
				chargement.value = false
				getCompossition()
				closeCompossition()
				toast("Les données ont bien été enregistré", 'success')
			}

		})
	}
};

const updateUsualComposition = function () {
	
	if (chargement.value == false) {
		chargement.value = true
		menuHabituel.patient_id = current_patient.value.id

		MenusService.update(updateId.value, menuHabituel).then((data) => {
			const response = data.data
			if (response.status === 'error') {
				chargement.value = false
				toast(response.message, 'danger')
			}
			else {
				chargement.value = false
				getMenu()
				closeMenu()
				toast('vous avez effectué une mise à jours', 'success')
			}

		})
	}
};

const supprimeComposition = function (index, data) {
	deleteData.id = data.id;
	deleteData.nom = data.nom;
	deleteData.index = index;
};
const deleteComposition = function () {
	menus.value.splice(menus.value.indexOf(deleteData.index), 1);
	MenusService.destroy(deleteData.id)
		.then((data) => {
			toast("Suppression effectué avec succèss", "success");
			getMenu()
		})
		.catch((error) => {
			if (error.response) {
				// Requête effectuée mais le serveur a répondu par une erreur.
				const erreurs = error.response.data.message;
				toast(erreurs, "danger");
			} else if (error.request) {
				// Demande effectuée mais aucune réponse n'est reçue du serveur.
				//console.log(error.request);
			} else {
				// Une erreur s'est produite lors de la configuration de la demande
			}
		});
};
//fin body composition

//crud rappel des 24h

const list_reminds = ref([])
const current_remind = reactive(
	{
		name: '',
		type_ratio: '',
		lieu: '',
		heure: '',
		description: '',
		details: [
			{
				aliment: '',
				poids_vol: '',
			}
		],
		patient_id: null
	}
)

const getReminds = () => {
	PatientRemindService.get(current_patient.value.id).then((data) => {
		const datas = data.data
		list_reminds.value = datas.data
	}).catch((e) => {
		console.error(e)
	})
}

function addRemind() {
	current_remind.details.push({
		aliment: '',
		poids_vol: '',
	})
}
function closeRemind() {
	document.getElementById('close_remind').click();
	current_remind.details = [
		{
			aliment: '',
			poids_vol: '',
		}
	]
}
function openRemindModal() {
	isUpdate.value = false
}
const saveRemind = function () {
	if (chargement.value == false) {
		chargement.value = true
		current_remind.patient_id = current_patient.value.id
		current_remind.consultation_id = consultation_id
		RemindsService.create(current_remind).then((data) => {
			const response = data.data
			if (response.status === 'error') {
				chargement.value = false
				toast(response.message, 'danger')
			}
			else {
				chargement.value = false
				getReminds()
				closeRemind()
				toast("vous avez creer un menu", 'success')
			}
		})
	}
};

const editRemind = function (index, menu) {
	updateId.value = menu.id
	current_remind.index = index
	current_remind.name = menu.name
	current_remind.type_ratio = menu.type_ratio
	current_remind.lieu = menu.lieu
	current_remind.heure = menu.heure
	current_remind.description = menu.description
	current_remind.details = menu.details
	isUpdateRemind.value = true
}
const updateRemind = function () {
	
	if (chargement.value == false) {
		chargement.value = true
		current_remind.patient_id = current_patient.value.id
		RemindsService.update(updateId.value, current_remind).then((data) => {
			const response = data.data
			if (response.status === 'error') {
				chargement.value = false
				toast(response.message, 'danger')
			}
			else {
				chargement.value = false
				getReminds()
				closeRemind()
				toast('vous avez effectué une mise à jours', 'success')
			}

		})
	}
};

const deleteRemind = function (index, data) {
	deleteData.id = data.id;
	deleteData.nom = data.nom;
	deleteData.index = index;
};
const deleteRemindItems = function () {
	menus.value.splice(menus.value.indexOf(deleteData.index), 1);
	RemindsService.destroy(deleteData.id)
		.then((data) => {
			toast("Suppression effectué avec succèss", "success");
			getReminds()
		})
		.catch((error) => {
			if (error.response) {
				// Requête effectuée mais le serveur a répondu par une erreur.
				const erreurs = error.response.data.message;
				toast(erreurs, "danger");
			} else if (error.request) {
				// Demande effectuée mais aucune réponse n'est reçue du serveur.
				//console.log(error.request);
			} else {
				// Une erreur s'est produite lors de la configuration de la demande
			}
		});
};

// crud composantes nutritionel

const list_composantes = ref([])
const joined_composantes = ref([])
const all_composantes = [
	"Energie Kilocalorie", "Carbohydrate", "Sugar", "Saturated Fat", "Mono Fat", "Sodium", "Iron", "Calcium", "Magnesium", "Selenium", "Thiamin B1", "Protein", "Dietary Fiber", "Fat", "Poly Fat", "Cholesterol", "Potassium", "Phosphorus", "Copper", "Zinc", "Vitamin A", "Riboflavin B2", "Niacin B3", "Vitamin B6", "Folate", "Vitamin B12", "Vitamin C", "Vitamin E", "Vitamin K", "Beta-carotene", "Alcohol", "Caffeine", "Moisture"
]
const current_composante = reactive(
	{
		component: '',
		result: '',
		observation: '',
		patient_id: null,
		consultation_id: consultation_id,
	}
)

const getComposante = () => {
	PatientComposantService.get(current_patient.value.id).then((data) => {
		const datas = data.data
		list_composantes.value = all_composantes.flatMap(component => {
			if (datas.data.find(item => item.component === component)) {
				return [
				// { component },
				...datas.data.filter(item => item.component === component)
				];
			}
			return { component };
		});
		// list_composantes.value = all_composantes.flatMap(component => {
		// 	const matchingItems = datas.data.filter(item => item.component === component);
		// 	if (matchingItems.length > 0) {
		// 		return [
		// 		{ component },
		// 		...matchingItems
		// 		];
		// 	}
		// 	return { component };
		// });
	}).catch((e) => {
		console.error(e)
	})
}
const prevComposante = function () {
	if (pagination.current >= 0) {
		pagination.current = 1
		getComposante(pagination.current)
	}
	else {
		pagination.current--
		getComposante(pagination.current)
	}
}
const nextComposante = function () {
	pagination.current++
	if (pagination.current <= pagination.total) {
		getComposante(pagination.current)
	}
}
function closeComposante() {
	document.getElementById('close_component').click();
	current_composante.component = ''
	current_composante.result = ''
	current_composante.observation = ''
}
const saveComponent = function () {
	if (chargement.value == false) {
		chargement.value = true
		current_composante.consultation_id = consultation_id
		current_composante.patient_id = current_patient.value.id
		ComposantService.create(current_composante).then((data) => {
			const response = data.data
			if (response.status === 'error') {
				chargement.value = false
				toast(response.message, 'danger')
			}
			else {
				chargement.value = false
				getComposante()
				closeComposante()
				toast("vous avez creer un composant nutrionnel ", 'success')
			}
		})
	}
};

const editComponent = function (index, component) {
	updateId.value = component.id
	current_composante.component = component.component
	current_composante.result = component.result
	current_composante.observation = component.observation
	isUpdateComponent.value = true

}
const updateComponent = function () {
	if (chargement.value == false) {
		chargement.value = true
		current_composante.consultation_id = consultation_id
		current_composante.patient_id = current_patient.value.id
		ComposantService.update(updateId.value, current_composante).then((data) => {
			const response = data.data
			if (response.status === 'error') {
				chargement.value = false
				toast(response.message, 'danger')
			}
			else {
				chargement.value = false
				getComposante()
				closeComposante()
				toast('vous avez effectué une mise à jours', 'success')
			}

		})
	}
};

const supprimerComposant = function (index, data) {
	deleteData.id = data.id;
	deleteData.nom = data.nom;
	deleteData.index = index;
};
const deleteComponent = function () {
	list_composantes.value.splice(list_composantes.value.indexOf(deleteData.index), 1);
	ComposantService.destroy(deleteData.id)
		.then((data) => {
			toast("Suppression effectué avec succèss", "success");
			getComposante()
		})
		.catch((error) => {
			if (error.response) {
				// Requête effectuée mais le serveur a répondu par une erreur.
				const erreurs = error.response.data.message;
				toast(erreurs, "danger");
			} else if (error.request) {
				// Demande effectuée mais aucune réponse n'est reçue du serveur.
				//console.error(error.request);
			} else {
				// Une erreur s'est produite lors de la configuration de la demande
			}
		});
};


// crud qfc

const list_qfc = ref([])
const aliments = {
  "MAIS": ["Pâte de maïs", "Bouillie de maïs", "Akassa", "Mansa", "Amiwo", "Autres"],
  "MIL": ["Pâte de mil", "Bouillie de mil", "Foura", "Autres"],
  "RIZ": ["Cuit à l'eau", "Pâte de riz", "Bouillie de riz", "Riz + haricot (watchi, ayi monlou)", "Riz au gras", "Autres"],
  "HARICOT": ["Haricot bouilli", "Beignet ata = Gaou", "Cuit + farine de maïs + huile", "Autres"],
  "VOANDZOU": ["Voandzou cuit à l’eau", "Voandzou grillé", "Voandzou en purée", "Autres"],
  "POIS D’ENGOL": ["Cuit à l’eau", "Cuit à l’eau et mélangé à la farine de maïs", "Autres"],
  "CASSOULET": ["Purée de cassoulet"],
  "BLE": ["Pain salé", "Yovo doko", "Pâté", "Biscuits de fabrication locale", "Atchonmon frit", "Atchonmon grillé", "Pain sucré", "Autres"],
  "MANIOC": ["Cuit à l’eau", "Gari", "Piron", "Pâte de cossette de manioc séché", "Atièkè", "Manioc pilé", "Autres"],
  "IGNAME": ["Igname bouillie", "Igname frite", "Pâte de cossette d'igname : Télibo", "Igname pilée", "Igname grillée", "Ragoût d'igname", "Autres"],
  "PATATE DOUCE": ["Patate douce grillée", "Patate douce cuite à l’eau", "Patate douce frite", "Ragoût de patate douce", "Autres"],
  "POMME DE TERRE": ["Pomme de terre frite", "Pomme de terre bouillie", "Autres"],
  "TARO": ["Taro grillé (Mankani)", "Taro bouilli", "Taro frit", "Fufu de taro", "Feuille de taro (kotonbli)", "Ragoût de taro", "Autres"],
  "SUCRERIES ET CONFISERIRES": ["Chocolats", "Bonbons", "Gâteaux", "Autres"],
  "FRUITS": ["Orange", "Banane", "Mandarine", "Goyages", "Citron", "Ananas", "Mangue", "Pamplemousse", "Banane plantain", "Avocat", "Pomme", "Anacarde", "Papaye", "Azonbébé", "Assonsron", "Autres"],
  "INFUSIONS ET AUTRES": ["Thé", "Citronnelle", "Café", "Autres"],
  "Epinard d’Afrique": ["Epinard cuit à la sauce de tomate", "Epinard cuit à la sauce de courge", "Autres"],
  "Amaranthe": ["Amaranthe cuite à la sauce de tomate", "Amaranthe cuite à la sauce de courge", "Autres"],
  "Amanvivè": ["Amanvivè cuite à la sauce de tomate", "Amanvivè cuite à la sauce de courge/goussi", "Autres"],
  "Feuille de haricot": ["Feuille de haricot + huile de palme", "Feuille de haricot + huile d’arachides", "Autres"],
  "Crincrin ou feuille de jute ou de corosier": ["Crincrin en sauce + épinard", "Crincrin en sauce + huile de palme", "Crincrin en sauce seule", "Autres"],
  "Gombo": ["Gombo en sauce + épinard", "Gombo en sauce + huile de palme", "Gombo en sauce seule", "Gombo sec en sauce", "Autres"],
  "Cabri": ["Tête", "Boyaux", "Viande maigre", "Viande grasse", "Autres"],
  "Bœuf": ["Viande maigre", "Viande demi-grasse", "Viande grasse", "Pattes de bœuf", "Langue", "Queue", "Foie", "Sang", "Autres"],
  "Porc": ["Boyaux", "Viande maigre", "Viande grasse", "Kpètè", "Autres"],
  "Autres viandes": ["Cochon d'inde", "Mouton", "Agouti", "Biche fumée", "Autres"],
  "Volailles": ["Poulet", "Pintade", "Dindon (Adokou gbin)", "Canard", "Pigeon", "Tourterelles", "Perdrix", "Autres"],
  "Poissons": ["Daurade (cica-cica)", "Tilapia (carpe)", "Saumon (salmon)", "Akpala (Chinchard)", "Silivi", "Silure noire ou blanche (Blolo / Akpavi)", "Autres"],
  "Crabes d’eau douce": ["Agoglan", "Asson (Kpèdè)", "Autres"],
  "Crevettes": ["Crevettes fraîches", "Crevettes fumées moulues", "Autres"],
  "MOLLUSQUES": ["Escargot (abobo)", "Huîtres", "Limace (petit escargot akuété)", "Sauterelles", "Autres"],
  "Arachides": ["Sauces d'arachide fraîche", "Sauces d'arachide grillée", "Grillée", "Arachide bouillie", "Klui-klui d'arachide (gallette)", "Nouga", "Andou (maïs + arachide grillée sucre)", "Kokanda (arachide grillée + caramel de sucre)", "Autres"],
  "Courge": ["Sauce de courge", "Gâteau de courge", "Autres"],
  "Autres noix et amandes": ["Noix de coco-fraîche", "Coprah", "Amande grillée d'anarcadium", "Autres"],
  "CORPS GRAS": ["Huile soja", "Huile de palme", "Huile d'arachides", "Huile de beurre de karité", "Huile de graine de coton", "Huiles importées", "Huile de l'amande de la noix de coco (yovo némi)", "Huile de sésame", "Autres"],
  "EPICES ET AUTRES": ["Oignon", "Ail", "Poivre", "Piment", "Gingembre", "Feuilles de lauriers", "Maggy poulet", "Moutarde", "Autres"],
  "CONSERVES": ["Tomates en boîtes", "Sardines en boîtes", "Pilchard", "Autres"],
  "LAITS ET PRODUITS LAITIERS": ["Lait condensé sucré en boîte", "Lait liquide en boîte (peak et genre)", "Lait frais de vache", "Lait frais de mouton", "Yaourt", "Fromage la vache qui rit", "Fromage de lait de vache local : warangashi", "Autres"],
  "Eau": ["Eau", "Eau de pompe", "Eau de puits", "Autres"],
  "Sucreries": ["Coca-Cola", "Sprite", "Cocktail de fruit", "Fanta", "Jus et sirop de gingembre", "Jus et sirop d'ananas", "Autres"],
  "BOISSONS alcoolisées de maïs / mil": ["Tchakpalo", "Sodabi", "Tchoukoutou"],
  "BOISSONS alcoolisées industrielles": ["Bière", "Martini", "Vin rouge", "Sodabi", "Autres"],
  "Sels et minéraux": ["Sel de cuisine", "Iodé", "Non iodé", "Bicarbonate de soude", "Autres"],
  "Excitants": ["Café", "Cola", "Thé", "Autres"],
  "Vitamines": ["Vitamine A", "Vitamine E", "Vitamine C", "Autres"]
}
const current_qfc = reactive(
	{
		aliment_group: 'MAIS',
		aliment: '',
		type_ration: '',
		freq_jours: "",
		freq_sem: '',
		freq_mois: '',
		lieu: '',
		heure: '',
		consultation_id: consultation_id
	}
)

const getQfc = () => {
	PatientQfcService.get(current_patient.value.id).then((data) => {
		const datas = data.data
		list_qfc.value = datas.data
	}).catch((e) => {
		console.error(e)
	})
}

function closeQfc() {
	document.getElementById('close_qfc').click();
	current_qfc.component = ''
}
const saveQfc = function () {
	if (chargement.value == false) {
		chargement.value = true
		current_qfc.patient_id = current_patient.value.id
		current_qfc.consultation_id = consultation_id
		QfcService.create(current_qfc).then((data) => {
			const response = data.data
			if (response.status === 'error') {
				chargement.value = false
				toast(response.message, 'danger')
			}
			else {
				chargement.value = false
				getQfc()
				closeQfc()
				toast("vous avez creer un composant nutrionnel ", 'success')
			}
		})
	}
};

const editQfc = function (index, qfc) {
	updateId.value = qfc.id
	current_qfc.aliment_group = qfc.aliment_group
	current_qfc.aliment = qfc.aliment
	current_qfc.type_ration = qfc.type_ration
	current_qfc.freq_jours = qfc.freq_jours
	current_qfc.freq_sem = qfc.freq_sem
	current_qfc.freq_mois = qfc.freq_mois
	current_qfc.lieu = qfc.lieu
	current_qfc.heure = qfc.heure
	isUpdateQfc.value = true
}
const updateQfc = function () {
	if (chargement.value == false) {
		chargement.value = true
		current_qfc.patient_id = current_patient.value.id
		current_qfc.consultation_id = consultation_id
		QfcService.update(updateId.value, current_qfc).then((data) => {
			const response = data.data
			if (response.status === 'error') {
				chargement.value = false
				toast(response.message, 'danger')
			}
			else {
				chargement.value = false
				getQfc()
				closeQfc()
				toast('vous avez effectué une mise à jours', 'success')
			}

		})
	}
};

const supprimerQfc = function (index, data) {
	deleteData.id = data.id;
	deleteData.nom = data.nom;
	deleteData.index = index;
};

const deleteQfc = function () {
	composantes.value.splice(composantes.value.indexOf(deleteData.index), 1);
	QfcService.destroy(deleteData.id)
		.then((data) => {
			getQfc()
			toast("Suppression effectué avec succèss", "success");
		})
		.catch((error) => {
			if (error.response) {
				// Requête effectuée mais le serveur a répondu par une erreur.
				const erreurs = error.response.data.message;
				toast(erreurs, "danger");
			} else if (error.request) {
				// Demande effectuée mais aucune réponse n'est reçue du serveur.
				//console.log(error.request);
			} else {
				// Une erreur s'est produite lors de la configuration de la demande
			}
		});
};

const toast = (message, type) => {
	createToast(message, { type: type });
};

onMounted(() => {
	getQfc()
	getCurrentUser()
	getData()
	// getFiche()
	// getMenu()
	getReminds()
	getComposante()
	getDocteurs()
	getCompossition()
})


const finishConsultation = function () {
  FinconsultationService.get(route.params.id).then((data) => {
    const response = data.data
    if (response.status === 'error') {
      toast(response.message, 'danger')
    }
    else {
      router.go(-2)
      toast("Consultation terminée !!!", 'success')
    }
  })
}
</script>

<style scoped>.whiteSpace {
	white-space: normal;
}</style>