
import ApiService from '@/services/configs/api.service'

const ProtocoleService = {
  get(slug,element) {
    return ApiService.get(`protocols`, slug,element);
  },
 
  create(params) {
    return ApiService.post("protocols", params);
  },

  

  update(slug, params) {
    return ApiService.update("protocols", slug, params);
  },

  destroy(slug) {
    return ApiService.delete(`protocols/${slug}`);
  },
 

 
};

  export default ProtocoleService;
  