<template>
    <div>
        <div class="app-content content">
            <div class="content-overlay"></div>
            <div class="content-wrapper">
                <div class="content-header row">
                    <div class="content-header-left col-md-6 col-12 mb-2">
                        <!-- <h3 class="content-header-title">Gérer les rendez-vous </h3> -->
                        <div class="row breadcrumbs-top">
                            <div class="breadcrumb-wrapper col-12">
                                <ol class="breadcrumb">
                                    <li class="breadcrumb-item">
                                        <router-link to="/admin/fiche-conseil">
                                            <span>Conseil & recommandation</span>
                                        </router-link>

                                    </li>

                                    <li class="breadcrumb-item active">
                                        <router-link to="/admin/evolution-traitement">
                                            <span>Aller au suivi de ce patient</span>
                                        </router-link>
                                    </li>
                                    <li class="breadcrumb-item ">
                                        <router-link to="/">
                                            <span>Dossier Médical</span>
                                        </router-link>
                                    </li>
                                </ol>
                            </div>
                        </div>
                    </div>
                    <!-- Definition du modal -->
                    <!-- modal ajout  -->
                    <div class="modal fade text-left" id="large" tabindex="-1" role="dialog" aria-labelledby=""
                        aria-hidden="true">
                        <div class="modal-dialog modal-sm" role="document">
                            <div class="modal-content">
                                <div class="modal-header bg-info white">
                                    <h4 class="modal-title white" id="">
                                        + Ajouter un conseil

                                    </h4>
                                    <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                                        <span aria-hidden="true">&times;</span>
                                    </button>
                                </div>
                                <div class="modal-body">
                                    <form>
                                        <div>
                                            <div>
                                                <div class="container">
                                                    <div class="row">
                                                        <div class="col-lg-12">
                                                            <label for="patient">Identité du patient</label>
                                                            <input class="form-control" id="fullName" name="fullName" required v-model="selectedPatient" type="text" disabled/>
                                                        </div>
                                                    </div>

                                                    <div class="row">
                                                        <!-- <div class="col-lg-12">
                                        <fieldset class="form-group floating-label-form-group">
                                            <label for="patient">DIAGNOSTICS RETENUS</label>
                                            <textarea name="" class="form-control" id="" cols="10"
                                            disabled>Patient diabétique</textarea>
                                        </fieldset>
                                        </div> -->
                                                        <div class="col-lg-12">
                                                            <label for="patient">Identité du praticien</label>
                                                            <fieldset class="form-group floating-label-form-group">
                                                                <VueMultiselect v-model="formData.doctor" label="name"
                                                                    track-by="id"
                                                                    :selectLabel="'Appuyez sur Entrée pour sélectionner'"
                                                                    :deselectLabel="'Appuyez sur Entrée pour supprimer'"
                                                                    selectedLabel="Selectionné"
                                                                    tag-placeholder="Selectionnez le praticien"
                                                                    placeholder="Selectionnez un praticien"
                                                                    :options="praticiens">
                                                                </VueMultiselect>
                                                            </fieldset>
                                                        </div>


                                                    </div>


                                                    <div class="row">
                                                        <div class="col-lg-12">
                                                            <fieldset class="form-group floating-label-form-group">
                                                                <label for="patient">Votre conseil </label>
                                                                <textarea name="" class="form-control" id="" cols="10"
                                                                    rows="10" v-model="formData.comment"></textarea>
                                                            </fieldset>
                                                        </div>
                                                    </div>

                                                </div>
                                            </div>
                                        </div>
                                        <div class=" d-flex justify-content-center py-1  ">
                                            <button type="button" @click="close"
                                                class="btn grey btn-danger btn-sm mx-3 fs-5 fs-semibold"
                                                data-dismiss="modal">
                                                Fermer
                                            </button>
                                            <button @click.prevent="storeConseil" data-dismiss="modal" aria-label="Close"
                                                type="submit" class="btn btn-primary flex">
                                                <span class="fs-5 fs-semibold">
                                                    Envoyer
                                                </span>
                                            </button>
                                        </div>
                                    </form>

                                </div>

                            </div>
                        </div>
                    </div>

                    <div class="content-header-right col-md-6 col-12">
                        <div class="btn-group float-md-right" role="group" aria-label="Button group with nested">
                            <div class="form-group">
                                <button type="button" @click="addRdv" class="btn btn-info round box-shadow-2 px-2 mb-1"
                                    data-toggle="modal" data-target="#large">
                                    <i class="ft-edit icon-left"></i> Conseils
                                </button>
                            </div>
                        </div>
                    </div>
                </div>


                <section id="plan-medical-nutritionnel">
                    <div class="row">
                        <div class="col-lg-6 col-md-12">
                            <div class="card" style="height: 207.125px;">
                                <div class="card-body">
                                    <div class="row">
                                        <div class="col-lg-4 col-md-4 d-flex justify-content-around">
                                            <div class="patient-img-name text-center">
                                                <img src="/hospital/app-assets/images/portrait/small/avatar-s-18.png" alt=""
                                                    class="card-img-top mb-1 patient-img img-fluid rounded-circle"
                                                    style="width: 51%;">
                                                <h4>{{ patient.name }}</h4>
                                                <span class="badge badge-info mr-2">{{ patient.sex }}</span>
                                            </div>
                                        </div>
                                        <div class="col-lg-8 col-md-8 d-flex justify-content-around">
                                            <div class="media-body">
                                                <h5 class="media-heading">
                                                    No Dossier :
                                                    <span class="badge badge-info float-right">{{ patient.id }}</span>
                                                </h5>
                                                <hr />
                                                <h5 class="media-heading">
                                                    AGE:
                                                    <span class="float-right content-header-title">{{patient.age}}</span>
                                                </h5>
                                                <hr />
                                                <h5 class="media-heading">
                                                    Date d'arrivée:
                                                    <span class="float-right content-header-title">{{patient.date_arrive}}</span>
                                                </h5>


                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="col-lg-3 col-md-6">
                            <div class="card bg-gradient-y-info" style="height: 207.125px;">
                                <div class="card-body">
                                    <ul class="list-unstyled text-white patient-info-card">
                                        <li><span class="patient-info-heading">Groupe Sanguin :</span> B+</li>
                                        <li><span class="patient-info-heading">Electrophorèse:</span> AC</li>
                                        <li><span class="patient-info-heading">G6PD:</span> --</li>
                                        <li><span class="patient-info-heading">IMC : 35 kg/m²</span> </li>
                                        <li><span class="patient-info-heading">Dernier Contrôle :</span> 10 Oct 2022</li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                        <div class="col-lg-3 col-md-6">
                            <div class="card bg-gradient-y-warning" style="height: 207.125px;">
                                <div class="card-header">
                                    <h5 class="card-title text-white">Contrôles réguliers</h5>
                                </div>
                                <div class="card-content mx-2">
                                    <ul class="list-unstyled text-white">
                                        <li v-for="(conseil, index) in [...new Set(conseils)]">Dr. {{ conseil.doctor.name }} </li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                        <div class="col-12 ">
                            <div class="card">
                                <div class="card-header">
                                    <h4 class="card-title">Conseils et Recommandations</h4>
                                    <a class="heading-elements-toggle"><i class="la la-ellipsis font-medium-3"></i></a>
                                    <div class="heading-elements">
                                        <ul class="list-inline mb-0">

                                            <li><a data-action="reload"><i class="ft-rotate-cw"></i></a></li>
                                            <li><a data-action="expand"><i class="ft-maximize"></i></a></li>
                                        </ul>
                                    </div>
                                </div>
                                <div class="card-content collapse show">
                                    <div class="card-body">
                                        <div class="row">
                                            <div class="col-6" v-for="(conseil, index) in conseils">
                                                <div class="bs-callout-info callout-border-left p-1">
                                                    <strong>Bonjour {{ patient.name }}!</strong>
                                                    <p>{{ conseil.comment }}
                                                    </p>
                                                    <strong class="float-right">Par Dr {{ conseil.doctor.name }}
                                                        {{ conseil.created_at }}</strong>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>

                    </div>
                </section>

            </div>
        </div>
    </div>
</template>

<script setup>
import { ref, reactive, onMounted, computed } from 'vue'
import { useRouter, useRoute } from "vue-router"
import { useStore } from 'vuex'
import { createToast } from "mosha-vue-toastify";
import Search from "@/components/Search"
import PatientService from "@/services/modules/patient.service.js";
import ConseilService from "@/services/modules/conseil.service.js";
import PraticienService from "@/services/modules/praticien.service.js";
import VueMultiselect from "vue-multiselect";
// import the styling for the toast
import "mosha-vue-toastify/dist/style.css";
import VueElementLoading from 'vue-element-loading'

const router = useRouter()
const route = useRoute()

const search = ref('')
const infoMeetings = ref([])
const conseils = ref([])
const patients = ref([])
const patient = ref([])
const praticiens = ref([])
const isActive = ref(false)
const deleteId = ref(null)
const selectedPatient = ref(null)

const pagination = reactive({
    first: null,
    last: null,
    next: null,
    prev: null,
    current: null,
    total: null
})


const formData = reactive(
    {
        comment: '',
        doctor: '',
    }
)

function closeConseil() {
    formData.comment = ""
    formData.doctor = ""
}

const toast = (message, type) => {
    createToast(message, { type: type })
}

const prev = function () {
    if (pagination.current >= 0) {
        pagination.current = 1
        getData(pagination.current)
    }
    else {
        pagination.current--
        getData(pagination.current)
    }
}
const next = function () {
    pagination.current++
    if (pagination.current <= pagination.total) {
        getData(pagination.current)
    }
}


const storeConseil = function () {
    const datas = {}
    datas.patient_id = patient.value.id
    datas.doctor_id = formData.doctor.id
    datas.comment = formData.comment
    ConseilService.create(datas).then((response) => {
        console.log(response);
        if (response.data.status == 'error') {
            toast(response.data.message, 'danger')
        }
        else {
            closeConseil()
            recupConseil()
            toast("Vous avez ajouté un nouveau conseil!!!", 'success')
        }

    })
}

const recupPatient = (id) => {
    PatientService.get(route.params.id).then((data) => {
        patient.value = data.data.data
        selectedPatient.value = data.data.data.name 
    }).catch((e) => {
        console.log(e)
    })
}


const recupConseil = (id) => {
    ConseilService.get(route.params.id).then((data) => {
        conseils.value = data.data.data
    }).catch((e) => {
        console.log(e)
    })
}

const updateMeeting = function () {
    const datas = {}
    datas.date_meet = infoMeetings.value.date_meet
    datas.heure = infoMeetings.value.heure
    datas.motif = infoMeetings.value.motif
    datas.type_consultation = infoMeetings.value.type_consultation
    datas.commentaire = infoMeetings.value.commentaire
    datas.patient_id = infoMeetings.value.patient.id
    datas.doctor_id = infoMeetings.value.doctor.id
    MeetingService.update(infoMeetings.value.id, datas).then((response) => {
        console.log(response);
        if (response.data.status == 'error') {
            toast(response.data.message, 'danger')
        }
        else {
            getData()
            toast("Modifications éffectuées avec succès !!!", 'success')
        }

    })
}


const recupId = (id) => {
    deleteId.value = id
}

const supprimerRdv = function () {
    MeetingService.destroy(deleteId.value).then((data) => {
        if (data.data.status == "success") {
            deleteId.value = ""
            getData()
            toast("Suppression du rendez vous", "success");
        } else {
            toast(data.data.message, "danger");
        }
    })
}

const getPatients = () => {
    PatientService.get().then((data) => {
        const datas = data.data.data
        patients.value = datas.data
    }).catch((e) => {
        console.log(e)
    })
}

const getPraticiens = () => {
    PraticienService.get().then((data) => {
        const datas = data.data.data
        praticiens.value = datas.data
    }).catch((e) => {
        console.log(e)
    })
}

onMounted(() => {
    //   getData()
    recupPatient()
    recupConseil()
    getPraticiens()
    getPatients()
})
</script>
