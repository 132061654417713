
import ApiService from '@/services/configs/api.service'

const ExamenService = {
  get(slug,element) {
    return ApiService.get(`exams`, slug,element);
  },
 
  create(params) {
    return ApiService.post("exams", params);
  },

  update(slug, params) {
    return ApiService.update("exams", slug, params);
  },

  destroy(slug) {
    return ApiService.delete(`exams/${slug}`);
  },
 

 
};

  export default ExamenService;
  