
import ApiService from '@/services/configs/api.service'

const AnamneseService = {
  get(slug,element) {
    return ApiService.get("anamnesis", slug,element);
  },
 
  create(params) {
    return ApiService.post("anamnesis", params);
  },

  
  update(slug, params) {
    return ApiService.update("anamnesis", slug, params);
  },

  destroy(slug) {
    return ApiService.delete(`anamnesis/${slug}`);
  },
 

 
};

  export default AnamneseService;
  