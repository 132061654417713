<template>
    <div class="modal animated bounce text-left" :id="modalId" tabindex="-1" role="dialog" aria-labelledby="myModalLabel36" aria-hidden="true">
      <div class="modal-dialog" role="document">
        <div class="modal-content">
          <div class="modal-header">
            <h4 class="modal-title text-danger" id="myModalLabel36">
              <span class="alert-icon text-danger"><i class="la la-warning"></i></span>
              Confirmation de la suppression
            </h4>
            <button type="button" class="close" data-dismiss="modal" aria-label="Close">
              <span aria-hidden="true">&times;</span>
            </button>
          </div>
          <div class="modal-body">
            <h5 class="text-danger text-center">Attention! Cette action est irresvocable, êtes vous sur de vouloir continuer ?</h5>
            <p>.</p>
          </div>
          <div class="modal-footer">
            <button type="button" class="btn grey btn-outline-primary" data-dismiss="modal">Annuler</button>
            <button type="button" @click.prevent="executeDeleteFunction" data-dismiss="modal" class="btn btn-outline-danger">Supprimer</button>
          </div>
        </div>
      </div>
    </div>
  </template>
  
  <script>
  import { ref, watchEffect } from 'vue';
  export default {
  name: 'DeleteConfirmationModal',
  props: {
    modalId: {
      type: String,
      default: 'delete_confirmation_modal',
    },
    deleteFunction: {
      type: Function,
      required: true,
    },
  },
  setup(props, { emit }) {
    const executeDeleteFunction = () => {
      props.deleteFunction();
      emit('update:show', false);
    };

    const closeModal = () => {
      emit('update:show', false);
    };

    return {
      executeDeleteFunction,
      closeModal,
    };
  },
};
  </script>
  
  <style scoped>
  /* Your styles here */
  </style>
  